import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import withParams from "../../HOC/withParams";
import BannersServices from "../../services/axiosServices/apiServices/BannersServices";
import SwalServices from "../../services/swalServices/SwalServices";
import moment from "moment";
import { CommonValidationMessages } from "../../utils/Messages";
import { Validate } from "../../utils/validation/Validation";
import { isValidForm } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";

// Prasad 27/04/2023
import ImageUploadTest from "../../components/ImageUpload3/ImageUploadTest";

const bannerObj = {
  bannerId: 0,
  bannerName: "",
  startDate: "",
  endDate: "",
  bannerURL: "",
  bannerImageUrl: "",
  bannerLocationId: 0,
  bannerMobileImageUrl: "",
  bannerIndex: 0,
  bannerPosition: 0,
  isPublish: false,
};

class BannerUpload extends Component {
  constructor(props) {
    super(props);
    this.bannersServices = new BannersServices();
    this.swalServices = new SwalServices();
    this.state = {
      redirect: null,
      detailList: {
        eventsPage: [],
        communityPage: [],
        businessOpportunitiesPage: [],
        articlesPage: [],
        homePage: [],
      },
      bannerDetails: [
        {
          bannerId: 0,
          bannerName: "",
          startDate: "",
          endDate: "",
          bannerURL: "",
          bannerImageUrl: "",
          bannerLocationId: 0,
          bannerMobileImageUrl: "",
          bannerIndex: 0,
          bannerPosition: 0,
          isPublish: false,
        },
      ],
      validationRule: {
        startDate: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "start date"
            ),
          },
        ],
        endDate: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "end date"
            ),
          },
        ],
        bannerURL: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "banner url"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      bannerPosition: 0,
      bannerName: "",
      bannerPositionName: "",
      route: "",
    };
  }

  validateFieldBanner1 = (key) => {
    const newValidState = Validate(
      key,
      bannerObj,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };
  validateFieldBanner2 = (key) => {
    const newValidState = Validate(
      key,
      this.state.bannerDetails[1],
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };
  validateFieldBanner3 = (key) => {
    const newValidState = Validate(
      key,
      this.state.bannerDetails[2],
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };
  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.bannerDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  handleChange(event, bannerIndex) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.bannerDetails;
    detail.sort((a, b) => a - b);
    if (detail.length < 3) {
      let banners = [...this.state.bannerDetails];
      if (bannerIndex === 1) {
        let banner1 = detail.find((banner) => banner.bannerIndex === 1);
        detail[bannerIndex - 1].bannerIndex = bannerIndex;
        detail[bannerIndex - 1].bannerPosition = this.state.bannerPosition;
      } else if (bannerIndex === 2) {
        let banner2 = detail.find((banner) => banner.bannerIndex === 2);
        if (!banner2) {
          detail.push({ ...bannerObj });
          detail[detail.length - 1].bannerIndex = bannerIndex;
          detail[detail.length - 1].bannerPosition = this.state.bannerPosition;
        }
      } else if (bannerIndex === 3) {
        let banner3 = detail.find((banner) => banner.bannerIndex === 3);
        if (!banner3) {
          let banner2 = detail.find((banner) => banner.bannerIndex === 2);
          if (!banner2) {
            detail.push({ ...bannerObj });
            detail[bannerIndex - 2].bannerIndex = bannerIndex - 1;
            detail[bannerIndex - 2].bannerPosition = this.state.bannerPosition;
          }
          detail.push({ ...bannerObj });
          detail[bannerIndex - 1].bannerIndex = bannerIndex;
          detail[bannerIndex - 1].bannerPosition = this.state.bannerPosition;
        }
      }
    }
    detail.sort((a, b) => a - b);
    if (bannerIndex === 1) {
      detail[bannerIndex - 1].bannerIndex = bannerIndex;
      detail[bannerIndex - 1].bannerPosition = this.state.bannerPosition;
      detail[0][name] = value;
    } else if (bannerIndex === 2) {
      let banner2 = detail.find((banner) => banner.bannerIndex === 2);
      if (banner2) {
        detail[bannerIndex - 1][name] = value;
      } else {
        detail.push({ ...bannerObj });
        detail[detail.length - 1].bannerIndex = bannerIndex;
        detail[detail.length - 1].bannerPosition = this.state.bannerPosition;
        detail[detail.length - 1][name] = value;
      }
    } else if (bannerIndex === 3) {
      // detail[2][name] = value;
      let banner3 = detail.find((banner) => banner.bannerIndex === 3);
      if (banner3) {
        let banner2 = detail.find((banner) => banner.bannerIndex === 2);
        if (!banner2) {
          detail.push(bannerObj);
          detail[detail.length - 1].bannerIndex = bannerIndex;
          detail[detail.length - 1].bannerPosition = this.state.bannerPosition;
        }
        detail[detail.length - 1].bannerIndex = bannerIndex;
        detail[detail.length - 1].bannerPosition = this.state.bannerPosition;
        detail[detail.length - 1][name] = value;
      }
    }
    this.setState({ bannerDetails: [...detail] });
  }

  // setBannerArray = (index, position) => {
  //   var banners = [...this.state.bannerDetails];
  //   if (index === 1) {
  //     var banner1 = banners.find((banner) => banner.bannerIndex === 1);
  //     banners[index - 1].bannerIndex = index;
  //     banners[index - 1].bannerPosition = this.state.bannerPosition;
  //   } else if (index === 2) {
  //     var banner2 = banners.find((banner) => banner.bannerIndex === 2);
  //     if (!banner2) {
  //       banners.push(bannerObj);
  //       banners[banners.length - 1].bannerIndex = index;
  //       banners[banners.length - 1].bannerPosition = this.state.bannerPosition;
  //     }
  //   } else if (index === 3) {
  //     var banner3 = banners.find((banner) => banner.bannerIndex === 3);
  //     if (!banner3) {
  //       var banner2 = banners.find((banner) => banner.bannerIndex === 2);
  //       if (!banner2) {
  //         banners.push(bannerObj);
  //         banners[banners.length - 1].bannerIndex = index;
  //         banners[banners.length - 1].bannerPosition =
  //           this.state.bannerPosition;
  //       }
  //       banners.push(bannerObj);
  //       banners[banners.length - 1].bannerIndex = index;
  //       banners[banners.length - 1].bannerPosition = this.state.bannerPosition;
  //     }
  //   }
  // };


  getBanners = (id, isDeleted) => {
    this.bannersServices.getBanners(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.length > 0) {
          this.setState({ bannerDetails: response.responseItem.responseContent },);
        }

        // this.swalServices.Success("Success");
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };
  addEditBanners = (isPreview) => {
    let req = this.state.bannerDetails;
    // req[0].bannerPosition = this.state.bannerPosition;
    // req[0].bannerName = this.state.bannerName;
    // req[1].bannerPosition = this.state.bannerPosition;
    // req[1].bannerName = this.state.bannerName;
    // req[2].bannerPosition = this.state.bannerPosition;
    // req[2].bannerName = this.state.bannerName;
    // req[0].bannerIndex = 1;
    // req[1].bannerIndex = 2;
    // req[2].bannerIndex = 3;
    // req[0].isPublish = false;
    // req[1].isPublish = false;
    // req[2].isPublish = false;

    let isValid = this.validateBanner(req);
    if (isValid) {
      this.bannersServices.addEditBanners(req).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (isPreview === true) {
            this.setState({
              route: "/BannerPreview/" + this.state.bannerPositionName,
            });
          } else {
            this.swalServices.Success("Banner information inserted successfully");
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };
  clearAllBannersByPositionId = (id) => {
    this.bannersServices.clearAllBannersByPositionId(this.state.bannerPosition).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.SuccessWithAction("Banner details deleted successfully.", "Okay").then((response) => {
          window.location.reload(true);
        })
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }

  componentDidMount() {
    let params = this.props.params;

    // var banners = [...this.state.bannerDetails];
    // var abc = [];
    // for (let index = 0; index < 3; index++) {
    //   // if(banners.length <= (index + 1)){
    //   //   abc.push(banners[index]);
    //   // }
    //   // else{
    //   //   banners[0]
    //   // }
    //   var banners = [...this.state.bannerDetails];
    //   abc.push(banners);
    //   //abc[index].bannerIndex = (index + 1);
    // }
    // this.setState({ bannerDetails: abc });

    if (params.chapterscommittee === "chapterscommittee") {
      this.setState({ bannerPosition: 1 });
      this.setState({ bannerName: "Chapters / Committee" });
      this.setState({ bannerPositionName: "chapterscommittee" });
      this.getBanners(1);
    } else if (params.chapterscommittee === "events") {
      this.setState({ bannerPosition: 2 });
      this.setState({ bannerName: "Events" });
      this.setState({ bannerPositionName: "events" });
      this.getBanners(2);
    } else if (params.chapterscommittee === "businessopportunities") {
      this.setState({ bannerPosition: 3 });
      this.setState({ bannerName: "Business Opportunities" });
      this.setState({ bannerPositionName: "businessopportunities" });
      this.getBanners(3);
    } else if (params.chapterscommittee === "articles") {
      this.setState({ bannerPosition: 4 });
      this.setState({ bannerName: "Articles" });
      this.setState({ bannerPositionName: "articles" });
      this.getBanners(4);
    } else if (params.chapterscommittee === "home") {
      this.setState({ bannerPosition: 5 });
      this.setState({ bannerName: "Home" });
      this.setState({ bannerPositionName: "home" });
      this.getBanners(5);
    }
  }
  saveMainImage = (file, keyId, imageIdentity) => {
    this.setState({ isLoaderEnabled: true });
    file.storagePath = "Banners";
    this.bannersServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("File Uploaded");
        let updatedBannerDetails = [...this.state.bannerDetails];

        if (keyId === "Banner1_Desktop") {
          updatedBannerDetails[0].bannerImageUrl = response.fileName;
        } else if (keyId === "Banner1_Mobile") {
          updatedBannerDetails[0].bannerMobileImageUrl = response.fileName;
        } else if (keyId === "Banner2_Desktop") {
          updatedBannerDetails[1].bannerImageUrl = response.fileName;
        } else if (keyId === "Banner2_Mobile") {
          updatedBannerDetails[1].bannerMobileImageUrl = response.fileName;
        } else if (keyId === "Banner3_Desktop") {
          updatedBannerDetails[2].bannerImageUrl = response.fileName;
        } else if (keyId === "Banner3_Mobile") {
          updatedBannerDetails[2].bannerMobileImageUrl = response.fileName;
        }
        this.setState({ bannerDetails: updatedBannerDetails });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  validateBanner = (banners) => {
    let valError = [];
    let valState = { ...this.state.validState };
    valState.isValid = true;
    let message = "";
    let field = "";
    banners.forEach((value, key) => {
      if (value.bannerURL === "") {
        //const url = new URL(value.bannerURL);
        message = "Please enter banner url";
        field = "bannerURL" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      else {
        if (value.bannerURL.includes("mailto")) {

        }
        else if (!this.isValidHttpUrl(value.bannerURL)) {
          message = "Please enter valid banner url";
          field = "bannerURL" + (key + 1);
          valError[field] = message;
          valState.isValid = false;
        }
      }

      if (value.bannerImageUrl === "") {
        message = "Please upload desktop banner image";
        field = "bannerImageUrl" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.bannerMobileImageUrl === "") {
        message = "Please upload mobile banner image";
        field = "bannerMobileImageUrl" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.startDate === "") {
        message = "Please enter start date";
        field = "startDate" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      } else {
        let defaultStartDate = new Date("2000-01-01 00:00:00.000");
        let defaultEndDate = new Date("9000-01-01 00:00:00.000");
        let startDate = new Date(value.startDate);
        if (startDate < defaultStartDate || startDate > defaultEndDate) {
          message = "Please enter valid year of start date between 2000 and 9000";
          field = "startDate" + (key + 1);
          valError[field] = message;
          valState.isValid = false;
        }
      }
      if (value.endDate === "") {
        message = "Please enter end date";
        field = "endDate" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      } else {
        let defaultStartDate = new Date("2000-01-01 00:00:00.000");
        let defaultEndDate = new Date("9000-01-01 00:00:00.000");
        let endDate = new Date(value.endDate);
        if (endDate < defaultStartDate || endDate > defaultEndDate) {
          message = "Please enter valid year of end date between 2000 and 9000";
          field = "endDate" + (key + 1);
          valError[field] = message;
          valState.isValid = false;
        }
      }

    });
    valState.error = valError;
    this.setState({ validState: valState });
    return valState.isValid;
  };

  isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
      return !!(url.protocol === "http:" || url.protocol === "https:");
    } catch (_) {
      return false;
    }
  }

  removeImage = (image) => {
    let banners = [...this.state.bannerDetails];
    const index = Number(image.slice(-1)) - 1;
    const field = image.slice(0, -1);
    banners[index][field] = "";
    this.setState({ bannerDetails: banners });
  };

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className="heading-part px-7 py-4 border-b flex items-center justify-between">
            <div className="left-title">
              <h4 className="text-2xl font-bold theme-color">Banner Upload</h4>
            </div>
            <div className="right-button">
              <button
                className="w-40 px-5 py-2 btn-sm btn-red text-lg text-white"
                onClick={() => this.clearAllBannersByPositionId()}
              >
                Clear All
              </button>
            </div>
          </div>
          <div className="px-7 py-4">
            <div className="normal-title pt-7">
              <p className="font-bold text-2xl theme-color">Banner 1</p>
            </div>
            <div className="my-5 xl:px-7 xl:py-8 lg:px-4 lg:py-5 border-dashed border-2">
              <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-5 lg:col-span-12 py-4 pr-4">
                  <div className="grid grid-cols-12 gap-6">
                    <div className=" col-span-12">
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className="xl:col-span-6 lg:col-span-4 xl:pr-3 w-full">
                          <label
                            className="block text-xl font-normal mb-3 theme-color min-h-30"
                            htmlFor="startDate1"
                          >
                            Start Date<span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="startDate1"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="date"
                            name="startDate"
                            value={this.state.bannerDetails[0].startDate ? moment(
                              this.state.bannerDetails[0].startDate
                            ).format("yyyy-MM-DD") : ""}
                            onChange={(event) => this.handleChange(event, 1)}
                          // onBlur={() => this.validateFieldBanner1("startDate")}
                          />
                          <ValidationText
                            error={this.state.validState.error.startDate1}
                          />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-4 xl:pl-3 w-full">
                          <label
                            className="block text-xl font-normal mb-3 theme-color min-h-30"
                            htmlFor="endDate1"
                          >
                            End Date<span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="endDate1"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="date"
                            name="endDate"
                            value={this.state.bannerDetails[0].endDate ? moment(
                              this.state.bannerDetails[0].endDate
                            ).format("yyyy-MM-DD") : ''}
                            onChange={(event) => this.handleChange(event, 1)}
                          />
                          <ValidationText
                            error={this.state.validState.error.endDate1}
                          />
                        </div>
                        <div className="xl:col-span-12 lg:col-span-4 xl:mt-7">
                          <label
                            className="block text-xl font-normal mb-3 theme-color min-h-30"
                            htmlFor="bannerURL1"
                          >
                            Banner URL:<span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="default"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="text"
                            name="bannerURL"
                            value={this.state.bannerDetails[0].bannerURL ? this.state.bannerDetails[0].bannerURL : ""}
                            onChange={(event) => this.handleChange(event, 1)}
                          // onBlur={() => this.validateFieldBanner1("bannerURL")}
                          />
                          <ValidationText
                            error={this.state.validState.error.bannerURL1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 mt-10 gap-6 hidden">
                    <div className="col-span-12">
                      <div className="flex items-center justify-between">
                        <button className="px-3 mt-3 py-2 btn-red-border text-xl border text-[#C00000]">
                          Upload Desktop Image
                        </button>
                        <button className="px-3 mt-3 py-2 btn-red-border text-xl border text-[#C00000]">
                          Upload Mobile Image
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-7 lg:col-span-12 pl-4">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                      <label
                        className="block text-xl font-bold mb-3 theme-color min-h-30"
                        htmlFor="desktopBanner1"
                      >
                        Desktop Banner
                      </label>
                      <div className="img-logo-upload text-center relative banner-manage">
                        <ImageUploadTest
                          key="Main"
                          keyId="Banner1_Desktop"
                          height={356}
                          width={2438}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[0].bannerImageUrl
                          }
                          aspactRatio={2438 / 356}
                          uploadBtn={"Upload Desktop Image"}
                          dimensionText={
                            "(Dimension: 1170px by 170px Maximum Size:2MB)"
                          }
                        />

                        {/* <ImageCropperWithUpload
                          key="Main"
                          keyId="Banner1_Desktop"
                          height={170}
                          width={1170}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[0].bannerImageUrl
                          }
                          aspactRatio={1170 / 170}
                          uploadBtn={"Upload Desktop Image"}
                          dimensionText={
                            "(Dimension: 1170px by 170px Maximum Size:2MB)"
                          }
                        /> */}
                        <span
                          className={`close-sign absolute font-extralight fa fa-times theme-color border rounded-full 
                                   bg-zinc-200 w-6 flex items-center justify-center h-6 p-1 ${this.state.bannerDetails[0]
                              .bannerImageUrl === ""
                              ? "hidden"
                              : null
                            }`}
                          onClick={() => this.removeImage("bannerImageUrl1")}
                        ></span>
                        <ValidationText
                          error={this.state.validState.error.bannerImageUrl1}
                        />
                      </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                      <label
                        className="block text-xl font-bold mb-3 theme-color min-h-30"
                        htmlFor="mobileBanner1"
                      >
                        Mobile Banner
                      </label>
                      <div className="img-logo-upload text-center relative banner-manage">
                        <ImageUploadTest
                          key="Main"
                          keyId="Banner1_Mobile"
                          height={522}
                          width={1739}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[0].bannerMobileImageUrl
                          }
                          aspactRatio={1739 / 522}
                          uploadBtn={"Upload Mobile Image"}
                          dimensionText={
                            "(Dimension: 834 x 250px Maximum Size: 2 MB)"
                          }
                        />
                        {/* <ImageCropperWithUpload
                          key="Main"
                          keyId="Banner1_Mobile"
                          height={522}
                          width={1739}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[0].bannerMobileImageUrl
                          }
                          aspactRatio={1739/522}
                          uploadBtn={"Upload Mobile Image"}
                          dimensionText={
                            "(Dimension: 834 x 250px Maximum Size: 2 MB)"
                          }
                        /> */}
                        <span
                          className={`close-sign absolute font-extralight fa fa-times theme-color border rounded-full 
                                   bg-zinc-200 w-6 flex items-center justify-center h-6 p-1 ${this.state.bannerDetails[0]
                              .bannerMobileImageUrl === ""
                              ? "hidden"
                              : null
                            }`}
                          onClick={() =>
                            this.removeImage("bannerMobileImageUrl1")
                          }
                        ></span>
                        <ValidationText
                          error={
                            this.state.validState.error.bannerMobileImageUrl1
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-7 py-4">
            <div className="normal-title">
              <p className="font-bold text-2xl theme-color">Banner 2</p>
            </div>
            <div className="my-5 xl:px-7 xl:py-8 lg:px-4 lg:py-5 border-dashed border-2">
              <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-5 lg:col-span-12 py-4 pr-4">
                  <div className="grid grid-cols-12 gap-6">
                    <div className=" col-span-12">
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className="xl:col-span-6 lg:col-span-4 xl:pr-3 w-full">
                          <label
                            className="block text-xl font-normal mb-3 theme-color min-h-30"
                            htmlFor="startDate2"
                          >
                            Start Date<span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="startDate2"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="date"
                            name="startDate"
                            value={
                              this.state.bannerDetails[1]
                                ? moment(
                                  this.state.bannerDetails[1].startDate
                                ).format("yyyy-MM-DD")
                                : ''
                            }
                            onChange={(event) => this.handleChange(event, 2)}
                          />
                          <ValidationText
                            error={this.state.validState.error.startDate2}
                          />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-4 xl:pl-3 w-full">
                          <label
                            className="block text-xl font-normal mb-3 theme-color min-h-30"
                            htmlFor="endDate2"
                          >
                            End Date<span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="endDate2"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="date"
                            name="endDate"
                            value={
                              this.state.bannerDetails[1]
                                ? moment(
                                  this.state.bannerDetails[1].endDate
                                ).format("yyyy-MM-DD")
                                : ''
                            }
                            onChange={(event) => this.handleChange(event, 2)}
                          />
                          <ValidationText
                            error={this.state.validState.error.endDate2}
                          />
                        </div>
                        <div className="xl:col-span-12 lg:col-span-4 xl:mt-7">
                          <label
                            className="block text-xl font-normal mb-3 theme-color min-h-30"
                            htmlFor="bannerURL2"
                          >
                            Banner URL:<span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="bannerURL2"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="text"
                            name="bannerURL"
                            value={
                              this.state.bannerDetails[1]
                                ? this.state.bannerDetails[1].bannerURL
                                : ''
                            }
                            onChange={(event) => this.handleChange(event, 2)}
                          />
                          <ValidationText
                            error={this.state.validState.error.bannerURL2}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 mt-10 gap-6 hidden">
                    <div className="col-span-12">
                      <div className="flex items-center justify-between">
                        <button className="px-3 mt-3 py-2 btn-red-border text-xl border text-[#C00000]">
                          Upload Desktop Image
                        </button>
                        <button className="px-3 mt-3 py-2 btn-red-border text-xl border text-[#C00000]">
                          Upload Mobile Image
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-7 lg:col-span-12 pl-4">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                      <label
                        className="block text-xl font-bold mb-3 theme-color min-h-30"
                        htmlFor="desktopBanner"
                      >
                        Desktop Banner
                      </label>
                      <div className="img-logo-upload text-center relative banner-manage">
                        <ImageUploadTest
                          key="Main"
                          height={356}
                          width={2438}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          keyId="Banner2_Desktop"
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[1]
                              ? this.state.bannerDetails[1].bannerImageUrl
                              : null
                          }
                          aspactRatio={2438 / 356}
                          uploadBtn={"Upload Desktop Image"}
                          dimensionText={
                            "(Dimension: 1170px by 170px Maximum Size:2MB)"
                          }
                        />
                        {/* <ImageCropperWithUpload
                          key="Main"
                          height={170}
                          width={1170}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          keyId="Banner2_Desktop"
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[1]
                              ? this.state.bannerDetails[1].bannerImageUrl
                              : null
                          }
                          aspactRatio={1170/170}
                          uploadBtn={"Upload Desktop Image"}
                          dimensionText={
                            "(Dimension: 1170px by 170px Maximum Size:2MB)"
                          }
                        /> */}
                        <span
                          className={`close-sign absolute font-extralight fa fa-times theme-color border rounded-full 
                                   bg-zinc-200 w-6 flex items-center justify-center h-6 p-1 ${this.state.bannerDetails[1] &&
                              this.state.bannerDetails[1]
                                .bannerImageUrl === ""
                              ? "hidden"
                              : null
                            }`}
                          onClick={() => this.removeImage("bannerImageUrl2")}
                        ></span>
                        <ValidationText
                          error={this.state.validState.error.bannerImageUrl2}
                        />
                      </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                      <label
                        className="block text-xl font-bold mb-3 theme-color min-h-30"
                        htmlFor="mobileBanner2"
                      >
                        Mobile Banner
                      </label>
                      <div className="img-logo-upload text-center relative banner-manage">
                        <ImageUploadTest
                          key="Main"
                          height={522}
                          width={1739}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          keyId="Banner2_Mobile"
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[1]
                              ? this.state.bannerDetails[1].bannerMobileImageUrl
                              : null
                          }
                          aspactRatio={1739 / 522}
                          uploadBtn={"Upload Mobile Image"}
                          dimensionText={
                            "(Dimension: 834 x 250px Maximum Size: 2 MB)"
                          }
                        />
                        {/* <ImageCropperWithUpload
                          key="Main"
                          height={250}
                          width={834}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          keyId="Banner2_Mobile"
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[1]
                              ? this.state.bannerDetails[1].bannerMobileImageUrl
                              : null
                          }
                          aspactRatio={834/250}
                          uploadBtn={"Upload Mobile Image"}
                          dimensionText={
                            "(Dimension: 834 x 250px Maximum Size: 2 MB)"
                          }
                        /> */}
                        <span
                          className={`close-sign absolute font-extralight fa fa-times theme-color border rounded-full 
                                   bg-zinc-200 w-6 flex items-center justify-center h-6 p-1 ${this.state.bannerDetails[1] &&
                              this.state.bannerDetails[1]
                                .bannerMobileImageUrl === ""
                              ? "hidden"
                              : null
                            }`}
                          onClick={() =>
                            this.removeImage("bannerMobileImageUrl2")
                          }
                        ></span>
                        <ValidationText
                          error={
                            this.state.validState.error.bannerMobileImageUrl2
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-7 py-4 pb-7">
            <div className="normal-title">
              <p className="font-bold text-2xl theme-color">Banner 3</p>
            </div>
            <div className="my-5 xl:px-7 xl:py-8 lg:px-4 lg:py-5 border-dashed border-2">
              <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-5 lg:col-span-12 py-4 pr-4">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className="xl:col-span-6 lg:col-span-4 xl:pr-3 w-full">
                          <label
                            className="block text-xl font-normal mb-3 theme-color min-h-30"
                            htmlFor="startDate3"
                          >
                            Start Date<span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="startDate3"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="date"
                            name="startDate"
                            value={
                              this.state.bannerDetails[2]
                                ? moment(
                                  this.state.bannerDetails[2].startDate
                                ).format("yyyy-MM-DD")
                                : ''
                            }
                            onChange={(event) => this.handleChange(event, 3)}
                          />
                          <ValidationText
                            error={this.state.validState.error.startDate3}
                          />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-4 xl:pl-3 w-full">
                          <label
                            className="block text-xl font-normal mb-3 theme-color min-h-30"
                            htmlFor="endDate3"
                          >
                            End Date<span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="endDate3"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="date"
                            name="endDate"
                            value={
                              this.state.bannerDetails[2]
                                ? moment(
                                  this.state.bannerDetails[2].endDate
                                ).format("yyyy-MM-DD")
                                : ''
                            }
                            onChange={(event) => this.handleChange(event, 3)}
                          />
                          <ValidationText
                            error={this.state.validState.error.endDate3}
                          />
                        </div>
                        <div className="xl:col-span-12 lg:col-span-4 xl:mt-7">
                          <label
                            className="block text-xl font-normal mb-3 theme-color min-h-30"
                            htmlFor="bannerURL3"
                          >
                            Banner URL:<span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="bannerURL3"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="text"
                            name="bannerURL"
                            value={
                              this.state.bannerDetails[2]
                                ? this.state.bannerDetails[2].bannerURL
                                : ""
                            }
                            onChange={(event) => this.handleChange(event, 3)}
                          />
                          <ValidationText
                            error={this.state.validState.error.bannerURL3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 mt-10 gap-6 hidden">
                    <div className="col-span-12">
                      <div className="flex items-center justify-between">
                        <button className="px-3 mt-3 py-2 btn-red-border text-xl border text-[#C00000]">
                          Upload Desktop Image
                        </button>
                        <button className="px-3 mt-3 py-2 btn-red-border text-xl border text-[#C00000]">
                          Upload Mobile Image
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-7 lg:col-span-12 pl-4">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                      <label
                        className="block text-xl font-bold mb-3 theme-color min-h-30"
                        htmlFor="desktopBanner"
                      >
                        Desktop Banner
                      </label>
                      <div className="img-logo-upload text-center relative banner-manage">
                        <ImageUploadTest
                          key="Main"
                          height={356}
                          width={2438}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          keyId="Banner3_Desktop"
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[2]
                              ? this.state.bannerDetails[2].bannerImageUrl
                              : null
                          }
                          aspactRatio={2438 / 356}
                          uploadBtn={"Upload Desktop Image"}
                          dimensionText={
                            "(Dimension: 1170px by 170px Maximum Size:2MB)"
                          }
                        />

                        {/* <ImageCropperWithUpload
                          key="Main"
                          height={170}
                          width={1170}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          keyId="Banner3_Desktop"
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[2]
                              ? this.state.bannerDetails[2].bannerImageUrl
                              : null
                          }
                          aspactRatio={1170 / 170}
                          uploadBtn={"Upload Desktop Image"}
                          dimensionText={
                            "(Dimension: 1170px by 170px Maximum Size:2MB)"
                          }
                        /> */}
                        <span
                          className={`close-sign absolute font-extralight fa fa-times theme-color border rounded-full 
                                   bg-zinc-200 w-6 flex items-center justify-center h-6 p-1 ${this.state.bannerDetails[2] &&
                              this.state.bannerDetails[2]
                                .bannerImageUrl === ""
                              ? "hidden"
                              : null
                            }`}
                          onClick={() => this.removeImage("bannerImageUrl3")}
                        ></span>
                        <ValidationText
                          error={this.state.validState.error.bannerImageUrl3}
                        />
                      </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                      <label
                        className="block text-xl font-bold mb-3 theme-color min-h-30"
                        htmlFor="mobileBanner"
                      >
                        Mobile Banner
                      </label>
                      <div className="img-logo-upload text-center relative banner-manage">
                        <ImageUploadTest
                          key="Main"
                          height={522}
                          width={1739}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          keyId="Banner3_Mobile"
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[2]
                              ? this.state.bannerDetails[2].bannerMobileImageUrl
                              : null
                          }
                          aspactRatio={1739 / 522}
                          uploadBtn={"Upload Mobile Image"}
                          dimensionText={
                            "(Dimension: 834 x 250px Maximum Size: 2 MB)"
                          }
                        />
                        {/* <ImageCropperWithUpload
                          key="Main"
                          height={250}
                          width={834}
                          isCheckValidation={true}
                          MaxFileSize={2097152}
                          MaxFileSizeMessage={"2MB"}
                          onSaveImage={this.saveMainImage.bind(this)}
                          keyId="Banner3_Mobile"
                          imageIdentity="Banners"
                          intitImage={
                            this.state.bannerDetails[2]
                              ? this.state.bannerDetails[2].bannerMobileImageUrl
                              : null
                          }
                          aspactRatio={834 / 250}
                          uploadBtn={"Upload Mobile Image"}
                          dimensionText={
                            "(Dimension: 834 x 250px Maximum Size: 2 MB)"
                          }
                        /> */}
                        <span
                          className={`close-sign absolute font-extralight fa fa-times theme-color border rounded-full 
                                   bg-zinc-200 w-6 flex items-center justify-center h-6 p-1 ${this.state.bannerDetails[2] &&
                              this.state.bannerDetails[2]
                                .bannerMobileImageUrl === ""
                              ? "hidden"
                              : ""
                            }`}
                          onClick={() =>
                            this.removeImage("bannerMobileImageUrl3")
                          }
                        ></span>
                        <ValidationText
                          error={
                            this.state.validState.error.bannerMobileImageUrl3
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal footer Button*/}

        <div className="flex flex-wrap justify-between space-x-10 mt-12">
          <div>
            <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white">
              Back
            </button>
          </div>

          <div>
            <button
              className="w-40 px-5 mr-16 py-2 btn-sm btn-lighblue text-lg text-white"
              onClick={() => this.addEditBanners(false)}
            >
              Save as Draft
            </button>
            {/* <NavLink to={"/BannerPreview/" + this.state.bannerPositionName}>
              <button className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white">
                Preview
              </button>
            </NavLink> */}
            <button
              className="w-40 px-5 py-2 btn-sm btn-lighblue text-lg text-white"
              onClick={() => this.addEditBanners(true)}
            >
              Preview
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default withParams(BannerUpload);