import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Table from "../../components/table/Table";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import ActionTagButton from "../../components/UIComponent/ActionTableComponent/ActionTagButton";
import SwalServices from "../../services/swalServices/SwalServices";
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import moment from "moment";
import {
  CommonSuccessMessages,
  CommonValidationMessages,
  ErrorMessage,
  SuccessMessage,
} from "../../utils/Messages";
import DropdownAction from "../../components/dropdown/DropdownAction";
import CancelAllBookingsModel from "../../components/entrymanagement/CancelAllBookingsModel";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import { encryptAES } from "../../utils/Encryption";
import BookingServices from "../../services/axiosServices/apiServices/BookingServices";
import { saveAs } from "file-saver";
import { WebPath } from "../../utils/AppSetting";
import EventCalender from "../../components/eventCalander/EventCalender";
import ToggleButton from "../../components/common/ToggleButton";

export default class ViewEditEntries extends Component {
  constructor(props) {
    super(props);
    this.swalServices = new SwalServices();
    this.entryServices = new EntryManagementServices();
    this.financeService = new FinanceServices();
    this.BookingServices = new BookingServices();
    this.state = {
      route: "",
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        statusId: 0,
        optionSelected: [],
        hiddenSearch: "",
        isCalendarView: true,
      },
      totalResultes: "",
      isLoading: false,
      eventList: [],
      eventStatus: [],
      hiddenOption: [
        {
          value: 1,
          label: "Yes",
        },
        {
          value: 2,
          label: "No",
        },
      ],
      eventId: 0,
      actionDropdown: [
        "Edit",
        "Cancel all bookings",
        "Download Booking Reports",
        "URL Link",
      ],
      actionDropdown3: [
        "Edit",
        "Cancel all bookings",
        "Download Booking Reports",
      ],
      actionDropdown2: ["Edit"],
      setCancelAllBookingsModel: false,
      cancelBookingDetails: {},
      cancellationReason: [],
      gstRate: 0,
      isOtherCancellationReason: false,
      cancelBookingDetailsValidationRules: {
        cancellationReasonId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "cancellation reason"
            ),
          },
        ],
        cancellationReason: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "cancellation reason"
            ),
          },
        ],
      },
      validCreditNote: {
        isValid: true,
        error: {},
      },
    };
  }

  // Methods Section
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getEntryDetailList(newPagination);
  };

  actions = (element, index, value) => {
    return element !== "eventId" ? null : (
      <td>
        <ActionContainer>
          {this.state.eventList.find((x) => x.eventId === value).status ===
            "Approved " &&
            new Date(
              this.state.eventList.find((x) => x.eventId === value).eventDate
            ).getTime() >= new Date().getTime() ? (
            <DropdownAction
              key={index}
              value={value}
              id={index}
              index={index}
              options={this.state.actionDropdown}
              align="right"
              onOptionClick={this.actionClick.bind(this)}
            />
          ) : this.state.eventList.find((x) => x.eventId === value).status ===
            "Approved " ? (
            <DropdownAction
              key={index}
              value={value}
              id={index}
              index={index}
              options={this.state.actionDropdown3}
              align="right"
              onOptionClick={this.actionClick.bind(this)}
            />
          ) : (
            <DropdownAction
              key={index}
              value={value}
              id={index}
              index={index}
              options={this.state.actionDropdown2}
              align="right"
              onOptionClick={this.actionClick.bind(this)}
            />
          )}
          {/* <ActionEditButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onEdit.bind(this)}
          text="Edit"
        /> */}
          <ActionTagButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onTagging.bind(this)}
          // text="Edit"
          />
          <ActionDeleteButton
            value={value}
            value2={this.state.eventList[index].eventTimeSlotId}
            id={index}
            index={index}
            onActionClick={this.onDelete.bind(this)}
          // text="Delete"
          />
        </ActionContainer>
      </td>
    );
  };

  // handleChangeInDropdown = (value ,drpIdentity) => {
  //   let detail = this.state.pagination;
  //   if(drpIdentity === "Status")
  //   {
  //    detail['filterByStatus'] = value;
  //    this.setState({pagination : { ...detail }});
  //   }
  //   this.getEventList();
  //   }

  handleChangeInDropdown(selected, drpIdentity) {
    if (drpIdentity === "Status") {
      let details = this.state.pagination;
      details["optionSelected"] = selected;
      details.pageNo = 1;
      details.pageSize = 10;
      details.statusId = selected.value;
      this.setState({ pagination: details });
    }
    if (drpIdentity === "Hidden") {
      let details = this.state.pagination;
      details.pageNo = 1;
      details.pageSize = 10;
      if (selected.value > 0) {
        details.hiddenSearch = selected.label;
      } else {
        details.hiddenSearch = "";
      }
      this.setState({ pagination: details });
    }
  }

  // filterByParameters = () => {
  //   let detail = this.state.pagination;
  //   detail.pageNo = 1;
  //   detail.pageSize = 10;
  //   this.setState({ pagination: { ...detail } }, () => this.getAuthorityLimitList());
  // }

  componentDidMount() {
    this.getAllDropDownForEntryManagement(this.state.eventId);
    // this.getEntryDetailList();
  }
  getAllDropDownForEntryManagement = (eventId) => {
    this.entryServices
      .getAllDropDownForEntryManagement(eventId)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let eventStatus = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Status"
          );
          eventStatus = eventStatus.map((p) => ({
            value: p.id,
            label: p.name,
          }));

          this.setState({ eventStatus: eventStatus }, () => {
            this.getEntryDetailList();
          });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };
  getEntryDetailList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.entryServices.getEntryDetailList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let eventArr = response.responseItem.responseContent.itemList;

        const swapValue = (details) => {
          Object.keys(details).forEach((key) => {
            if (details[key].eventDate !== null) {
              details[key].eventDate = moment(details[key].eventDate).format(
                "DD MMM YYYY"
              );
            }
          });
        };
        swapValue(eventArr);
        this.setState({ eventList: eventArr });
        this.setState({
          totalResultes: response.responseItem.responseContent.totalCount,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };
  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.pageSize = 10;
    detail.searchText = value;
    this.setState({ pagination: { ...detail } });

    //this.getEntryDetailList();
  };

  onEdit = (id) => {
    this.setState({ route: "/CreateEntry/eventId?=" + encryptAES(id) });
  };
  actionClick = (index, value, option, event) => {
    if (option === "Cancel all bookings") {
      let found = this.state.eventList.find((event) => event.eventId);
      if (found) {
        found.creditNoteMonth = moment();
        this.setState({ cancelBookingDetails: found }, () => {
          event.stopPropagation();
          this.setState({ setCancelAllBookingsModel: true });
        });
        this.getCancellationReasonForCreditNote();
      }
    }
    if (option === "Edit") {
      //event.stopPropagation();
      this.onEdit(value);
    }
    if (option === "Download Booking Reports") {
      let event = this.state.eventList.find((x) => x.eventId === value);
      if (event) {
        if (
          event.eventId &&
          event.eventId > 0 &&
          event.booking &&
          Number(event.booking) > 0
        ) {
          this.downloadBookingSheetByEventId(event.eventId, event.eventName);
        } else {
          this.swalServices.Warning(
            `Booking details is not available for ${event.eventName}.`
          );
        }
      }
    }
    if (option === "URL Link") {
      if (value > 0 && this.state.eventList.length > 0) {
        let data = this.state.eventList.find((x) => x.eventId === value);
        if (data) {
          let host = WebPath;
          let urlLink = "";
          if (data.eventTypeName === "Conference") {
            urlLink = `${host}programmes/conferenceDetails/${encryptAES(
              data.eventId,
              0
            )}?timeSlot=${encryptAES(data.eventTimeSlotId)}`;
            window.open(urlLink, "_blank");
          } else if (data.eventTypeName === "Workshop") {
            urlLink = `${host}programmes/workshopDetails/${encryptAES(
              data.eventId,
              0
            )}`;
            window.open(urlLink, "_blank");
          } else if (data.eventTypeName === "Forms (Interest to Participate)") {
            urlLink = `${host}programmes/formDetails/${encryptAES(
              data.eventId,
              0
            )}`;
            window.open(urlLink, "_blank");
          }
        }
      }
    }
  };

  downloadBookingSheetByEventId = (eventId = 0, eventName = "Event") => {
    this.setState({ isDownloadingExcel: true });
    if (eventId && eventId > 0) {
      let request = [];
      request.push(eventId);
      this.BookingServices.downloadBookingSheetByEventId(request).then(
        (response) => {
          if (response.status === 200 && response.data != null) {
            // var filename = `${detail}${formattedDate}`;
            let blob = new Blob([response.data]);
            saveAs(blob, `${eventName}_Bookings` + ".xlsx");
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isDownloadingExcel: false });
        }
      );
    }
  };

  onTagging = (id) => {
    let event = this.state.eventList.find((x) => x.eventId === id);
    if (event) {
      if (event.status === "Draft") {
        this.swalServices.Error("Please submit the entry");
      } else {
        this.setState({
          route: "/CategoryCampaignTagging/eventId?=" + encryptAES(id),
        });
      }
    }
  };
  onDelete = (eventId, index, value, eventTimeSlotId) => {
    let request = {
      eventId: eventId,
      eventTimeSlotId: eventTimeSlotId || 0,
    };
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "Entry"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "entry details"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.entryServices
            .deleteEntryDetailsByIds(request)
            .then((response) => {
              if (
                response.statusCode === 200 &&
                response.responseItem != null
              ) {
                this.swalServices.Success(
                  "Entry details deleted successfully."
                );
                this.getEntryDetailList();
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
        }
      });
  };

  handleChangeForCreditNote = (event, identity) => {
    let { name, value } = event.target;
    let detail = { ...this.state.cancelBookingDetails };
    if (identity === "CreditNote") {
      detail[name] = value;
      this.setState({ cancelBookingDetails: { ...detail } });
      this.setState({ cancelBookingDetails: detail });
    }
  };

  getCancellationReasonForCreditNote = () => {
    this.financeService
      .getCancellationReasonForCreditNote()
      .then((response) => {
        if (
          response.statusCode === 200 &&
          response.responseItem.responseContent != null &&
          response.responseItem.responseContent.length > 0
        ) {
          let cancelReason = response.responseItem.responseContent;
          this.setState({ cancellationReason: cancelReason });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  setFilterParameters = (id, drpIdentity) => {
    let cancel_reason = this.state.cancellationReason;
    let data = { ...this.state.cancelBookingDetails };

    //let details = { ...this.state.corporateCreditNotePaymentInfo }
    let findData = cancel_reason.filter((x) => x.id === id);
    if (findData.length > 0) {
      data.cancellationReasonId = findData[0].id;
      if (findData[0].name === "Others") {
        data.cancellationReason = "";
        this.setState({ isOtherCancellationReason: true });
        //this.validateFinanceCreditNote("cancellationReasonId", data);
      } else {
        data.cancellationReason = findData[0].name;
        this.setState({ isOtherCancellationReason: false });
      }
    } else {
      data.cancellationReason = "";
      data.cancellationReasonId = 0;
      this.setState({ isOtherCancellationReason: false });
    }
    this.validateFinanceCreditNote("cancellationReasonId", data);
    this.setState({ cancelBookingDetails: data });
  };

  validateFinanceCreditNote = (key, providedState) => {
    providedState = providedState || this.state.cancelBookingDetails;
    const newValidState = validate(
      key,
      providedState,
      this.state.cancelBookingDetailsValidationRules,
      this.state.validCreditNote
    );
    this.setState({ validCreditNote: newValidState });
  };

  isValidFinanceCreditNote = () => {
    const newValidState = isValidForm(
      this.state.cancelBookingDetails,
      this.state.cancelBookingDetailsValidationRules,
      this.state.validCreditNote
    );
    this.setState({ validCreditNote: newValidState });
    return newValidState.isValid;
  };

  addFinanceCreditNote = () => {
    if (this.isValidFinanceCreditNote()) {
      let request = {
        EventId: this.state.cancelBookingDetails.eventId,
        EventTimeSlotId: this.state.cancelBookingDetails.eventTimeSlotId,
        IsEvent: true,
        CreditNoteMonth: this.state.cancelBookingDetails.creditNoteMonth,
        CreditNoteStatusId: 1,
        CreditNoteCancellationReason:
          this.state.cancelBookingDetails.cancellationReason,
        CreatedAppId: 114,
        CreatedAt: moment(),
        CreatedBy: 1,
      };
      this.financeService.addFinanceCreditNote(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (
            response.responseItem.responseContent.creditNoteNumber === "" &&
            response.responseItem.responseContent.errorMessage === null
          ) {
            this.swalServices.Error(
              ErrorMessage.NoRecordFound.replace("{0}", "Credit note")
            );
          } else if (
            response.responseItem.responseContent.errorMessage === ""
          ) {
            this.swalServices.Success(
              SuccessMessage.Insert_Success.replace("{0}", "Credit Note")
            );
          } else {
            this.swalServices.Error(
              ErrorMessage.Already_Exist.replace("{0}", "Credit note")
            );
          }
        } else {
          this.swalServices.Error(ErrorMessage.DefaultMessage);
        }
        this.setState({ isLoading: false });
      });
    }
  };

  filterByFeatured = (value, e) => {
    let pagination = this.state.pagination;
    pagination.isCalendarView = e.target.checked;
    this.setState({ pagination: pagination }, () => {
      this.getEntryDetailList();
    });
  };



  changeCalendarListView = (view) => {
    let pagination = this.state.pagination;
    pagination.isCalendarView = view;
    this.setState({ pagination: pagination }, () => {
      this.getEntryDetailList();
    });
  };

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="toggle-btn-part">
          {/* <ToggleButton
            label="Calender View"
            setToggle={this.state.pagination.isCalendarView}
            onHandleToggle={this.filterByFeatured.bind(this)}
            index={0}
          /> */}
          <button
            className={`btn btn-blue text-white ${this.state.pagination.isCalendarView === true ? "active" : ""}`}
            onClick={() => this.changeCalendarListView(true)}
          >
            <span className="text-xl text-white font-semibold tracking-normal">
              Calendar View
            </span>
          </button>
          <button
            className={`btn btn-blue text-white ${this.state.pagination.isCalendarView === false ? "active" : ""}`}
            onClick={() => this.changeCalendarListView(false)}
          >
            <span className="text-xl text-white font-semibold tracking-normal">
              List View
            </span>
          </button>
        </div>
        <div
          className={`main-body-section ${this.state.pagination.isCalendarView === true ? " hidden" : ""
            }`}
        >
          <div className="custom-card shadow-lg">
            <div className="filter-info grid grid-cols-12 gap-6">
              <div className="filter-types xl:col-span-5 lg:col-span-5 col-span-12 pr-12">
                <label
                  className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Search by Event Name
                </label>
                <input
                  id="eventName"
                  name="eventName"
                  className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) => this.searchByName(e.target.value)}
                />
              </div>
              <div className="filter-types xl:col-span-5 lg:col-span-5 col-span-12 pr-12">
                <label
                  className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Status
                </label>
                <Selector
                  options={this.state.eventStatus}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{
                  //   Option,
                  // }}
                  onChange={this.handleChangeInDropdown.bind(this)}
                  allowSelectAll={false}
                  value={this.state.pagination.optionSelected}
                  drpIdentity="Status"
                />
              </div>
              <div className="filter-types xl:col-span-5 lg:col-span-5 col-span-12 pr-12">
                <label
                  className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                  htmlFor="default"
                >
                  Hidden
                </label>
                <Selector
                  options={this.state.hiddenOption}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  onChange={this.handleChangeInDropdown.bind(this)}
                  allowSelectAll={false}
                  value={this.state.hiddenOption.find(
                    (x) => x.label === this.state.pagination.hiddenSearch
                  )}
                  drpIdentity="Hidden"
                />
              </div>
              <div className="xl:col-span-2 lg:col-span-2 col-span-12">
                <div className="flex items-end justify-end h-full pb-1">
                  <button
                    className="btn btn-red text-white"
                    onClick={() => this.getEntryDetailList()}
                  >
                    <span className="text-xl text-white font-semibold tracking-normal">
                      Search
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Event list section start */}
          <div className="grid grid-cols-6 gap-4 pt-10 px-8">
            <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#757575]">
              {`Total:${this.state.eventList.length}`}
            </h2>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6 view-edit-entries-tble">
                    <Table
                      columns={[
                        { name: "eventName", title: "Event Name" },
                        { name: "eventDate", title: "Event Date" },
                        { name: "eventTypeName", title: "Type" },
                        { name: "isHidden", title: "Hidden" },
                        { name: "booking", title: "Booking" },
                        { name: "status", title: "Status" },
                        { name: "eventId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.eventList}
                      sortingColumns={[
                        "eventName",
                        "eventDate",
                        "eventTypeName",
                      ]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "eventId",
                          // column:"eventTimeSlotId",
                          renderTableData: this.actions.bind(this),
                        },
                        // {
                        //   // column: "eventId",
                        //   column:"eventTimeSlotId",
                        //   renderTableData: this.actions.bind(this),
                        // },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>

          <div className="generate-credit-note-popup">
            <CancelAllBookingsModel
              setCancelAllBookingsModel={this.state.setCancelAllBookingsModel}
              setOpenModal={() =>
                this.setState({ setCancelAllBookingsModel: false }, () => {
                  this.setState({ cancelBookingDetails: {} });
                })
              }
              onClose={(e) => {
                e.stopPropagation();
                this.setState({ setCancelAllBookingsModel: false }, () => {
                  this.setState({ cancelBookingDetails: {} });
                });
              }}
              handleChangeForCreditNote={this.handleChangeForCreditNote.bind(
                this
              )}
              cancelBookingDetails={this.state.cancelBookingDetails}
              addEdit={this.addFinanceCreditNote.bind(this)}
              cancellationReason={this.state.cancellationReason}
              setFilterParameters={this.setFilterParameters.bind(this)}
              isOtherCancellationReason={this.state.isOtherCancellationReason}
              validate={this.validateFinanceCreditNote.bind(this)}
              validCreditNote={this.state.validCreditNote}
            />
          </div>
        </div>
        {/* Calendar Event Start */}
        <div
          className={`event-calender-sec ${this.state.pagination.isCalendarView === false ? "hidden" : ""
            }`}
        >
          <EventCalender
            eventList={this.state.eventList}
            onTagging={this.onTagging.bind(this)}
            onDelete={this.onDelete.bind(this)}
            actionDropdown={this.state.actionDropdown}
            actionDropdown2={this.state.actionDropdown2}
            actionDropdown3={this.state.actionDropdown3}
            actionClick={this.actionClick.bind(this)}
          />
        </div>
        {/* Calendar Event End */}
      </>
    );
  }
}
