import { Component } from "react-image-crop";
import CreateButton from "../../../components/UIComponent/Buttons/CreateButton";
import Table from "../../../components/table/Table";
import ActionEditButton from "../../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionDeleteButton from "../../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import LeadershipPositionsServices from "../../../services/axiosServices/apiServices/LeadershipPositionsServices";
import { encryptAES } from "../../../utils/Encryption";
import { Navigate } from "react-router-dom";
import SwalServices from "../../../services/swalServices/SwalServices";

export default class ManageSecretariatDetails extends Component {
    constructor(props) {
        super(props);
        this.LeadershipPositionsServices = new LeadershipPositionsServices();
        this.swalServices = new SwalServices();
        this.onPageChange = null;
        this.state = {
            route: "",
            isLoading: false,
            totalResultes: 0,

            SecretariatDetails: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
            },
        };
    }

    componentDidMount() {
        if (!this.onPageChange)
            this.onPageChange = this.getAllSecretariatDetailsList;
        this.getAllSecretariatDetailsList();
    }

    setPagination = (newPagination) => {

        this.setState({ pagination: newPagination });
        if (this.onPageChange) {
            this.onPageChange();
        }
        this.getAllSecretariatDetailsList();
    }

    actions = (element, index, value) => {
        return (element !== "SecretariatDetailsId") ? null
            :
            <td>
                <ActionContainer>

                    <ActionEditButton
                        value={value}
                        id={index}
                        index={index}
                        onActionClick={this.onEdit}
                        text="Edit"
                    />
                    <ActionDeleteButton
                        value={value}
                        id={index}
                        index={index}
                        onActionClick={this.onDelete}
                        text="Delete"
                    />
                </ActionContainer>
            </td>
    }
    onEdit = (id) => {
        this.setState({ route: '/AddEditSecretariatDetails/SecretariatDetailsId?=' + encryptAES(id) });
    }

    onDelete = (SecretariatDetailsId) => {
        let request = {
            id: SecretariatDetailsId,
            deletedBy: 115,
            appId: 15
        }
        this.LeadershipPositionsServices.deleteSecretariatDetails(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ status: response.responseItem.responseContent });
                this.swalServices.Success("Deleted");
                this.getAllSecretariatDetailsList();
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    getAllSecretariatDetailsList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });

        this.LeadershipPositionsServices.getAllSecretariatDetailsList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                const SecretariatDetails = response.responseItem.responseContent.itemList.map(item => {

                    return {
                        ...item,
                        name: `${item.firstName || ''} ${item.lastName || ''}`.trim(),
                    };
                });
                // debugger;
                this.setState({ SecretariatDetails: SecretariatDetails });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }


    render() {
        if (this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div className="main-body-section">
                <div className="flex">
                    <div className="relative flex flex-col flex-1">
                        <main className="pb-10">
                            <div className="w-full mx-auto">
                                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="table-title-txt theme-color font-bold">
                                            {this.state.totalResultes} Secretariat Member
                                        </h1>
                                    </div>
                                    <CreateButton
                                        redirect="/AddEditSecretariatDetails"
                                        text="Create New"
                                    />
                                </div>
                                <div className="relative">
                                    <Table
                                        columns={[
                                            { name: "name", title: "Name" },
                                            { name: "designation", title: "Designation" },
                                            { name: "description", title: "Description" },
                                            { name: "positionId", title: "Arrangement Position" },
                                            {
                                                name: "SecretariatDetailsId",
                                                title: "Action",
                                            },
                                        ]}
                                        rows={this.state.SecretariatDetails}
                                        isLoading={this.state.isLoading}
                                        pagination={this.state.pagination}
                                        sortingColumns={[]}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                        customScope={[
                                            {
                                                column: 'SecretariatDetailsId',
                                                renderTableData: this.actions.bind(this)
                                            },
                                        ]}
                                    />

                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}
