import React, { Component } from "react";
import OrganisationBasicInformation from "../../../components/membership/corporateMembers/OrganisationBasicInformation";
import EmployeesList from "../../../components/membership/corporateMembers/EmployeesList";
import EngagementTrackingNotes from "../../../components/membership/corporateMembers/EngagementTrackingNotes";
import FinancialsRecord from "../../../components/membership/corporateMembers/FinancialsRecord";
import OrganisationChapterRole from "../../../components/membership/corporateMembers/OrganisationChapterRole";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import SIISCorporateAccountInformation from ".././../../components/membership/corporateMembers/corporateMemberSegment/SIISCorporateAccountInformation";
import ProspectInformation from "../../../components/membership/corporateMembers/corporateMemberSegment/ProspectInformation";
import { validate, isValidForm, } from "../../../utils/validation/CommonValidator";
import SwalServices from "../../../services/swalServices/SwalServices";
import { CommonSuccessMessages, CommonValidationMessages, ErrorMessage, SuccessMessage } from "../../../utils/Messages";
import FinanceCard from "../../../components/membership/corporateMembers/corporateMemberSegment/FinanceCard";
import CorporateMembershipSubscription from "../../../components/membership/corporateMembers/corporateMemberSegment/CorporateMembershipSubscription";
import OrganisationBasicInformations from "../../../components/membership/corporateMembers/corporateMemberSegment/OrganisationBasicInformation";
import OrganisationProfile from "../../../components/membership/corporateMembers/corporateMemberSegment/OrganisationProfile";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import SuperUserLoginModal from "./SuperUserLoginModal";
import { setAuthProps } from "../../../utils/AuthenticationLibrary";
import { encryptAES } from "../../../utils/Encryption";
import { removeAllCookies } from "../../../utils/cookies/CookieHandler";
import {
  engagementTrackingValRules,
  organisationKeyRolesValRules,
  organizationProfileValRules,
  organizationBasicInformationValRules,
  chapterInformationValRules,
  memberSubscriptionValRules,
  prospectInformationValRules,
  siisAccountInformationValRules,
  organizationEmployeesValRules,
  financeCardValRules,
  addEditCreditNoteValRules,
  addEditCreditNoteValRules2,
  searchByEmailValRule,
  organizationBusinessDetailsValRules,
} from "./CorporateValidations";
import moment from "moment";
import { saveAs } from 'file-saver';
import FinanceServices from "../../../services/axiosServices/apiServices/FinanceServices";
import { convertToDecimal, isNullBoolean, isNullString } from "../../../utils/Utils";
import { monthDifference } from "../../../utils/DateUtils";
import { checkParameters } from "../../../utils/Encryption";
import GoGlobalServices from "../../../services/axiosServices/apiServices/GoGlobalService";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import Endorsers from "../../../components/membership/corporateMembers/Endorsers";
import IndividualMemberService from "../../../services/axiosServices/apiServices/IndividualMemberService";
import { WebPath } from "../../../utils/AppSetting";
import Cookies from "universal-cookie";
import PastApplication from "../../../components/membership/corporateMembers/corporateMemberSegment/PastApplication";
import MembershipTaskListServices from "../../../services/axiosServices/apiServices/MembershipTaskListServices";


export default class CreateCorporateMembers extends Component {
  constructor(props) {
    super(props);
    this.corporateMemberServices = new CorporateMemberServices();
    this.financeServices = new FinanceServices();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.goGlobalServices = new GoGlobalServices();
    this.financeService = new FinanceServices();
    this.individualMemberServices = new IndividualMemberService();
    this.TaskListServices = new MembershipTaskListServices();

    this.state = {
      setLoginToCorporateModal: false,
      availableChapterListForUpcomingTenure: [],
      passwordForCorporateModal: "",
      isLoadingForJoinChapter: false,
      adminRoleId: 0,
      isLoading: false,
      isLoadingForRenewal: false,
      isOrganizationBasicInfoLoading: false,
      isOrganizationProfileLoading: false,
      isEngagementTrackingLoading: false,
      isCorporateAccountLoading: false,
      isProspectInformationLoading: false,
      isFinanceCardLoading: false,
      isOtherInfoLoading: false,
      isMembershipSubscriptionLoading: false,
      Organisationrole: [],
      chaptercommitteerol: [],
      chapterCommitteeRole: [],
      openSection: "SIISAccountInformation",
      mrMrs: [],
      status: [],
      reason: [],
      _by: [],
      recorded_by: [],
      source1: [],
      prospectStatus: [],
      recordType: [],
      billingCategory: [],
      sgTech: [],
      sponsorship: [],
      matching: [],
      programs: [],
      engagementType: [],
      chaptersList: [],
      emailDomain: [],
      domainArr: [],
      membershipTypes: [],
      countriesWithPresence: [],
      corporateGrossTurnovers: [],
      organizationRoleArray: [],
      corporateInternalFlags: [],
      prospectSource2: [],
      memberId: 0,
      corporatePIC1: [],
      corporatePIC2: [],
      corporateCreditTerms: [],
      interestedChapter: [],
      getToKnowUsArr: [],
      mainGSTRate: 0,
      corporateSIISAccount: {
        memberId: 0,
        isAccountActive: 0,
        corporateReasonLeavingId: 0,
        corporateJoinedDate: "",
        updatedDate: "",
        updatedById: 0,
        corporateProspectSourceId: 0,
        corporateProspectStatusId: 0,
        corporateMemberRecordTypeId: 0,
        corporateMemberBillingCategoryId: 0,
        corporateSGTechId: 0,
        corporateSponsorshipId: 0,
        corporateMatchingId: 0,
        corporateStrategicProgrammesId: 0,
        adHocActivityEvent: false,
        monthlySGTechEdition: false,
        sGTechAnnouncements: false,
        fortnightlyEvents: false,
        businessOpportunities: false,
        talentCapabilities: false,
        memberId: 0,
        selectedSGTech: [],
        selectedSponsorship: [],
        selectedMatching: [],
        selectedProgram: [],
      },
      siisAccountInformation: {
        memberId: 0,
        siisAccountId: "",
        engagementScore: 0,
        accountStatus: 7,
        corporatejoinedDate: null,
        corporateReasonLeavingId: 0,
        renewalNotes: "",
        updatedAt: null,
        createdBy: 1,
        internalFlagId: 0,
        companyDomain: "",
        quotationNeeded: false,
        sendInvoiceReminder: 0,
        creditTermsId: 0,
        stratigicMember: false,
        projectsInvolved: 0,
        isFeatured: false,
        projectName: "",
        isGoGlobal: false,
        companyUEN: "",
        isAppliedForReinstatement: false
      },
      chapterInformation: {
        workgroupMappingId: 0,
        memberId: 0,
        availableChapters: 0,
        joinedChapters: 0,
        updatedBy: 1,
        updatedAt: null,
        purchasedChapters: 0,
        // startDate: '',
        // endDate: '',
      },
      paymentsMethods: [
        {
          id: 3,
          name: "Pay Now",
        },
        {
          id: 4,
          name: "Bank Transfer",
        },
      ],
      responseMessage: "",
      voucherCode: "",
      discountedAmount: 0,
      isFixedPrice: false,
      loadingForPayment: false,
      validStateEmp: {
        isValid: true,
        error: {},
      },
      engagementTrackingList: [],
      employeeList: [],
      organisationRoles: [],
      isEmployeeModalEditable: false,
      nameSearch: "",
      isLoaderOn: false,
      validState: {
        isValid: true,
        error: {},
      },
      validStateOrg: {
        isValid: true,
        error: {},
      },
      validStateSIISInfo: {
        isValid: true,
        error: {}
      },

      validStateChapter: {
        isValid: true,
        error: {}
      },

      validMemberSub: {
        isValid: true,
        error: {}
      },

      validProspectMem: {
        isValid: true,
        error: {}
      },

      validSiisAccountInfo: {
        isValid: true,
        error: {}
      },

      validOrgBasicInfo: {
        isValid: true,
        error: {}
      },

      validStateFinanceCard: {
        isValid: true,
        error: {}
      },
      disableUenNumber: false,
      organizationBasicInformation: {
        organisationInformationId: 0,
        companyUEN: "",
        companyName: "",
        incorporationDate: "",
        firstName: "",
        lastName: "",
        designation: "",
        email: "",
        contactNo: "",
        companyDomain: "",
        mrMrsId: 0,
        //selectedDesignation: [],
      },
      designationList: [],
      contactRoles: [],
      serviceType: [],
      industriesServed: [],
      associationBenefits: [],
      getToKnow: [],
      ordinaryEndorsers: [],
      allEndorsers: [],
      ordinaryRepresentative: [],
      allRepresentative: [],
      endorsersDetails: {
        endorsersId1: 0,
        endorsersId2: 0,
        representativeId1: 0,
        representativeId2: 0
      },
      organizationProfile: {
        memberId: 0,
        organisationProfileId: 0,
        orgLogoFileName: "",
        orgGrossTurnoverId: 0,
        generalBusinessTelephone: "",
        organisationWebsite: "",
        generalBusinessEmail: "",
        countryId: 0,
        updatedBy: 0,
        updatedAt: "",
        paidUpCapital: '',
        staffStrength: 0,
        registeredStreetName: "",
        registeredBuildingName: "",
        registeredNoUnitNumber: false,
        registeredPostalCode: "",
        mailingStreetName: "",
        mailingBuildingName: "",
        mailingNoUnitNumber: false,
        mailingPostalCode: "",
        acraFilePath: "",
        isMTORegistry: false,
        employmentSize: 0,
        membershipTenureId: 0,
      },
      imageDetails: {
        memberId: 0,
        profileImagePath: "",
        isCorporate: 0,
        fileName: "",
        fileStr: "",
        storagePath: "",
      },
      prospectInformation: {
        corporateProspectId: 0,
        source1Id: 0,
        source2Id: 0,
        source3: "",
        pic1Id: 0,
        pic2Id: 0,
        firstEngagementDate: null,
        prospectStatusId: 0,
        updatedBy: 0,
        updatedAt: null,
        comments: "",
        getToKnow: "",
        interestedChaptersId: "",
        selectedInterestChapterArr: [],

      },
      organizationBusinessDetails: {
        organisationBusinessDetailId: 0,
        typesOfServices: [],
        typesOfIndustriesServed: [],
        countriesPresence: [],
        associationBenefits: [],
        getToKnowId: 0,
        getToKnowOthers: '',
        associationBenefitsOthers: '',
        others: '',
        updatedBy: 0,
        updatedAt: null,
      },
      membershipSubscription: {
        corporateMemberSubscriptionId: 0,
        membershipTypeId: 0,
        billingCategoryId: 1,
        subscriptionStartDate: null,
        subcriptionEndDate: null,
        updatedBy: 0,
        updatedAt: null,
        incorporationDate: "",
        commencementDate: "",
        membershipRevenueId: 0,
        tacFromId: 0,
        tacName: '',
        isAligibleForNewApplication: false,
        resignationFlag: false,
      },
      corporateCommunication: {
        corporateCommunicationId: 0,
        adHocActivityEvent: true,
        monthlySGTechEdition: true,
        sGTechAnnouncements: true,
        fortnightlyEvents: true,
        businessOpportunities: true,
        talentCapabilities: true,
        updatedBy: 0,
        updatedAt: null
      },
      employeeDetails: {
        memberId: 0,
        contactRoleId: 0,
        mrMrsId: 0,
        firstName: "",
        lastName: "",
        emailAddress: "",
        bussinessMobile: null,
        officeDID: "",
        sameAsFirstFlag: false,
        experienceYears: "",
        yearsId: 0,
        contactRoleName: "",
        designation: "",
        emailSubscriptionId: "",
        emailSubscription: [],
        emailDomainId: 0,
        emailText: "",
        emailString: "",
      },
      organisationDetails: {
        memberId: 0,
        contactRoleId: 0,
        mrMrsId: 0,
        firstName: "",
        lastName: "",
        emailAddress: "",
        bussinessMobile: null,
        officeDID: "",
        sameAsFirstFlag: false,
        experienceYears: "",
        yearsId: 0,
        contactRoleName: "",
        designation: "",
        emailSubscriptionId: "",
        emailSubscription: [],
        emailDomainId: 0,
        emailText: "",
        emailString: "",
      },
      individualOrganisationRole: "",
      years: [],
      emailSubscription: [],
      engagementTrackingDetails: {
        corporateEngagementTrackingId: 0,
        corporateEngagementTypeId: 0,
        corporateEngagementDate: null,
        engagementDate: "",
        engagementDescription: "",
        corporateEngagementDescription: "",
        corporateEngagementRecordedById: 0,
        corporateEngagementFollowUpById: 0,
        memberId: 0,
        updatedAt: "",
        updatedBy: 0,
      },
      corporateFinance: {
        quotationNeeded: false,
        sendInvoiceReminder: true,
        creditTermsId: 1,
        financeId: 0,
        memberId: 0,
      },
      governmentProgramme: {
        stratigicMember: true,
        projectsInvolved: 0,
        governmentProgrammeId: 0,
        memberId: 0,
        projectName: "",
      },
      engagemmentTrackingList: [],
      organisationRoleArray: [],
      validStateProfile: {
        isValid: true,
        error: {},
      },
      validStateRoles: {
        isValid: true,
        error: {},
      },
      activeProjects: [],
      futureProjects: [],
      pastProjects: [],
      govProjectsNameList: [],
      corporateFinanceInfo: [],
      corporateCreditNotePaymentInfo: {},
      cancellationReason: [],
      selectedCancellationReason: [],
      isOtherCancellationReason: false,
      addEditCreditNote: {
        membershipPaymentId: 0,
        entranceRefund: 0,
        annualRefund: 0,
        chapterRefund: 0,
        subTotalRefund: 0,
        gstRefund: 0,
        discountRefund: 0,
        totalRefund: 0,
        cancellationReason: "",
        creditNoteMonth: "",
        compareEntranceRefund: 0,
        compareAnnualRefund: 0,
        compareChapterRefund: 0,
        membershipPaymentTypeId: 0,
        paidTotalAmount: 0
      },
      gstRate: 0,
      withoutGstRate: 1.07,
      addEditCreditNoteRules: [],
      validCreditNote: {
        isValid: true,
        error: {}
      },
      validCreditNote2: {
        isValid: true,
        error: {}
      },
      addEditOrganisationModel: false,
      isAddEditAOrganisation: false,
      isAddEditProfileButtonLoader: false,
      isCreateNewOrgRole: false,
      isCreateNewOrgRoleForEmployee: false,
      isOrganisationModalEditable: false,
      searchByEmployeeName: "",
      addEditModel: false,
      addEditModelForEmployee: false,
      companyType: [],
      countryWithPresence: [],
      countryNameList: [],
      businessSectorList: [],
      serviceProvidedList: [],
      partnerTypeList: [],
      regionList: [],
      cityNameList: [],
      isSelectedGoGlobal: false,
      charLimit: 1000,
      validStateForGoGlobal: {
        isValid: true,
        error: {},
      },

      tacNames: [],
      isReinstatementLoader: false
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let corporateId = params.get("")
    let loginUser = getAuthProps();
    if (loginUser?.adminUserId && loginUser.adminUserId > 0) {
      this.setState({ adminRoleId: loginUser.adminUserId });
    } else {
      this.setState({ adminRoleId: 0 });
    }
    if (corporateId && (corporateId = checkParameters(corporateId, "Number"))) {
      if (corporateId != null && corporateId > 0) {
        this.setState({ memberId: corporateId }, () => { this.setState({ disableUenNumber: true }); });
      }
    }
    this.getAllDropdownForCorporateMember();
    this.getGSTRate();
  }

  getGSTRate = () => {
    this.financeServices.getGSTRate().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let data = response.responseItem.responseContent.gstRate ? response.responseItem.responseContent.gstRate : 0;
        this.setState({ mainGSTRate: data }, () => {
        });
      } else {
      }
    });
  }

  getEmployeeList = (id, nameSearch) => {
    if (id > 0 && id != null) {
      let request = [id, nameSearch]
      this.corporateMemberServices.getEmployeeList(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.setState({ employeeList: response.responseItem.responseContent });
          // this.getOrganisationRoleList(this.state.memberId);
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getOrganisationRoleList = (id) => {
    if (id > 0 && id != null) {
      this.corporateMemberServices.getOrganisationRoleList(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({ Organisationrole: response.responseItem.responseContent }, () => {
            // this.getChapterCommitteeRoles(this.state.memberId);
          });
        }
        else {
          // this.getChapterCommitteeRoles(this.state.memberId);
          this.swalServices.Error(response.message);
        }
        this.setState({ isOrganizationProfileLoading: false });
      })
    }
  }

  getChapterCommitteeRoles = (id) => {
    if (id > 0 && id != null) {
      this.corporateMemberServices.getChapterCommitteeRoles(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.setState({ chaptercommitteerol: response.responseItem.responseContent });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  handleChangeCheckBoxFinanceCard = (e) => {
    const { name, checked } = e.target;
    let det = this.state.corporateFinance;
    det[name] = checked;
    this.setState({ corporateFinance: { ...det } });
  };

  handleChangeCheckBoxGovProgram = (e) => {
    const { name, checked } = e.target;
    let det = this.state.governmentProgramme;
    det[name] = checked;
    this.setState({ governmentProgramme: det });
  };

  setFilterParametersFinanceCard = (id, drpIdentity) => {
    let request = this.state.corporateFinance;

    if (drpIdentity === "CreditTerms") {
      request["creditTermsId"] = id;
      // this.validateFinanceCard("creditTermsId");
    }
    this.setState({ corporateFinance: request });
  }
  setFilterParametersEmployee = (id, drpIdentity) => {
    let request = this.state.employeeDetails;
    if (drpIdentity === "mrMrsId") {
      request["mrMrsId"] = id;
    } else if (drpIdentity === "Employee_OrganisationRole") {
      request["contactRoleId"] = id;
    } else if (drpIdentity === "Years") {
      request["yearsId"] = id;
    }
    this.setState({ employeeDetails: request });
  }
  setFilterParametersOrgRoles = (id, drpIdentity) => {
    let request = this.state.organisationDetails;
    if (drpIdentity === "Org_MrMrs") {
      request["mrMrs"] = id;
    } else if (drpIdentity === "Org_OrganisationRoles") {
      request["contactRoleId"] = id;
      this.setState({ individualOrganisationRole: id })
    } else if (drpIdentity === "Years") {

      request["yearsId"] = id;
    }
    this.setState({ organisationDetails: request });

  }

  addEditFinanceCardDetails = () => {
    if (this.state.memberId > 0 && this.state.memberId != null) {
      var request = this.state.corporateFinance;
      request.memberId = this.state.memberId;
      request.createdBy = 1;
      request.createdAppId = 115;
      // if (this.isValidFinanceCard()) {
      this.setState({ isFinanceCardLoading: true });
      this.corporateMemberServices.addEditFinanceCardDetails(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Finance details updated successfully.");
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isFinanceCardLoading: false });
      })
      // }
    } else {
      this.swalServices.Warning("Please enter SLA corporate account information.");
    }
  }

  getAllDropdownForCorporateMember = () => {
    this.corporateMemberServices.getAllDropdownForCorporateMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let status = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "AccountStatus"
        );
        let reason = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ReasonForLeaving"
        );
        let _by = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "By"
        );
        let source1 = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ProspectSource1"
        );
        let prospectStatus = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ProspectStatus"
        );
        let recordType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "RecordType"
        );
        let billingCategory = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "BillingCategory"
        );
        let domain = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );
        let domainArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );
        let tac = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "TACNames"
        );
        let membershipTypes = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipTypes"
        );
        let corporateInternalFlags = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporateInternalFlags"
        );
        let source2 = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ProspectSource2"
        );
        let corporatePIC1 = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporatePIC1"
        );
        let corporatePIC2 = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporatePIC2"
        );
        let corporateCreditTerms = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporateCreditTerms"
        );
        let years = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Years"
        );
        let getToKnowUsArr = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "GetToKnowUs");

        domainArr.map(function (item) {
          return delete item["dropdownName"];
        });
        domainArr = domainArr.map((p) => ({
          id: p.id,
          name: "@" + p.name,
        }));

        let sgTech = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "SGTech"
        );
        sgTech.map(function (item) {
          return delete item["dropdownName"];
        });
        sgTech = sgTech.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let sponsorship = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Sponsership"
        );
        sponsorship.map(function (item) {
          return delete item["dropdownName"];
        });
        sponsorship = sponsorship.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let matching = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Matching"
        );
        matching.map(function (item) {
          return delete item["dropdownName"];
        });
        matching = matching.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let program = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "StrategicProgrammes"
        );
        program.map(function (item) {
          return delete item["dropdownName"];
        });
        program = program.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let engagement = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "EngagementType"
        );
        let chapter = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Chapters"
        );

        let interestedChapter = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Chapters');
        interestedChapter.map(function (item) { return delete item['dropdownName']; });
        interestedChapter = interestedChapter.map(p => ({ value: p.id, label: p.name }));


        let mrMrs = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MrMrs"
        );

        let countries = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Countries"
        );
        countries.map(function (item) {
          return delete item["dropdownName"];
        });
        countries = countries.map((p) => ({ value: p.id, label: p.name }));

        let business = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "BussinessSectors");
        business.map(function (item) {
          return delete item["dropdownName"];
        });
        business = business.map((p) => ({ value: p.id, label: p.name }));

        let coService = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CoProductServices");
        coService.map(function (item) {
          return delete item["dropdownName"];
        });
        coService = coService.map((p) => ({ value: p.id, label: p.name }));

        let corporateGrossTurnovers = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporateGrossTurnovers"
        );

        let designation = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Designation");
        designation.map(function (item) {
          return delete item["dropdownName"];
        });
        designation = designation.map((p) => ({ value: p.id, label: p.name }));

        let organisationRoles = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "OrganisationRoles");
        organisationRoles.map(function (item) {
          return delete item["dropdownName"];
        });
        organisationRoles = organisationRoles.map((p) => ({ value: p.id, label: p.name }));

        let target = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "TargetMarket");
        target.map(function (item) {
          return delete item["dropdownName"];
        });
        target = target.map((p) => ({ value: p.id, label: p.name }));

        let recorded_by = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "By"
        );

        recorded_by.map(function (item) {
          return delete item["dropdownName"];
        });
        recorded_by = recorded_by.map((p) => ({ value: p.id, label: p.name }));

        let contactRoles = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ContactRole"
        );


        // contactRoles.map(function (item) {
        //   return delete item["dropdownName"];
        // });
        // contactRoles = contactRoles.map((p) => ({ value: p.id, label: p.name }));

        // let contactRoles = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ContactRole");
        // contactRoles = contactRoles.filter((item) => item.id !== 1 && item.id !== 2);
        // contactRoles.forEach((item) => delete item.dropdownName);
        // contactRoles = contactRoles.map((p) => ({ value: p.id, label: p.name }));

        let serviceType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ServicesType");
        serviceType.map(function (item) {
          return delete item["dropdownName"];
        });
        serviceType = serviceType.map((p) => ({ value: p.id, label: p.name }));

        let industriesServed = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "IndustriesServed");
        industriesServed.map(function (item) {
          return delete item["dropdownName"];
        });
        industriesServed = industriesServed.map((p) => ({ value: p.id, label: p.name }));

        let getToKnow = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporateGetToKnowUs"
        );

        let associationBenefits = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AssociationBenefits");
        associationBenefits.map(function (item) {
          return delete item["dropdownName"];
        });
        associationBenefits = associationBenefits.map((p) => ({ value: p.id, label: p.name }));


        let emailSubscription = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EmailSubscription");
        emailSubscription = emailSubscription.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        this.setState({
          status: status, reason: reason, _by: _by, source1: source1,
          prospectStatus: prospectStatus, recordType: recordType, billingCategory: billingCategory,
          engagementType: engagement, sgTech: sgTech, sponsorship: sponsorship, matching: matching,
          programs: program, chaptersList: chapter, mrMrs: mrMrs, countriesWithPresence: countries,
          coProductService: coService,
          organizationRoleArray: organisationRoles, membershipTypes: membershipTypes,
          corporateInternalFlags: corporateInternalFlags, corporateGrossTurnovers: corporateGrossTurnovers,
          prospectSource2: source2,
          corporatePIC1: corporatePIC1, corporatePIC2: corporatePIC2, corporateCreditTerms: corporateCreditTerms,
          interestedChapter: interestedChapter, getToKnowUsArr: getToKnowUsArr, designationList: designation, contactRoles: contactRoles,
          recorded_by: recorded_by, tacNames: tac, serviceType: serviceType, industriesServed: industriesServed, getToKnow: getToKnow,
          associationBenefits: associationBenefits, years: years, emailSubscription: emailSubscription
        }, () => {
          if (this.state.memberId > 0) {
            this.getSIISCorporateAccountInformationById(this.state.memberId);

            this.setState({ emailDomain: domain }, () =>
              this.getDomainByOrganisationId(this.state.memberId)
            );
            this.setState({ domainArr: domainArr }, () =>
              this.getDomainByOrganisationId(this.state.memberId)
            );
          }
          let emailArray = this.state.emailSubscription
          this.handleChangeMultiDropdown(emailArray, "EmailSubscriptionOrg")
          this.handleChangeMultiDropdown(emailArray, "EmailSubscriptionEmployee")
        });
      } else {
        if (this.state.memberId > 0) {
          this.getSIISCorporateAccountInformationById(this.state.memberId);
          this.setState({ emailDomain: [] }, () =>
            this.getDomainByOrganisationId(this.state.memberId)
          );
          this.setState({ domainArr: [] }, () =>
            this.getDomainByOrganisationId(this.state.memberId)
          );
        }
      }
    });
  };

  validateOrganisationProfile = (key) => {

    let validationRule = organizationProfileValRules
    if (this.state.organizationProfile.registeredNoUnitNumber !== true) {
      let registerUnitNumbervalidation = [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace("{0}", "unit number & building name of regeistered address"),
        },
      ];
      validationRule.registeredBuildingName = registerUnitNumbervalidation
    }

    if (this.state.organizationProfile.mailingNoUnitNumber !== true) {
      let mailingUnitNumbervalidation = [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace("{0}", "unit number & building name of mailing address"),
        },
      ];
      validationRule.mailingBuildingName = mailingUnitNumbervalidation
    }

    const returnValidState = validate(key, this.state.organizationProfile, validationRule, this.state.validStateProfile);
    this.setState({ validStateProfile: returnValidState });
  }

  isValidOrganisationProfile = () => {

    let validationRule = organizationProfileValRules
    if (this.state.organizationProfile.registeredNoUnitNumber !== true) {
      let registerUnitNumbervalidation = [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace("{0}", "unit number & building name of regeistered address"),
        },
      ];
      validationRule.registeredBuildingName = registerUnitNumbervalidation
    } else {
      validationRule.registeredBuildingName = []
    }

    if (this.state.organizationProfile.mailingNoUnitNumber !== true) {
      let mailingUnitNumbervalidation = [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace("{0}", "unit number & building name of mailing address"),
        },
      ];
      validationRule.mailingBuildingName = mailingUnitNumbervalidation
    } else {
      validationRule.mailingBuildingName = []
    }

    const returnValidState = isValidForm(this.state.organizationProfile, validationRule, this.state.validStateProfile);
    this.setState({ validStateProfile: returnValidState });
    return returnValidState.isValid;
  }

  isValidOrganisationBusinessDetails = () => {

    let validation = organizationBusinessDetailsValRules

    if (this.state.organizationBusinessDetails.getToKnowId && this.state.getToKnow.find(x => x.id === this.state.organizationBusinessDetails.getToKnowId).name.includes('Other')) {
      let getToKnowOthers = [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other"
          ),
        },
      ]
      validation.getToKnowOthers = getToKnowOthers
    } else {
      validation.getToKnowOthers = []
    }

    if (this.state.organizationBusinessDetails.associationBenefits.find(x => x.label.includes('Others'))) {
      let associationBenefitsOthers = [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other"
          ),
        },
      ]
      validation.associationBenefitsOthers = associationBenefitsOthers
    } else {
      validation.associationBenefitsOthers = []
    }

    const returnValidState = isValidForm(this.state.organizationBusinessDetails, validation, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  }

  validateCorporateBasic = (key, object) => {
    let validation = organizationBusinessDetailsValRules

    if (this.state.organizationBusinessDetails.getToKnowId && this.state.getToKnow.find(x => x.id === this.state.organizationBusinessDetails.getToKnowId).name.includes('Other')) {
      let getToKnowOthers = [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other"
          ),
        },
      ]
      validation.getToKnowOthers = getToKnowOthers
    } else {
      validation.getToKnowOthers = []
    }

    if (this.state.organizationBusinessDetails.associationBenefits.find(x => x.label.includes('Others'))) {
      let associationBenefitsOthers = [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other"
          ),
        },
      ]
      validation.associationBenefitsOthers = associationBenefitsOthers
    } else {
      validation.associationBenefitsOthers = []
    }

    const returnValidState = validate(key, this.state.organizationBusinessDetails, validation, this.state.validState);
    this.setState({ validState: returnValidState, });
  };

  validateOrganisationKeyRoles = (key) => {
    const returnValidState = validate(key, this.state.organisationDetails, organisationKeyRolesValRules, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
  }

  validateSearchByEmailField = (key) => {
    const returnValidState = validate(key, this.state.organisationDetails, searchByEmailValRule, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
    return returnValidState.isValid;
  }

  validateSearchByEmailFieldForEmployee = (key) => {
    const returnValidState = validate(key, this.state.employeeDetails, searchByEmailValRule, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState });
    return returnValidState.isValid;
  }

  isValidOrganisationKeyRoles = () => {
    const returnValidState = isValidForm(this.state.organisationDetails, organisationKeyRolesValRules, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
    return returnValidState.isValid;
  }

  validateEmployeeInfo = (key, object) => {
    const returnValidState = validate(key, this.state.employeeDetails, organizationEmployeesValRules, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState, });
  };

  isValidateAllEmployeeInfo = () => {
    const returnValidState = isValidForm(this.state.employeeDetails, organizationEmployeesValRules, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState });
    return returnValidState.isValid;
  };

  validateFinanceCard = (key) => {
    const returnValidState = validate(key, this.state.corporateFinance, financeCardValRules, this.state.validStateFinanceCard);
    this.setState({ validStateFinanceCard: returnValidState, });
  }

  isValidFinanceCard = () => {
    const returnValidState = isValidForm(this.state.corporateFinance, financeCardValRules, this.state.validStateFinanceCard);
    this.setState({ validStateFinanceCard: returnValidState });
    return returnValidState.isValid;
  };

  addEditSIISCorporateAccountInformation = () => {
    var request = this.state.siisAccountInformation;
    request.memberId = this.state.memberId;
    request.accountStatus = request.accountStatus;
    request.createdBy = request.createdBy === 0 ? 1 : request.createdBy;
    request.createdAppId = 115;
    // if (this.isValidSiisAccountInfo()) {
    this.setState({ isCorporateAccountLoading: true });
    this.corporateMemberServices.addEditSIISCorporateAccountInformation(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.errorMessage === '') {
          this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Corporate SIIS Information"));
          this.setState({ memberId: response.responseItem.responseContent.keyId },
            () => this.getSIISCorporateAccountInformationById(response.responseItem.responseContent.keyId));
          this.setState({ isCorporateAccountLoading: false })

        }
        else {
          this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Corporate SIIS Information"));
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isCorporateAccountLoading: false });
    })
    // }
  }

  renewCorporateMember = () => {
    let request = {};
    let details = { ...this.state.membershipSubscription }
    request.memberId = this.state.memberId;
    request.membershipTypeId = details.membershipTypeId;
    request.revenueTypeId = details.membershipRevenueId;
    if (details.isAligibleForNewApplication === true) {
      request.transactionTypeId = 1;
      request.isAppliedForRenewal = 0
    } else {
      request.transactionTypeId = 2;
      request.isAppliedForRenewal = 1
    }
    request.membershipTenureId = 0;
    request.incorporationDate = details.incorporationDate;
    request.numberOfChapters = 0;
    this.setState({ isLoadingForRenewal: true });
    this.corporateMemberServices.renewCorporateMember(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null && response.responseItem.responseContent.errorMessage === "") {
          this.swalServices.Success("Applied for renewal successfully!");
        } else if (response.responseItem.responseContent != null && response.responseItem.responseContent.errorMessage === "Task Exists") {
          this.swalServices.Error("Account details already exists in membership tasklist");
        }
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoadingForRenewal: false });
    });
  }

  addEditOrganisationBasicInformation = () => {

    if (this.state.memberId) {
      var request = this.state.organizationBasicInformation;
      request.memberId = this.state.memberId;
      request.RepresentativeFirstName = this.state.organizationBasicInformation.firstName;
      request.RepresentativeLastName = this.state.organizationBasicInformation.lastName;
      request.businessContactNumber = this.state.organizationBasicInformation.contactNo;
      request.businessEmail = this.state.organizationBasicInformation.email;
      request.companyDomain = "";
      request.createdBy = 1;
      request.createdAppId = 115;
      request.designation = this.state.organizationBasicInformation.designation;

      // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
      //   var arr1 = request.selectedDesignation.map(function (item) {
      //     delete item.bad;
      //     return item.label;
      //   });
      //   request.designation = arr1.join([',']);
      // } else {
      //   request.designation = "";
      // }

      // if (this.isValidOrgBasicInfo()) {
      this.setState({ isOrganizationBasicInfoLoading: true });
      this.corporateMemberServices.addEditOrganisationBasicInformation(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Organisation basic information"));
            this.getOrganisationBasicInformationById();
            this.getOrganisationRoleList(this.state.memberId);
            this.setState({ isOrganizationBasicInfoLoading: false });
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Organisation basic information"));
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isOrganizationBasicInfoLoading: false });
      })
      // }
    } else {
      this.swalServices.Warning("Please enter SLA corporate account information.");
    }
  }

  addEditOrganisationProfile = () => {
    if (this.state.memberId) {
      var request = this.state.organizationProfile;
      request.memberId = this.state.memberId;
      request.logo = this.state.organizationProfile.orgLogoFileName ? this.state.organizationProfile.orgLogoFileName : "";
      request.employmentSize = this.state.organizationProfile.staffStrength ? this.state.organizationProfile.staffStrength : 0;
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;
      request.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;

      if (this.state.organizationProfile.registeredNoUnitNumber === true) {
        request.registeredBuildingName = ""
      }
      if (this.state.organizationProfile.mailingNoUnitNumber === true) {
        request.mailingBuildingName = ""
      }
      if (this.state.organizationProfile.organisationWebsite === null) {
        request.organisationWebsite = ""
      }

      //if (this.isValidOrganisationProfile()) {
      this.setState({ isOrganizationProfileLoading: true });
      this.corporateMemberServices.addEditOrganisationProfile(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            var res = this.state.organizationProfile;
            res.organisationProfileId = response.responseItem.responseContent.keyId;
            res.updatedAt = new Date();
            res.updatedBy = 1;
            this.setState({ organizationProfile: res }, () => {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Organisation profile"));
              this.getSIISCorporateAccountInformationById(this.state.memberId);
              this.setState({ isOrganizationProfileLoading: false });
            }
            );
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Organisation profile"));
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isOrganizationProfileLoading: false });
      })
      //}

    } else {
      this.swalServices.Warning("Please enter SLA corporate account information.");
    }
  }
  addEditOrganisationBussinessDetails = () => {
    if (this.state.memberId) {
      var request = this.state.organizationBusinessDetails;
      request.organisationBusinessDetailId = this.state.organizationBusinessDetails.organisationBusinessDetailId;

      if (request.typesOfServices.length > 0) {
        var arr1 = request.typesOfServices.map(function (item) { delete item.bad; return item.value; });
        request.servicesTypeId = arr1.join([","]);
      } else {
        request.servicesTypeId = "";
      }

      if (request.typesOfIndustriesServed.length > 0) {
        var arr1 = request.typesOfIndustriesServed.map(function (item) { delete item.bad; return item.value; });
        request.industriesServedId = arr1.join([","]);
      } else {
        request.industriesServedId = "";
      }

      if (request.countriesPresence.length > 0) {
        var arr1 = request.countriesPresence.map(function (item) { delete item.bad; return item.value; });
        request.countriesPresenceId = arr1.join([","]);
      } else {
        request.countriesPresenceId = "";
      }

      if (request.associationBenefits.length > 0) {
        var arr1 = request.associationBenefits.map(function (item) { delete item.bad; return item.value; });
        request.associationBenefitsId = arr1.join([","]);
      } else {
        request.associationBenefitsId = "";
      }

      request.memberId = this.state.memberId;
      // request.membershipTypeId = this.state.membershipSubscription.membershipTypeId
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;
      // if (this.isValidOrganisationBusinessDetails()) {
      this.setState({ isOtherInfoLoading: true });
      this.corporateMemberServices.addEditOrganisationBussinessDetails(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            var res = this.state.organizationBusinessDetails;
            res.organisationBusinessDetailId = response.responseItem.responseContent.keyId;
            res.updatedAt = new Date();
            res.updatedBy = 1;
            this.setState({ organizationBusinessDetails: res }, () => {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Organisation business details"));
              this.getOrganisationBussinessDetailsById();
            });
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Organisation business details"));
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isOtherInfoLoading: false });
      })
      // } else {
      //   this.swalServices.Warning("Please enter Organisation Business Details.");
      // }
    } else {
      this.swalServices.Warning("Please enter SLA corporate account information.");
    }
  }

  addEditCorporateMembershipSubscription = () => {
    if (this.state.memberId > 0 && this.state.memberId != null) {

      let request = this.state.membershipSubscription;
      request.corporateMemberSubscriptionId = this.state.membershipSubscription.corporateMemberSubscriptionId;
      request.membershipTypeId = this.state.membershipSubscription.membershipTypeId;
      request.billingCategoryId = this.state.membershipSubscription.billingCategoryId;
      request.subscriptionStartDate = this.state.membershipSubscription.subscriptionStartDate;
      request.subcriptionEndDate = this.state.membershipSubscription.subcriptionEndDate;
      request.memberId = this.state.memberId;
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;
      request.tacFromId = this.state.membershipSubscription.tacFromId;
      request.tacName = this.state.membershipSubscription.tacName;
      // if (this.isValidMemberSub()) {
      this.setState({ isMembershipSubscriptionLoading: true });
      this.corporateMemberServices.addEditCorporateMembershipSubscription(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            var res = this.state.membershipSubscription;
            res.updatedAt = new Date();
            res.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
            res.corporateMemberSubscriptionId = response.responseItem.responseContent.keyId;
            this.setState({ membershipSubscription: res }, () => { this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Corporate membership subscription")); });
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Corporate membership subscription"));
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isMembershipSubscriptionLoading: false });
      })
      // }
    } else {
      this.swalServices.Warning("Please enter SLA corporate account information.");
    }
  }

  addEditCorporateProspect = () => {
    if (this.state.memberId > 0 && this.state.memberId != null) {
      let request = this.state.prospectInformation;
      request.corporateProspectId = this.state.prospectInformation.corporateProspectId;
      request.prospectSource1 = this.state.prospectInformation.source1Id;
      request.prospectSource2 = this.state.prospectInformation.source2Id;
      request.prospectSource3 = this.state.prospectInformation.source3;
      request.pIC1 = this.state.prospectInformation.pic1Id;
      request.pIC2 = this.state.prospectInformation.pic2Id;
      request.firstEngagementDate = this.state.prospectInformation.firstEngagementDate;
      request.prospectStatusId = this.state.prospectInformation.prospectStatusId;
      request.memberId = this.state.memberId;
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;
      // if (this.isValidPropectMem()) {
      this.setState({ isProspectInformationLoading: true });
      this.corporateMemberServices.addEditCorporateProspect(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            var res = this.state.prospectInformation;
            res.corporateProspectId = response.responseItem.responseContent.keyId;
            res.updatedAt = new Date();
            res.updatedBy = 1;
            this.setState({ prospectInformation: res }, () => { this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Prospect information")); });
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Prospect information"));
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isProspectInformationLoading: false });
      })
      // }
    } else {
      this.swalServices.Warning("Please enter SLA corporate account information.");
    }
  }

  addEditEmployeeInformation = () => {
    if (this.state.memberId > 0 && this.state.memberId != null) {
      let request = this.state.employeeDetails;
      request.memberId = this.state.memberId;
      request.emailAddress = request.emailText;
      request.yearsId = request.yearsId ? request.yearsId : 0

      if (this.state.employeeDetails.emailSubscription.length > 0) {
        var arr1 = this.state.employeeDetails.emailSubscription.map(function (item) { delete item.bad; return item.value; });
        request.emailSubscriptionId = arr1.join([","]);
      }
      request.contactRoleId = request.contactRoleId ? request.contactRoleId : 0

      // if (this.isValidateAllEmployeeInfo()) {
      this.setState({ isAddEditProfileButtonLoader: true })
      this.corporateMemberServices.addEditEmployeeInformation(request).then((response) => {

        if (
          response.statusCode === 200 &&
          response.responseItem != null &&
          response.responseItem.responseContent.errorType === "EXISTS"
        ) {

          this.swalServices.Error(
            "Email has been assigned to an existing company. Please kindly contact SLA."
          );
          //this.setState({ addEditModel: false });
          this.setState({ addEditModelForEmployee: false });
        }
        else if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success(
            "Employee information inserted successfully."
          );
          this.getEmployeeList(this.state.memberId, "");
          this.getOrganisationRoleList(this.state.memberId);
          this.setState({ addEditModelForEmployee: false });
          //this.setState({ addEditModel: false });
        } else {
          this.swalServices.Error("Error");
        }
        this.setState({ isAddEditProfileButtonLoader: false })
      });
      // }
    } else {
      this.swalServices.Warning("Please enter SLA corporate account information.");
    }
  };

  resetEmployeeInfo = (email = '') => {
    let reset = this.state.employeeDetails;
    // var domainId = this.state.emailDomain.find(
    //   (domain) => domain.id === this.state.memberId
    // ).id;
    reset.mrMrsId = 0;
    reset.contactInfoId = 0;
    reset.lastName = "";
    reset.firstName = "";
    reset.emailText = email;
    reset.title = "";
    reset.designation = "";
    reset.bussinessMobile = "";
    reset.officeDID = "";
    reset.yearsId = "";
    //reset.emailDomainId = domainId;
    //reset.selectedOrganisationRole = [];
    reset.searchByEmail = "";
    reset.contactRoleId = 0;

    let res = this.state.validStateEmp;
    res.isValid = true;
    res.error = {};

    this.setState({ employeeDetails: reset, validStateEmp: res });
  }

  resetOrganisationRoleInfo = (email = '') => {
    let reset = this.state.organisationDetails;
    // var domainId = this.state.emailDomain.find(
    //   (domain) => domain.id === this.state.memberId
    // ).id;
    reset.organisationRoleId = 0;
    reset.mrMrsId = "";
    reset.firstName = "";
    reset.lastName = "";
    reset.emailAddress = "";
    reset.bussinessMobile = "";
    reset.designation = "";
    reset.organisationRole = "";
    reset.contactInfoId = 0;
    reset.emailString = "";
    reset.officeDID = "";
    reset.yearsId = 0;
    this.setState({ individualOrganisationRole: "" })
    this.setState({ organisationDetails: reset }, () =>
      this.getEmployeeList(this.state.memberId, "")
    );
  }

  addEditOraganisation = () => {
    if (this.state.memberId > 0 && this.state.memberId != null) {
      // if (this.isValidOrganisationKeyRoles()) {
      let request = this.state.organisationDetails;
      request.emailString = request.emailText + "@abc.com";
      request.memberId = this.state.memberId;
      request.emailAddress = request.emailText;
      request.yearsId = request.yearsId ? request.yearsId : 0
      //request.experienceYears = request.experienceYears ? request.experienceYears : 0

      if (this.state.organisationDetails.emailSubscription != null && this.state.organisationDetails.emailSubscription.length > 0) {
        var arr1 = this.state.organisationDetails.emailSubscription.map(function (item) { delete item.bad; return item.value; });
        request.emailSubscriptionId = arr1.join([","]);
      } else {
        request.emailSubscriptionId = 0
      }
      request.isCreateNewOrgRole = this.state.isCreateNewOrgRole
      request.IsEmployeeModalEditable = this.state.isOrganisationModalEditable

      this.setState({ isAddEditAOrganisation: true });
      this.corporateMemberServices.addEditOrganisation(request).then((response) => {
        if (
          response.statusCode === 200 &&
          response.responseItem != null &&
          response.responseItem.responseContent.errorType === "EXISTS"
        ) {
          this.setState({ addEditOrganisationModel: false });
          this.swalServices.Error(
            "Member already exists with same email address."
          );
        } else if (
          response.statusCode === 200 &&
          response.responseItem != null &&
          response.responseItem.responseContent.errorType === "ROLE EXISTS"
        ) {
          this.setState({ addEditOrganisationModel: false });
          this.swalServices.Error(
            "Organisation Role Already Exists For this Member."
          );
        }
        else if (
          response.statusCode === 200 &&
          response.responseItem != null
        ) {
          this.swalServices.Success(
            "Employee information inserted successfully."
          );
          this.setState({ addEditOrganisationModel: false });
          this.getOrganisationRoleList(this.state.memberId);
          this.getEmployeeList(this.state.memberId, "");
          let reset = this.state.organisationDetails;
          reset.organisationRoleId = 0;
          reset.mrMrsId = "";
          reset.firstName = "";
          reset.lastName = "";
          reset.emailAddress = "";
          reset.bussinessMobile = "";
          reset.designation = "";
          reset.organisationRole = "";
          reset.officeDID = "";
          this.setState({ individualOrganisationRole: null });
          //reset.selectedDesignation = [];
          //reset.selectedOrganisationRole = [];
          reset.contactInfoId = 0;
          reset.emailString = "";
          this.setState({ organisationDetails: reset }, () =>
            this.getEmployeeList(this.state.memberId, "")
          );
        } else {
          this.swalServices.Error("Error");
        }
      });
      // }
    } else {
      this.swalServices.Warning("Please enter SLA corporate account information.");
    }
  };

  addEditEngagementTracking = () => {
    let request = this.state.engagementTrackingDetails;
    request.corporateEngagementDate = request.engagementDate;
    request.memberId = this.state.memberId;
    request.corporateEngagementDescription = request.engagementDescription;
    request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
    if (this.state.memberId) {
      // if (this.isValidengagement()) {
      this.setState({ isEngagementTrackingLoading: true });
      this.corporateMemberServices.addEditEngagementTracking(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.getEngagementTrackingList(this.state.memberId);
          let details = this.state.engagementTrackingDetails;

          details.corporateEngagementTrackingId = 0;
          details.corporateEngagementTypeId = 0;
          details.corporateEngagementDate = "";
          details.engagementDate = "";
          details.engagementDescription = "";
          details.corporateEngagementDescription = "";
          details.corporateEngagementRecordedById = 0;
          details.corporateEngagementFollowUpById = 0;
          details.memberId = 0;
          details.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;

          this.swalServices.Success("Engagement inserted successfully.");
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isEngagementTrackingLoading: false });
      });
      // }
    } else {
      this.swalServices.Warning("Please enter SLA corporate account information.");
    }
  };

  getSIISCorporateAccountInformationById = (memberId) => {
    if (memberId > 0) {
      this.corporateMemberServices.getSIISCorporateAccountInformationById(memberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let res = { ...this.state.siisAccountInformation };
          res.memberId = response.responseItem.responseContent.memberId;
          res.siisAccountId = response.responseItem.responseContent.siisAccountId;
          res.engagementScore = response.responseItem.responseContent.engagementScore;
          res.accountStatus = response.responseItem.responseContent.accountStatus;
          res.corporatejoinedDate = response.responseItem.responseContent.corporateJoinedDate;
          res.corporateReasonLeavingId = response.responseItem.responseContent.corporateReasonLeavingId;
          res.renewalNotes = response.responseItem.responseContent.renewalNotes;
          res.updatedAt = response.responseItem.responseContent.updatedDate;
          res.updatedBy = response.responseItem.responseContent.updatedBy;
          res.internalFlagId = response.responseItem.responseContent.internalFlagId;
          res.companyDomain = response.responseItem.responseContent.companyDomain;
          res.isFeatured = response.responseItem.responseContent.isFeatured;
          res.projectName = response.responseItem.responseContent.projectName;
          res.isGoGlobal = response.responseItem.responseContent.isGoGlobal;
          res.companyUEN = response.responseItem.responseContent.companyUEN;
          res.isAppliedForReinstatement = response.responseItem.responseContent.isAppliedForReinstatement;
          this.setState({ siisAccountInformation: res, isSelectedGoGlobal: response.responseItem.responseContent.isGoGlobal },
            () => {
              var chapInfo = this.state.chapterInformation

              chapInfo.availableChapters = response.responseItem.responseContent.availableChapters ? response.responseItem.responseContent.availableChapters : 0;
              chapInfo.joinedChapters = response.responseItem.responseContent.joinedChapters ? response.responseItem.responseContent.joinedChapters : 0;
              chapInfo.purchasedChapters = response.responseItem.responseContent.purchasedChapters ? response.responseItem.responseContent.purchasedChapters : 0;
              chapInfo.updatedBy = response.responseItem.responseContent.chapInformationUpdatedBy;
              chapInfo.updatedAt = response.responseItem.responseContent.chapInformationUpdatedDate;
              this.setState({ chapterInformation: chapInfo });

              var finance = this.state.corporateFinance;
              finance.creditTermsId = response.responseItem.responseContent.creditTermsId !== null ? response.responseItem.responseContent.creditTermsId : 1;
              finance.quotationNeeded = response.responseItem.responseContent.quotationNeeded;
              finance.sendInvoiceReminder = response.responseItem.responseContent.sendInvoiceReminder;

              this.setState({ corporateFinance: finance })

              var gov = this.state.governmentProgramme;
              gov.projectsInvolved = response.responseItem.responseContent.projectsInvolved;
              gov.stratigicMember = response.responseItem.responseContent.stratigicMember;
              gov.projectName = response.responseItem.responseContent.projectName;

              this.setState({ governmentProgramme: gov }, () => {
                this.getOrganisationRoleList(this.state.memberId);
                // this.getOrganisationBasicInformationById(this.state.memberId)
              })
            });
        }
        else {
          this.getOrganisationRoleList(this.state.memberId);
          // this.getOrganisationBasicInformationById(this.state.memberId)


          this.swalServices.Error(response.message);
        }
        this.setState({ isOrganizationProfileLoading: false });
      })
    }
  }

  getOrganisationBasicInformationById = (memberId) => {
    if (this.state.memberId > 0 && this.state.memberId != null) {
      this.corporateMemberServices.getOrganisationBasicInformationById(this.state.memberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.organizationBasicInformation };
            res.organisationInformationId = response.responseItem.responseContent.organisationInformationId;
            res.companyUEN = response.responseItem.responseContent.companyUEN;
            res.companyName = response.responseItem.responseContent.companyName;
            res.incorporationDate = response.responseItem.responseContent.incorporationDate;
            res.firstName = response.responseItem.responseContent.representativeFirstName;
            res.lastName = response.responseItem.responseContent.representativeLastName;
            res.email = response.responseItem.responseContent.businessEmail;
            res.contactNo = response.responseItem.responseContent.businessContactNumber;
            res.mrMrsId = response.responseItem.responseContent.mrMrsId;
            res.designation = response.responseItem.responseContent.designation;

            // if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
            //   var array = response.responseItem.responseContent.designation.split(',');
            //   var designation = [];
            //   array.forEach(topic => {
            //     let found = this.state.contactRoles.find(deg => deg.label === topic);
            //     if (found) {
            //       designation.push(found);
            //     }
            //   });
            //   res.selectedDesignation = designation;
            // }

            this.setState({ organizationBasicInformation: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isOrganizationBasicInfoLoading: false });
      })
    }
  }

  saveMainImage = (file, keyId, imageIdentity) => {
    //file.storagePath = imageIdentity;
    this.corporateMemberServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        let request = this.state.imageDetails;
        request.memberId = this.state.organizationProfile.memberId;
        request.profileImagePath = response.fileName;
        request.isCorporate = true;

        this.corporateMemberServices
          .saveImageToDatabase(request)
          .then((response) => {
            if (response != null) {
              this.swalServices.Success("Image Uploaded");
            }
          });
        let detail = this.state.organizationProfile;
        if (file.storagePath === "orgLogoFileName") {
          detail["orgLogoFileName"] = response.fileName;
          this.setState({ orgLogoFileName: { ...detail } });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  getOrganisationProfileById = (memberId) => {
    if (this.state.memberId > 0 && this.state.memberId != null) {

      this.corporateMemberServices.getOrganisationProfileById(this.state.memberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let res = { ...this.state.organizationProfile };
          res.organisationProfileId = response.responseItem.responseContent.organisationProfileId;
          res.paidUpCapital = response.responseItem.responseContent.paidUpCapital;
          res.orgGrossTurnoverId = response.responseItem.responseContent.membershipRevenueId;
          res.staffStrength = response.responseItem.responseContent.employmentSize;
          res.countryId = response.responseItem.responseContent.countryId;
          res.generalBusinessEmail = response.responseItem.responseContent.generalEmailAddress;
          res.generalBusinessTelephone = response.responseItem.responseContent.generalBusinessTelephone;
          res.organisationWebsite = response.responseItem.responseContent.organisationWebsite;
          res.acraFilePath = response.responseItem.responseContent.acraFilePath;
          res.orgLogoFileName = response.responseItem.responseContent.logo;
          res.updatedAt = response.responseItem.responseContent.lastUpdatedDate;
          res.updatedBy = response.responseItem.responseContent.updatedBy;

          res.registeredStreetName = response.responseItem.responseContent.registeredStreetName;
          res.registeredNoUnitNumber = response.responseItem.responseContent.registeredNoUnitNumber;
          res.registeredBuildingName = response.responseItem.responseContent.registeredBuildingName;
          res.registeredPostalCode = response.responseItem.responseContent.registeredPostalCode;

          res.mailingStreetName = response.responseItem.responseContent.mailingStreetName;
          res.mailingBuildingName = response.responseItem.responseContent.mailingBuildingName;
          res.mailingNoUnitNumber = response.responseItem.responseContent.mailingNoUnitNumber;
          res.mailingPostalCode = response.responseItem.responseContent.mailingPostalCode;
          res.isMTORegistry = response.responseItem.responseContent.isMTORegistry;

          this.setState({ organizationProfile: res });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getOrganisationBussinessDetailsById = (memberId) => {
    if (this.state.memberId > 0 && this.state.memberId != null) {

      this.corporateMemberServices.getOrganisationBussinessDetailsById(this.state.memberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.organizationBusinessDetails };
            res.organisationBusinessDetailId = response.responseItem.responseContent.organisationBusinessDetailId;

            let ids = response.responseItem.responseContent.associationBenefitsId
            var array = (ids && ids !== '0') ? ids.split(',') : [];
            res.associationBenefits = [];
            array.forEach(topic => {
              res.associationBenefits.push(this.state.associationBenefits.find(topics => topics.value === Number(topic)));
            });

            let ids1 = response.responseItem.responseContent.countriesPresenceId
            var array = (ids1 && ids1 !== '0') ? ids1.split(',') : [];
            res.countriesPresence = [];
            array.forEach(topic => {
              res.countriesPresence.push(this.state.countriesWithPresence.find(topics => topics.value === Number(topic)));
            });

            let ids2 = response.responseItem.responseContent.industriesServedId
            var array = (ids2 && ids2 !== '0') ? ids2.split(',') : [];
            res.typesOfIndustriesServed = [];
            array.forEach(topic => {
              res.typesOfIndustriesServed.push(this.state.industriesServed.find(topics => topics.value === Number(topic)));
            });

            let ids3 = response.responseItem.responseContent.servicesTypeId
            var array = (ids3 && ids3 !== '0') ? ids3.split(',') : [];
            res.typesOfServices = [];
            array.forEach(topic => {
              res.typesOfServices.push(this.state.serviceType.find(topics => topics.value === Number(topic)));
            });

            res.getToKnowId = response.responseItem.responseContent.getToKnowId;
            res.others = response.responseItem.responseContent.others;
            res.getToKnowOthers = response.responseItem.responseContent.getToKnowOthers;
            res.associationBenefitsOthers = response.responseItem.responseContent.associationBenefitsOthers;

            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.lastUpdatedDate;


            this.setState({ organizationBusinessDetails: res });
          } else {
            this.swalServices.Error(response.message);
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getCorporateMembershipSubscriptionById = () => {
    if (this.state.memberId > 0 && this.state.memberId != null) {

      this.corporateMemberServices.getCorporateMembershipSubscriptionById(this.state.memberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.membershipSubscription };
            res.corporateMemberSubscriptionId = response.responseItem.responseContent.corporateMemberSubscriptionId;
            res.membershipTypeId = response.responseItem.responseContent.membershipTypeId;
            res.billingCategoryId = response.responseItem.responseContent.billingCategoryId;
            res.subscriptionStartDate = response.responseItem.responseContent.subscriptionStartDate;
            res.subcriptionEndDate = response.responseItem.responseContent.subcriptionEndDate;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            res.membershipRevenueId = response.responseItem.responseContent.membershipRevenueId;
            res.incorporationDate = response.responseItem.responseContent.incorporationDate;
            res.commencementDate = response.responseItem.responseContent.commencementDate;
            res.tacFromId = response.responseItem.responseContent.tacFromId;
            res.tacName = response.responseItem.responseContent.tacName;
            res.isAligibleForNewApplication = response.responseItem.responseContent.isAligibleForNewApplication;
            res.approvalDate = response.responseItem.responseContent.approvalDate !== null ? response.responseItem.responseContent.approvalDate : null;


            this.setState({ membershipSubscription: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getProspectInformationById = () => {
    if (this.state.memberId > 0 && this.state.memberId != null) {
      this.corporateMemberServices.getProspectInformationById(this.state.memberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.prospectInformation };
            res.corporateProspectId = response.responseItem.responseContent.corporateProspectId;
            res.source1Id = response.responseItem.responseContent.prospectSource1;
            res.source2Id = response.responseItem.responseContent.prospectSource2;
            res.source3 = response.responseItem.responseContent.prospectSource3;
            res.pic1Id = response.responseItem.responseContent.piC1;
            res.pic2Id = response.responseItem.responseContent.piC2;
            res.firstEngagementDate = response.responseItem.responseContent.firstEngagementDate;
            res.prospectStatusId = response.responseItem.responseContent.prospectStatusId;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            res.comments = response.responseItem.responseContent.comments;
            res.getToKnow = response.responseItem.responseContent.getToKnow;
            if (response.responseItem.responseContent.getToKnow !== '' && response.responseItem.responseContent.getToKnow !== '0') {
              var array = response.responseItem.responseContent.getToKnow.split(',');
              const options = [];
              array.forEach(topic => {
                options.push(this.state.getToKnowUsArr.find(topics => topics.value === Number(topic)));
              });
              res.getToKnow = options;
            }
            res.interestedChaptersId = response.responseItem.responseContent.interestedChaptersId;

            var selectedInterestChapterArr = (res.interestedChaptersId && res.interestedChaptersId !== '') ? res.interestedChaptersId.split(',') : [];
            res.selectedInterestChapterArr = [];
            selectedInterestChapterArr.forEach(topic => {
              res.selectedInterestChapterArr.push(this.state.interestedChapter.find(topics => topics.value === Number(topic)));
            });

            this.setState({ prospectInformation: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ CorporateProspect: false });
      })
    }
  }

  getEmployeeInfoById = (id) => {
    this.corporateMemberServices.getEmployeeInfoById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.emailSubscriptionId !== null && response.responseItem.responseContent.emailSubscriptionId !== "") {
          var array = response.responseItem.responseContent.emailSubscriptionId.split(',');
          var emailSubscription = [];
          array.forEach(topic => {
            emailSubscription.push(this.state.emailSubscription.find(deg => deg.value === Number(topic)));
          });
        }
        // if (response.responseItem.responseContent.organisationRole !== null && response.responseItem.responseContent.organisationRole !== "") {
        //   var array2 = response.responseItem.responseContent.organisationRole.split(',');
        //   var organisationRole = [];
        //   array2.forEach(topic => { organisationRole.push(this.state.contactRoles.find(deg => deg.label === topic)); });
        // }

        this.setState({ employeeDetails: response.responseItem.responseContent, isEmployeeModalEditable: false, }, () => {
          let data = { ...this.state.employeeDetails };
          //data.designation = designation;
          //data.selectedOrganisationRole = organisationRole;
          data.emailSubscription = emailSubscription;
          this.setState({ employeeDetails: data });
        });

        let details = response.responseItem.responseContent;
        details.emailText = details.emailAddress;
        details.emailDomainId = details.memberId;
        this.setState({ organisationRoleDetail: details });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  getCorporateOrganisationRoleById = (id) => {
    if (id > 0) {

      this.corporateMemberServices.getCorporateOrganisationRoleById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.emailSubscriptionId !== null && response.responseItem.responseContent.emailSubscriptionId !== "") {
            var array = response.responseItem.responseContent.emailSubscriptionId.split(',');
            var emailSubscription = [];
            array.forEach(topic => {
              emailSubscription.push(this.state.emailSubscription.find(deg => deg.value === Number(topic)));
            });
          }
          response.responseItem.responseContent.searchByEmail = '';
          this.setState({ organisationDetails: response.responseItem.responseContent, isCreateNewOrgRole: false, isOrganisationModalEditable: false },
            () => {
              let data = { ...this.state.organisationDetails };
              //data.designation = designation;
              //data.selectedOrganisationRole = organisationRole;
              data.emailSubscription = emailSubscription;
              this.setState({ organisationDetails: data });
            }
          );
          let details = response.responseItem.responseContent;
          details.emailText = details.emailAddress;
          details.emailDomainId = details.memberId;
          response.responseItem.responseContent.searchByEmail = '';

          this.setState({ organisationDetails: details });
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  searchIndividualMemberByEmailForOrgRole = (identity) => {
    if (this.state.memberId > 0 && ((identity === "Organisarion Key Roles" && this.validateSearchByEmailField("searchByEmail")) || (identity === "Organisarion Employees" && this.validateSearchByEmailFieldForEmployee("searchByEmail")))) {
      let req = {
        email: '',
        memberId: 0,
      }
      if (identity === "Organisarion Key Roles") {
        req.email = this.state.organisationDetails.searchByEmail;
      }
      if (identity === "Organisarion Employees") {
        req.email = this.state.employeeDetails.searchByEmail;
      }
      req.memberId = this.state.memberId;
      this.corporateMemberServices.searchIndividualMemberByEmailForOrgRole(req).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError === '0') {
          let isEditable = false;
          if (response.responseItem.responseContent.mrMrsId > 0) {
            response.responseItem.responseContent.mrMrs = response.responseItem.responseContent.mrMrsId;
          } else {
            isEditable = true
          }
          // if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
          //   var array = response.responseItem.responseContent.designation.split(',');
          //   var designation = [];
          //   array.forEach(topic => {
          //     designation.push(this.state.designationList.find(deg => deg.label === topic));
          //   });
          // } else {
          //   isEditable = true
          // }
          // if (response.responseItem.responseContent.organisationRole !== null && response.responseItem.responseContent.organisationRole !== "") {
          //   var array2 = response.responseItem.responseContent.organisationRole.split(',');
          //   var organisationRole = [];
          //   array2.forEach(topic => {
          //     organisationRole.push(this.state.organizationRoleArray.find(deg => deg.value === Number(topic)));
          //   });
          // } else {
          //   isEditable = true
          // }

          if (response.responseItem.responseContent.emailSubscriptionId !== null && response.responseItem.responseContent.emailSubscriptionId !== "") {
            var array = response.responseItem.responseContent.emailSubscriptionId.split(',');
            var emailSubscription = [];
            array.forEach(topic => {
              emailSubscription.push(this.state.emailSubscription.find(deg => deg.value === Number(topic)));
            });
          }

          if (identity === "Organisarion Key Roles") {
            let organisationDetailsObj = { ...this.state.organisationDetails };
            response.responseItem.responseContent.searchByEmail = organisationDetailsObj.searchByEmail;
            this.setState(
              { organisationDetails: response.responseItem.responseContent },
              () => {
                let data = { ...this.state.organisationDetails };
                //data.designation = designation;
                //data.selectedOrganisationRole = organisationRole;
                data.emailSubscription = emailSubscription;
                this.setState({ organisationDetails: data });
              }
            );
            this.resetEmployeeInfo()
            let details = response.responseItem.responseContent;
            details.emailText = req.email;
            details.emailDomainId = details.memberId;
            this.setState({ organisationDetails: details, isOrganisationModalEditable: isEditable });
          }
          if (identity === "Organisarion Employees") {
            let employeeDetailsObj = { ...this.state.employeeDetails };
            response.responseItem.responseContent.searchByEmail = employeeDetailsObj.searchByEmail;
            this.setState(
              { employeeDetails: response.responseItem.responseContent },
              () => {
                let data = { ...this.state.employeeDetails };
                //data.designation = designation;
                //data.selectedOrganisationRole = organisationRole;
                data.emailSubscription = emailSubscription;
                this.setState({ employeeDetails: data });
              }
            );
            let details = response.responseItem.responseContent;
            details.emailText = req.email;
            details.emailDomainId = details.memberId;
            this.setState({ employeeDetails: details, isEmployeeModalEditable: isEditable });
          }

        }
        else if (response.responseItem.responseContent.hasError === '1') {
          this.setState({ isCreateNewOrgRole: true, isOrganisationModalEditable: true, isEmployeeModalEditable: true, isCreateNewOrgRoleForEmployee: true }, () => {
            if (identity === "Organisarion Key Roles") {
              this.handleReset(req.email);
            }
            if (identity === "Organisarion Employees") {
              this.resetEmployeeInfo(req.email)
            }
            this.swalServices.Alert('No records found');
          });
        }
        else if (response.responseItem.responseContent.hasError === '2') {
          this.setState({ isCreateNewOrgRole: true, isOrganisationModalEditable: false, isEmployeeModalEditable: false, isCreateNewOrgRoleForEmployee: true }, () => {
            if (identity === "Organisarion Key Roles") {
              this.handleReset("");
            }
            if (identity === "Organisarion Employees") {
              this.resetEmployeeInfo("")
            }
            this.swalServices.Alert(response.responseItem.responseContent.error);
          });
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      this.swalServices.Alert('Please enter valid email address');
    }
  };

  getEngagementTrackingById = (id) => {
    this.corporateMemberServices.getEngagmentDetailsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          engagementTrackingDetails: response.responseItem.responseContent,
        });
      } else {
        //this.swalServices.Error(response.message);
      }
    });
  };

  getEngagementTrackingList = (id) => {
    if (id > 0 && id != null) {
      this.corporateMemberServices.getEngagementTrackingList(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.length > 0) {
            var updatedDate = new Date(Math.max(...response.responseItem.responseContent.map(e => new Date(e.lastUpdatedDate))));
            //const found = response.responseItem.responseContent.find(element => new Date(element.lastUpdatedDate) === updatedDate);
            var engagement = this.state.engagementTrackingDetails;
            engagement.lastUpdatedDate = updatedDate;
            engagement.updatedAt = updatedDate;
            engagement.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
            this.setState({
              engagemmentTrackingList: response.responseItem.responseContent,
            }, () => this.setState({ engagementTrackingDetails: engagement }));
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  deleteOrganisationRole = (memberId, id, role) => {
    if (id > 0 && id != null) {
      let request = this.state.organisationDetails;
      request.memberId = memberId
      request.contactInfoId = id
      request.contactRoleId = role
      request.deletedBy = 1;
      this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Organisation Role"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Organisation Role"),
        CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
          if (response) {
            this.corporateMemberServices.deleteCorporateOrganisationRole(request).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
                this.swalServices.Success(
                  "Organisation role deleted successfully."
                );
                let reset = this.state.organisationDetails;
                reset.contactInfoId = 0;
                reset.mrMrsId = "";
                reset.firstName = "";
                reset.lastName = "";
                reset.emailAddress = "";
                reset.bussinessMobile = "";
                reset.designation = "";
                reset.contactRoleId = "";
                reset.emailString = "";
                reset.contactInfoId = 0;
                //reset.selectedDesignation = [];
                //reset.selectedOrganisationRole = [];
                this.setState({ organisationDetails: reset });
                this.getOrganisationRoleList(this.state.memberId);
                this.setState({ addEditOrganisationModel: false })
              }
              else if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "OrganisationRoleId") {
                if (response.responseItem.responseContent.errorMessage === "2") {
                  this.swalServices.Error('Last official representative cannot be deleted.');
                }
                else if (response.responseItem.responseContent.errorMessage === "3") {
                  this.swalServices.Error('Last finance/billing rep cannot be deleted.');
                }
              }
              else {
                this.swalServices.Error(response.message);
              }
            });
          }
        });
    }
  };

  // saveMainImage = (file, keyId, imageIdentity) => {
  //   this.setState({ isLoaderEnabled: true });
  //   file.storagePath = "CorporateMembers";
  //   this.commonServices.storeBase64Image(file).then((response) => {
  //     if (response != null) {
  //       this.swalServices.Success("File Uploaded");
  //       if (file.storagePath === "CorporateMembers") {
  //         let detail = this.state.organizationProfile;
  //         detail["logoImage"] = response.fileName;
  //         this.setState({ logoImage: { ...detail } }, () => this.setState({ isLoaderEnabled: false }));
  //       }
  //     }
  //     else {
  //       this.swalServices.Error(response.message);
  //     }
  //   })
  // }

  onEditEmployee = (id) => {
    this.getEmployeeInfoById(id);
  };

  openSection = (section) => {
    this.setState({ openSection: this.state.openSection === section ? "" : section, });
    if (section === "SIISAccountInformation") {
      this.getSIISCorporateAccountInformationById(this.state.memberId);

    } else if (section === "OrganisationBasicInformation") {
      this.getOrganisationBasicInformationById();
    }
    else if (section === "OrganisationProfile") {
      this.getOrganisationProfileById();
    }
    else if (section === "OrganisationBusinessDetail") {
      this.getOrganisationBussinessDetailsById();
    }
    else if (section === "CorporateSubscription") {
      this.getCorporateMembershipSubscriptionById();
    }
    else if (section === "ProspectInformation") {
      this.getProspectInformationById();
    }
    else if (section === "EngagementTracking") {
      this.getEngagementTrackingList(this.state.memberId);
    } else if (section === "Employees") {
      this.getEmployeeList(this.state.memberId, "");
    } else if (section === "FinancialRecords") {
      this.getCorporateFinancialInfo(this.state.memberId);
    } else if (section === "Endorsers") {
      this.getEndorsersDetailById();
    }
  };

  editOrganisationRole = (value, e, role) => {
    this.getCorporateOrganisationRoleById(value);
    let found = this.state.contactRoles.find(deg => deg.name === role);
    if (found) {
      this.setState({ individualOrganisationRole: found.id });
    }
  };

  onEditEngagement = (id) => {
    this.getEngagementTrackingById(id);
  };

  // Start All validation Method 
  // Organisation Role Validation
  validateOrganisationRole = (key, object) => {
    const returnValidState = validate(key, this.state.organisationDetails, this.state.organisationRoleValidationRule, this.state.validStateOrg);
    this.setState({ validStateOrg: returnValidState, });
  };

  isValidateAllOrganisationRoles = () => {
    const returnValidState = isValidForm(this.state.organisationDetails, this.state.organisationRoleValidationRule, this.state.validStateOrg);
    this.setState({ validStateOrg: returnValidState });
    return returnValidState.isValid;
  };
  // Organisation Role Validation

  // Engagement tracking Validation
  engagementValidate = (key, object) => {
    const returnValidState = validate(key, this.state.engagementTrackingDetails, engagementTrackingValRules, this.state.validState);
    this.setState({ validState: returnValidState, });
  };

  isValidengagement = () => {
    const returnValidState = isValidForm(this.state.engagementTrackingDetails, engagementTrackingValRules, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  };
  //  End engagement tracking Validation

  // Chapter Information Validation
  chapterInfoValidate = (key, object) => {
    const returnValidState = validate(key, this.state.chapterInformation, chapterInformationValRules, this.state.validStateChapter);
    this.setState({ validStateChapter: returnValidState, });
  };

  isValidChapter = () => {
    const returnValidState = isValidForm(this.state.chapterInformation, chapterInformationValRules, this.state.validStateChapter);
    this.setState({ validStateChapter: returnValidState });
    return returnValidState.isValid;
  };

  // Corporate Membership Subscription Validation
  memberSubValidate = (key, object) => {
    const returnValidState = validate(key, this.state.membershipSubscription, memberSubscriptionValRules, this.state.validMemberSub);
    this.setState({ validMemberSub: returnValidState, });
  };

  isValidMemberSub = () => {
    let valRule = { ...memberSubscriptionValRules };
    if (this.state.membershipSubscription?.membershipTypeId > 0 && this.state.membershipSubscription?.membershipTypeId === 5) {
      let tacFromIdValRule = [
        {
          type: 'require',
          message: CommonValidationMessages.SelectRequired.replace('{0}', 'TAC from type'),
        },
      ];
      valRule.tacFromId = tacFromIdValRule;
      if (this.state.membershipSubscription.tacFromId > 0 && this.state.membershipSubscription.tacFromId === 1) {
        let tacNameValRule = [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'TAC name'),
          },
        ];
        valRule.tacName = tacNameValRule;
      }
    }
    const returnValidState = isValidForm(this.state.membershipSubscription, valRule, this.state.validMemberSub);
    this.setState({ validMemberSub: returnValidState });
    return returnValidState.isValid;
  };
  //End Corporate Membership Subscription validation

  // Propspect Member Validation 
  prospectMemValidate = (key, object) => {
    const returnValidState = validate(key, this.state.prospectInformation, prospectInformationValRules,
      this.state.validProspectMem);
    this.setState({ validProspectMem: returnValidState, });
  };

  isValidPropectMem = () => {
    const returnValidState = isValidForm(this.state.prospectInformation, prospectInformationValRules, this.state.validProspectMem);
    this.setState({ validProspectMem: returnValidState });
    return returnValidState.isValid;
  };
  //End Prospect Member validation

  // Siis account information Validation 
  siisAccountInfoValidate = (key, object) => {
    const returnValidState = validate(key, this.state.siisAccountInformation, siisAccountInformationValRules, this.state.validSiisAccountInfo);
    this.setState({ validSiisAccountInfo: returnValidState, });
  };

  isValidSiisAccountInfo = () => {
    const returnValidState = isValidForm(this.state.siisAccountInformation, siisAccountInformationValRules, this.state.validSiisAccountInfo);
    this.setState({ validSiisAccountInfo: returnValidState });
    return returnValidState.isValid;
  };
  //End siis account information validation

  // organisation basic information Validation 
  orgBasicInfoValidate = (key, object) => {
    const returnValidState = validate(
      key,
      this.state.organizationBasicInformation, organizationBasicInformationValRules, this.state.validOrgBasicInfo);
    this.setState({ validOrgBasicInfo: returnValidState, });
  };

  isValidOrgBasicInfo = () => {
    let valRules = { organizationBasicInformationValRules };
    // if (this.state.organizationBasicInformation.organisationInformationId > 0) {
    //   delete valRules.organizationBasicInformationValRules.designation;
    //   delete valRules.organizationBasicInformationValRules.email;
    //   delete valRules.organizationBasicInformationValRules.contactNo;
    // }
    const returnValidState = isValidForm(this.state.organizationBasicInformation, valRules.organizationBasicInformationValRules, this.state.validOrgBasicInfo);
    this.setState({ validOrgBasicInfo: returnValidState });
    return returnValidState.isValid;
  };

  getDomainByOrganisationId = (id) => {
    if (id > 0) {
      var domainId = this.state.emailDomain.find((domain) => domain.id === id);
      if (domainId) {
        let setDomain = this.state.employeeDetails;
        let setDomainOrg = this.state.organisationDetails;
        if (domainId.id && domainId.id > 0) {
          setDomain.emailDomainId = domainId.id;
          setDomainOrg.emailDomainId = domainId.id;
          this.setState({
            employeeDetails: setDomain,
            organisationDetails: setDomain,
          });
        }
      }
    }
  };

  handleReset = (email = '') => {
    let reset = {
      ...this.state.organisationDetails,
      mrMrs: 0,
      firstName: "",
      lastName: "",
      email: "",
      bussinessMobile: "",
      designation: "",
      contactRoleId: 0,
      memberId: 0,
      emailDomainId: 0,
      emailText: email,
      emailString: "",
      emailSubscription: 0,
      //selectedDesignation: [],
      //selectedOrganisationRole: [],
      searchByEmail: ''
    };
    let res = this.state.validStateRoles;
    res.isValid = true;
    res.error = {};
    this.setState({ validStateRoles: res });
    this.setState({ organisationDetails: reset });
  };

  setFilterParameters = (id, drpIdentity) => {
    let engagement = this.state.engagementTrackingDetails;
    let basicInfo = this.state.organizationBasicInformation;
    let siisInfo = this.state.siisAccountInformation;
    let chapter = this.state.chapterInformation;
    let orgProfile = this.state.organizationProfile;
    let bizDetails = this.state.organizationBusinessDetails;
    let subscription = this.state.membershipSubscription;
    let prospect = this.state.prospectInformation;
    let org = this.state.organisationDetails;
    let cancel_reason = this.state.cancellationReason;
    if (drpIdentity === "updatedById") {
      siisInfo["createdBy"] = id
    }

    if (drpIdentity === "corporateReasonLeaving") {
      siisInfo["corporateReasonLeavingId"] = id;
    }
    else if (drpIdentity === "corporateInternalFlags") {
      siisInfo["internalFlagId"] = id;
    }
    else if (drpIdentity === "Chapters") {
      chapter["workgroupMappingId"] = id;
      // this.chapterInfoValidate("workgroupMappingId")
    }
    else if (drpIdentity === "grossTurnOver") {
      orgProfile["orgGrossTurnoverId"] = id;
      //this.validateOrganisationProfile("orgGrossTurnoverId");
    }
    else if (drpIdentity === "orgCountry") {
      orgProfile["countryId"] = id;
      //this.validateOrganisationProfile("countryId");
    }
    else if (drpIdentity === "PaidUpCap") {
      bizDetails["paidUpCapital"] = id;
    }
    else if (drpIdentity === "StaffStrength") {
      bizDetails["staffStrength"] = id;
    }
    else if (drpIdentity === "MembershipTypesSubscription") {
      subscription["membershipTypeId"] = id;
      // this.memberSubValidate("membershipTypeId");
    }
    else if (drpIdentity === "TACFromTypeSubscription") {
      subscription["tacFromId"] = id;
      subscription["tacName"] = id > 1 && this.state.tacNames.find(x => x.id === id) ? this.state.tacNames?.find(x => x.id === id)?.name : '';
    }
    else if (drpIdentity === "ProspectSource1") {
      prospect["source1Id"] = id;
      // this.prospectMemValidate("source1Id");
    }
    else if (drpIdentity === "ProspectSource2") {
      prospect["source2Id"] = id;
      // this.prospectMemValidate("source2Id");
    }
    else if (drpIdentity === "ProspectPIC1") {
      prospect["pic1Id"] = id;
      // this.prospectMemValidate("pic1Id");
    }
    else if (drpIdentity === "ProspectPIC2") {
      prospect["pic2Id"] = id;
    }
    else if (drpIdentity === "ProspectStatus") {
      prospect["prospectStatusId"] = id;
    }
    else if (drpIdentity === "GetToKnow") {
      bizDetails["getToKnowId"] = id;
      //this.validateCorporateBasic("getToKnowId")
    }
    else if (drpIdentity === "EngagementType") {
      engagement["corporateEngagementTypeId"] = id;
      // this.engagementValidate("corporateEngagementTypeId");
    }
    else if (drpIdentity === "RecordedBy") {
      engagement["corporateEngagementRecordedById"] = id;
      // this.engagementValidate("corporateEngagementRecordedById");
    }
    else if (drpIdentity === "FollowUpBy") {
      engagement["corporateEngagementFollowUpById"] = id;
    }
    // else if (drpIdentity === "Org_MrMrs") {
    //   org["mrMrs"] = id;
    //   // this.validateOrganisationKeyRoles("mrMrs");
    // }
    else if (drpIdentity === "Org_EmailDomain") {
      org["emailDomainId"] = id;
    }
    else if (drpIdentity === "Org_OrganisationRoles") {
      org["contactRoleId"] = id;
      // this.validateOrganisationKeyRoles("contactRoleId");
    }
    else if (drpIdentity === "NamePrefix") {
      basicInfo["mrMrsId"] = id;
      // this.orgBasicInfoValidate("mrMrsId");
    }
    else if (drpIdentity === "cancel_reason") {
      let data = { ...this.state.addEditCreditNote }
      let details = { ...this.state.corporateCreditNotePaymentInfo }
      let findData = cancel_reason.filter((x) => x.id === id);
      if (findData.length > 0) {
        details.cancellationReason = findData[0].id;
        if (findData[0].name === "Others") {
          data.cancellationReason = '';
          this.setState({ isOtherCancellationReason: true });
          // this.validFinanceCreditNoteForOtherReason("cancellationReason", data);
        } else {
          data.cancellationReason = findData[0].name;
          this.setState({ isOtherCancellationReason: false });
        }
      } else {
        data.cancellationReason = '';
        details.cancellationReason = 0;
        this.setState({ isOtherCancellationReason: false, corporateCreditNotePaymentInfo: details });
      }
      // this.validateFinanceCreditNote("cancellationReason", details);
      this.setState({ addEditCreditNote: data, corporateCreditNotePaymentInfo: details })
    }
    this.setState({
      siisAccountInformation: siisInfo, organizationProfile: orgProfile, chapterInformation: chapter,
      membershipSubscription: subscription, prospectInformation: prospect, engagementTrackingDetails: engagement,
      organisationDetails: org
    });
  };

  handleChangeMultiDropdown(selected, identity) {
    var profile = this.state.organizationProfile;
    let bizDetails = this.state.organizationBusinessDetails;
    if (identity === "countriesWithPresence") {
      profile.selectedCountriesArr = selected;
      this.setState({ corporateBasicInformation: profile });
      //this.validateOrganisationProfile("selectedCountriesArr");
    }
    else if (identity === "coSoftwares") {
      profile.selectedSoftwaresArr = selected;
      this.setState({ organizationProfile: profile });
    }
    else if (identity === "businessSectors") {
      profile.selectedSectorsArr = selected;
      //this.validateOrganisationProfile("selectedSectorsArr");
      this.setState({ organizationProfile: profile });
    }
    else if (identity === "orgCountry") {
      profile.countryId = selected.value;
      //this.validateOrganisationProfile("countryId");
      this.setState({ organizationProfile: profile });
    }
    // else if (identity === "Designation_BasicInfo") {
    //   let detail = this.state.organizationBasicInformation;
    //   detail.selectedDesignation = selected;
    //   this.setState({ organizationBasicInformation: detail });
    // }
    // else if (identity === "Designation_Employee") {
    //   let detail = this.state.employeeDetails;
    //   detail.selectedDesignation = selected;
    //   this.setState({ employeeDetails: detail });
    // }
    // else if (identity === "Designation_KeyRoles") {
    //   let detail = this.state.organisationDetails;
    //   detail.selectedDesignation = selected;
    //   this.setState({ organisationDetails: detail });
    // } 
    // else if (identity === "Employee_OrganisationRole") {
    //   let detail = this.state.employeeDetails;
    //   detail.contactRoleId = selected.value;
    //   this.setState({ employeeDetails: detail });
    // }
    else if (identity === "Org_OrganisationRoles") {
      let detail = this.state.organisationDetails;
      detail.contactRoleId = selected;
      this.setState({ organisationDetails: detail });
    } else if (identity === "RecordedBy_Engagement") {
      let engagement = this.state.engagementTrackingDetails;
      engagement.corporateEngagementRecordedById = selected && selected.value && selected.value > 0 ? selected.value : 0;
      this.setState({ engagementTrackingDetails: engagement });
    } else if (identity === "ServiceType") {
      let serviceType = this.state.organizationBusinessDetails;
      serviceType.typesOfServices = selected;
      this.setState({ organizationBusinessDetails: serviceType }, () => {
        // this.validateCorporateBasic("typesOfServices")
      });
    } else if (identity === "IndustriesServed") {
      let industryServe = this.state.organizationBusinessDetails;
      industryServe.typesOfIndustriesServed = selected;
      this.setState({ organizationBusinessDetails: industryServe }, () => {
        //this.validateCorporateBasic("typesOfIndustriesServed")
      });
    } else if (identity === "CountriesWithPresence") {
      let countrypresence = this.state.organizationBusinessDetails;
      countrypresence.countriesPresence = selected;
      this.setState({ organizationBusinessDetails: countrypresence }, () => {
        // this.validateCorporateBasic("countriesPresence")
      });
    } else if (identity === "AssociationBenefits") {
      let association = this.state.organizationBusinessDetails;
      association.associationBenefits = selected;
      this.setState({ organizationBusinessDetails: association }, () => {
        // this.validateCorporateBasic("associationBenefits")
      });
    }
    else if (identity === "EmailSubscriptionEmployee") {
      const maxSelections = 50;
      const limitedSelections = selected.slice(0, maxSelections);
      this.setState(prevState => ({
        employeeDetails: {
          ...prevState.employeeDetails,
          emailSubscription: limitedSelections
        }
      }));
    }
    else if (identity === "EmailSubscriptionOrg") {
      const maxSelections = 50;
      const limitedSelections = selected.slice(0, maxSelections);
      this.setState(prevState => ({
        organisationDetails: {
          ...prevState.organisationDetails,
          emailSubscription: limitedSelections
        }
      }));
    }
  }

  handleChangeCheckBoxforcorporateSubscription = (e) => {
    const { name, checked } = e.target;
    let detail = this.state.membershipSubscription;
    detail[name] = checked;

    // if (this.state.memberDetails.registeredNoUnitNumber === true && this.state.memberDetails.mailingAddressFlag === true) {
    //   detail.mailingNoUnitNumber = true
    // } 
    // else {
    //   detail.mailingNoUnitNumber = false
    // }
    this.setState({ membershipSubscription: { ...detail } });
  };

  handleChangeCheckBoxSIIS = (e) => {
    const { name, checked } = e.target;
    let det = this.state.siisAccountInformation;
    det[name] = checked;
    this.setState({ siisAccountInformation: { ...det } });
  };

  handleChange(event, identity) {
    const value = event.target.value;
    const name = event.target.name;

    if (identity === "SIISInformation") {
      let detail = this.state.siisAccountInformation;
      detail[name] = value;
      this.setState({ siisAccountInformation: { ...detail } });
    }
    if (identity === "BasicInformation") {
      let detail = this.state.organizationBasicInformation;
      detail[name] = value;
      if (name === "email") {
        let fields = detail.email.split("@");
        detail["companyDomain"] = fields[1];
      }
      this.setState({ organizationBasicInformation: { ...detail } });
    }

    if (identity === "OrganizationProfile") {
      let detail = this.state.organizationProfile;
      detail[name] = value;
      this.setState({ organizationProfile: { ...detail } });
    }
    if (identity === "organizationBusinessDetails") {
      let detail = this.state.organizationBusinessDetails;
      detail[name] = value;
      this.setState({ organizationBusinessDetails: { ...detail } });
    }
    else if (identity === "EngagementTracking") {
      let detail = this.state.engagementTrackingDetails;
      detail[name] = value;
      this.setState({ engagementTrackingDetails: { ...detail } });
    }
    else if (identity === "MemebershipSubscription") {
      let detail = this.state.membershipSubscription;
      detail[name] = value;
      this.setState({ membershipSubscription: { ...detail } });
    }
    else if (identity === "ProspectInformation") {
      let detail = this.state.prospectInformation;
      detail[name] = value;
      this.setState({ prospectInformation: { ...detail } });
    }
    else if (identity === "IndividualContribution") {
      let detail = this.state.contributionDetails;
      detail[name] = value;
      this.setState({ contributionDetails: { ...detail } });
    }
    else if (identity === "Employee") {
      let detail = this.state.employeeDetails;
      detail[name] = value;
      if (name === "emailText") {
        detail["emailString"] = value;
        // this.validateEmployeeInfo("emailString");
      }
      if (name === "searchByEmail") {
        detail.searchByEmail = value;
        this.setState({ employeeDetails: { ...detail } }, () => {
          // this.validateSearchByEmailFieldForEmployee("searchByEmail");
        });
      }
      detail[name] = value;
      this.setState({ employeeDetails: { ...detail } });
    }
    else if (identity === "Organisation") {
      let detail = this.state.organisationDetails;
      detail[name] = value;
      if (name === "emailText") {
        detail["emailString"] = value;
        // this.validateOrganisationKeyRoles("emailString");
      }
      else if (name === 'searchByEmail') {
        detail["searchByEmail"] = value;
        // this.validateSearchByEmailField("searchByEmail");
      }
      this.setState({ organisationDetails: { ...detail } });
    }
    else if (identity === "CreditNote") {
      let detail = { ...this.state.addEditCreditNote };
      let val = value;
      let usedMonth = 0;
      let totalMonth = monthDifference(this.state.corporateCreditNotePaymentInfo.subcriptionEndDate, this.state.corporateCreditNotePaymentInfo.subscriptionStartDate, false);;
      if (name === "creditNoteMonth") {
        if (detail.paymentStatus !== 'Pending') {

          detail[name] = val;
          usedMonth = monthDifference(detail.creditNoteMonth, this.state.corporateCreditNotePaymentInfo.subscriptionStartDate, false);
          let paidMonth = ((totalMonth - usedMonth) / totalMonth)

          detail.entranceRefund = this.state.corporateCreditNotePaymentInfo.entranceFee;
          detail.annualRefund = this.state.corporateCreditNotePaymentInfo.annualFee;
          detail.chapterRefund = this.state.corporateCreditNotePaymentInfo.addtionalChapterFee;

          detail.entranceRefund = val !== "" ? parseFloat((paidMonth * detail.entranceRefund).toFixed(2)) : detail.entranceRefund;
          detail.annualRefund = val !== "" ? parseFloat((paidMonth * detail.annualRefund).toFixed(2)) : detail.annualRefund;
          detail.chapterRefund = val !== "" ? parseFloat((paidMonth * detail.chapterRefund).toFixed(2)) : detail.chapterRefund;

          detail.subTotalRefund = parseFloat((parseFloat(detail.entranceRefund) + parseFloat(detail.annualRefund))); //+ parseFloat(detail.chapterRefund)).toFixed(2));
          detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
          detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund))); //- parseFloat(detail.discountRefund)).toFixed(2)

        }
      }
      else if ((val = convertToDecimal(value)) || val === 0) {
        detail[name] = val;
        this.validFinanceCreditNoteForOtherReason(name, detail);
        detail.subTotalRefund = parseFloat((parseFloat(detail.entranceRefund) + parseFloat(detail.annualRefund))); //+ parseFloat(detail.chapterRefund)).toFixed(2)
        detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
        detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund))); //- parseFloat(detail.discountRefund)).toFixed(2)
        detail.cancellationReason = value;
      }
      this.setState({ addEditCreditNote: { ...detail } });
    }
  }

  handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    let det = this.state.organizationProfile;
    if (name === 'mailingNoUnitNumber') {
      det.mailingBuildingName = ""
    }
    if (name === 'registeredNoUnitNumber') {
      det.registeredBuildingName = ""
    }
    det[name] = checked;

    this.setState({ organizationProfile: { ...det } });
  };

  handleChangeInDropdownProspect = (selected) => {
    var resource = this.state.prospectInformation
    resource.selectedInterestChapterArr = selected
    this.setState({ prospectInformation: resource });
  };

  getCorporateFinancialInfo = (memberId) => {
    if (memberId > 0 && memberId != null) {
      this.corporateMemberServices.getCorporateFinancialInfo(memberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          if (response.responseItem.responseContent.length > 0) {
            this.setState({ corporateFinanceInfo: response.responseItem.responseContent });
          }
          else {
            this.swalServices.Alert('No records found.');
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    } else {

    }
  };

  financeGetCreditNoteInvoice = (value) => {
    let request = [];
    request.push(value);
    if (isNullString(value) !== "") {
      this.financeService
        .financeGetCreditNoteInvoice(request)
        .then((response) => {
          if (response.data !== null && response.status === 200) {
            var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

            var filename = "";
            filename = `Credit-Note-${formattedDate}`;

            var file = new Blob([response.data], { type: "application/pdf" });

            URL.createObjectURL(file);
            window.open(URL.createObjectURL(file), "_blank");

            saveAs(file, filename + ".pdf");
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Error("Credit Note is not available");
    }
  };

  getCorporateFinanceReceiptInvoice = (receiptInvoiceId, isReceipt, isMTO, isCertificate) => {
    if (receiptInvoiceId > 0 && receiptInvoiceId != null) {
      let request = {}
      request.corporateMemberId = this.state.memberId
      request.receiptInvoiceId = receiptInvoiceId
      request.isReceipt = isReceipt
      request.isMTO = isMTO
      request.isCertificate = isCertificate
      request.IsEvent = false
      this.corporateMemberServices.getCorporateFinanceReceiptInvoice(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")
          var filename = "";
          if (isReceipt === true) {
            filename = `Receipt-${formattedDate}`;
          } else if (isCertificate === true) {
            filename = `Certificate-${formattedDate}`;
          } else {
            filename = `Invoice-${formattedDate}`;
          }
          var file = new Blob([response.data], { type: "application/pdf" });

          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");

          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {

    }
  }

  getCreditNoteInfoForCorporateMember = (membershipPaymentId, paymentStatus, isReceipt, isMTO) => {
    if (this.state.memberId > 0 && membershipPaymentId > 0) {
      let request = {}
      request.memberId = this.state.memberId;
      request.membershipPaymentId = membershipPaymentId;
      request.isMTO = isMTO;
      this.financeServices.getCreditNoteInfoForCorporateMember(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          this.setState({ corporateCreditNotePaymentInfo: response.responseItem.responseContent });
          let creditNote = { ...this.state.addEditCreditNote };
          if (isNullString(paymentStatus) === 'Pending' && isNullBoolean(isReceipt) === false) {
            creditNote.entranceRefund = 0
            creditNote.annualRefund = 0
            creditNote.chapterRefund = 0;
            creditNote.subTotalRefund = 0;
            creditNote.gstRefund = 0;
            creditNote.discountRefund = 0;
            creditNote.totalRefund = 0;
            creditNote.paidTotalAmount = response.responseItem.responseContent.paidPriceByReconciliation ? response.responseItem.responseContent.paidPriceByReconciliation : 0;
            creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.compareAnnualRefund = response.responseItem.responseContent.annualFee;
            creditNote.compareChapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.creditNoteMonth = response.responseItem.responseContent.subscriptionStartDate ? response.responseItem.responseContent.subscriptionStartDate : new Date();// moment();

          } else {
            creditNote.entranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.annualRefund = response.responseItem.responseContent.annualFee;
            creditNote.chapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.subTotalRefund = response.responseItem.responseContent.subTotalAmount;
            creditNote.gstRefund = response.responseItem.responseContent.gstAmount;
            creditNote.discountRefund = response.responseItem.responseContent.discountedAmount;
            creditNote.paidTotalAmount = response.responseItem.responseContent.totalAmount;
            creditNote.totalRefund = response.responseItem.responseContent.totalAmount;
            creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.compareAnnualRefund = response.responseItem.responseContent.annualFee;
            creditNote.compareChapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.creditNoteMonth = "";
          }
          creditNote.membershipPaymentTypeId = response.responseItem.responseContent.membershipPaymentTypeId;
          creditNote.paymentStatus = paymentStatus;
          this.setState({ addEditCreditNote: creditNote, gstRate: response.responseItem.responseContent.gstRate });
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  getCancellationReasonForCreditNote = () => {
    this.financeServices.getCancellationReasonForCreditNote().then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null && response.responseItem.responseContent.length > 0) {
        let cancelReason = response.responseItem.responseContent;
        this.setState({ cancellationReason: cancelReason });
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }

  isValidFinanceCreditNote = () => {
    const newValidState = isValidForm(this.state.corporateCreditNotePaymentInfo, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
    return newValidState.isValid;
  }

  validateFinanceCreditNote = (key, providedState) => {
    providedState = providedState ? providedState : this.state.corporateCreditNotePaymentInfo;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
  }
  handleChangeForLoginToCorporateModal = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "corporateModalPassword") {
      this.setState({ passwordForCorporateModal: value });
    }
  };
  loginToIndividualMemberForCorporateMember = () => {
    let request = {};
    request.companyUEN = this.state.siisAccountInformation.companyUEN;
    request.password = this.state.passwordForCorporateModal;
    this.corporateMemberServices
      .loginToIndividualMemberForCorporateMember(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.isAuthenticated === true) {
          let details = {
            companyUEN: response.responseItem.responseContent.companyUEN,
          }
          this.redirectToWeb(details);
        }
        else {
          this.swalServices.Error(
            "Invalid Password"
          );
          this.setState({ setLoginToCorporateModal: false });
        }
        this.setState({ isLoading: false });
      });
  };

  isValidFinanceCreditNoteForOtherReason = () => {
    const newValidState = isValidForm(this.state.addEditCreditNote, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
    return newValidState.isValid;
  }

  validFinanceCreditNoteForOtherReason = (key, providedState) => {
    providedState = providedState ? providedState : this.state.addEditCreditNote;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
  }

  addFinanceCreditNote = () => {
    if (this.isValidFinanceCreditNote()) {
      // if (this.isValidFinanceCreditNoteForOtherReason()) {
      if (this.state.addEditCreditNote.paidTotalAmount >= this.state.addEditCreditNote.totalRefund) {
        this.setState({ isLoading: true })
        let request = {
          corporateMemberId: this.state.memberId,
          bookingId: this.state.addEditCreditNote.membershipPaymentId,
          creditNoteMonth: this.state.addEditCreditNote.membershipPaymentTypeId === 1 || this.state.addEditCreditNote.membershipPaymentTypeId === 2 || this.state.addEditCreditNote.membershipPaymentTypeId === 3 ? this.state.addEditCreditNote.creditNoteMonth : this.state.corporateCreditNotePaymentInfo.subcriptionEndDate,
          creditNoteTotalRefund: this.state.addEditCreditNote.totalRefund,
          creditNoteStatusId: 1,
          creditNoteItem: [],
          creditNoteCancellationReason: this.state.addEditCreditNote.cancellationReason,
          CreatedAppId: 114,
          CreatedAt: moment(),
          CreatedBy: 1,
          creditSubTotalAmount: this.state.addEditCreditNote.subTotalRefund,
          creditNoteGSTAmount: this.state.addEditCreditNote.gstRefund,
          creditNoteDiscountAmount: this.state.addEditCreditNote.discountRefund ? this.state.addEditCreditNote.discountRefund : 0,
          membershipPaymentTypeId: this.state.addEditCreditNote.membershipPaymentTypeId,
        }
        let creditNoteItemList = [];
        let stardDate = this.state.corporateCreditNotePaymentInfo.subscriptionStartDate;
        let endDate = this.state.corporateCreditNotePaymentInfo.subcriptionEndDate;
        let membershipTypeName = this.state.corporateCreditNotePaymentInfo.membershipTypeName;

        if (this.state.addEditCreditNote.membershipPaymentTypeId === 1) {
          creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Entrance Fee`, creditNoteItemAmount: this.state.addEditCreditNote.entranceRefund });
          creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Annual Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.annualRefund });
          //creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Additional Chapter Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.chapterRefund });
          request.creditNoteItem = creditNoteItemList;
          request.isCorporateMember = true
        } else if (this.state.addEditCreditNote.membershipPaymentTypeId === 2) {
          //creditNoteItemList.push({ creditNoteItem: `MTO - Entrance Fee`, creditNoteItemAmount: this.state.addEditCreditNote.entranceRefund });
          creditNoteItemList.push({ creditNoteItem: `MTO - Subscription Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.annualRefund });
          //creditNoteItemList.push({ creditNoteItem: `MTO - Additional Chapter Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.chapterRefund });
          request.creditNoteItem = creditNoteItemList;
          request.isMTO = true
        }

        this.financeServices.addFinanceCreditNote(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === '') {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Credit Note"));
              this.getCorporateFinancialInfo(this.state.memberId);
            }
            else {
              this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Credit note"));
            }
          }
          else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
      } else {
        this.swalServices.Warning("Refund Amount Must be Less then Total Amount of Paid");
        this.setState({ isLoading: false });
      }
      // }
    }
  }

  addCreditNoteInvoiceRecordsForBatchJob = (financeCreditNoteId) => {
    let request = [financeCreditNoteId];
    this.financeServices.addCreditNoteInvoiceRecordsForBatchJob(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }

  setOrganisationModel = (value) => {
    this.setState({ addEditOrganisationModel: value });
    this.resetOrganisationRoleInfo();
  }

  handleisAddEditAOrganisation = (isCreateNewOrgRole) => {
    if (isCreateNewOrgRole === true) {
      this.setState({ isAddEditAOrganisation: false, isCreateNewOrgRole: true });
    }
    else {
      this.setState({ isAddEditAOrganisation: false, isCreateNewOrgRole: false });
    }
  }

  handleisAddEditAOrganisationEmployees = (isCreateNewOrgRoleForEmployee) => {
    if (isCreateNewOrgRoleForEmployee === true) {
      this.setState({ isAddEditProfileButtonLoader: false, isCreateNewOrgRoleForEmployee: true });
    }
    else {
      this.setState({ isAddEditProfileButtonLoader: false, isCreateNewOrgRoleForEmployee: false });
    }
  }

  searchByName = (value) => {
    if (value.length > 2) {
      this.getEmployeeList(this.state.memberId, value)
    }
    else if (value.length < 3) {
      this.getEmployeeList(this.state.memberId, "")
    }
  }

  setLoader = (boolean) => {
    this.setState({ isLoaderOn: boolean });
  }

  setAddEditModel = (boolean) => {
    this.setState({ addEditModelForEmployee: boolean })
    this.resetEmployeeInfo()
  }

  markIndivisualMemberAsNonMember = (id) => {
    this.setLoader(true);
    this.corporateMemberServices.markIndivisualMemberAsNonMember(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Delete employee information successfully");
        this.setState({ addEditModelForEmployee: false }, () => {
          this.getEmployeeList(this.state.memberId, "");
          this.setLoader(false);
        });
      } else {
        this.swalServices.Error(response.message);
        this.setLoader(false);
      }
    });
  }

  //PDF File Upload 
  onFileChangePDF = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "application/pdf")) {
      let request = {};
      request.storagePath = "ACRA";
      request.file = file;
      request.fileName = file.name;

      this.commonServices.uploadPDF(request).then((response) => {
        if (response != null) {
          if (request.storagePath === "ACRA") {
            let detail = this.state.organizationProfile;
            detail["acraFilePath"] = response.fileName;
            // detail["acraCertificatePath"] = file.name;

            this.setState({ organizationProfile: detail }, () => {
              this.swalServices.Success("File Uploaded Successfully.");
            });
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {
      this.swalServices.Error("Please select only PDF files");
    }
  };

  getAllDropdownsForEndorsers(id) {
    this.corporateMemberServices.getAllDropdownsForEndorsers().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let ordinaryEndorsers = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "OrdinaryEndorsers");
        this.setState({ ordinaryEndorsers: ordinaryEndorsers });
      }
      if (response.statusCode === 200 && response.responseItem != null) {
        let allEndorsers = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AllEndorsers" || drp.dropdownName === "OrdinaryEndorsers");
        allEndorsers = allEndorsers.filter((drp) => drp.id !== id);
        this.setState({ allEndorsers: allEndorsers });
      }
    });
  }

  getRepresentativesById = (id, identity) => {
    this.corporateMemberServices.getRepresentativesById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.length > 0) {
        if (identity === "OrdinaryMembers") {
          let ordinaryRepresentative = response.responseItem.responseContent
          this.setState({ ordinaryRepresentative: ordinaryRepresentative });
        }
        if (identity === "AllMembers") {
          let allRepresentative = response.responseItem.responseContent
          this.setState({ allRepresentative: allRepresentative }
          );
        }
      }
    });
  }

  getEndorsersDetailById = () => {
    if (this.state.memberId > 0 && this.state.memberId != null) {
      this.corporateMemberServices.getEndorsersDetailById(this.state.memberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          if (details != null) {
            this.setState({ endorsersDetails: details }, () => {
              this.getAllDropdownsForEndorsers()
              this.getRepresentativesById(details.endorsersId1, "OrdinaryMembers");
              this.getRepresentativesById(details.endorsersId2, "AllMembers");
            })
          } else {
            this.swalServices.Error("No Records Found!");
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
      })
    }
  }

  getInvoiceReceiptPdfByBookingId = (memberId, type) => {
    memberId = this.state.memberId;
    let reqParams = [memberId, type];
    this.individualMemberServices.getInvoiceReceiptPdfByBookingId(reqParams)
      .then((response) => {
        if (response != null) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
          var filename = "ACRAPDF";
          if (type === "ACRA") {
            filename = this.state.organizationProfile.acraFilePath;
          }
          var file = new Blob([response.data], { type: "application/pdf" });

          const fileURL = URL.createObjectURL(file);
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;

          //saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  redirectToWeb = (details) => {
    let host = WebPath;
    let urlLink = "";
    urlLink = `${host}CorporateDashboard?isSuperLogin&${encryptAES(details.companyUEN, 0)}`;
    window.open(urlLink, "_blank");
  }

  handleClick = () => {
    // e.stopPropagation();
    this.setState({ setLoginToCorporateModal: true });
  };

  //GENERATE PDF 
  getAllDetailsForGeneratePDF = (memberId) => {
    let request = [];
    request.push(memberId);
    this.TaskListServices.getAllDetailsForGeneratePDF(request).then((response) => {
      if (response && response.status === 200) {
        let formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
        let filename = `MembershipInformation-${formattedDate}.pdf`;
        let file = new Blob([response.data], { type: "application/pdf" });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
        saveAs(file, filename);
      } else {
        this.swalServices.Error(response.message);
      }
    }).catch((error) => {
      this.swalServices.Error("Failed to fetch PDF");
    });
  }

  addReinstatementMembershipDetails = (memberId) => {
    // this.swalServices
    //   .Confirm(
    //     "Are you sure you want to add this member to Reinstatement?",
    //     "Reinstatement",
    //     CommonSuccessMessages.Yes_Text,
    //     CommonSuccessMessages.No_Text
    //   ).then((response) => {
    //     if (response) {

   
    // this.setState({ isReinstatementLoader: true });
    // this.corporateMemberServices.addEditReinstatementMembershipDetails(memberId).then((response) => {
    //   if (response.statusCode === 200 && response.responseItem != null) {
    //     this.swalServices.Success("Member Added to Reinstatement successfully.");
    //     this.setState({ isReinstatementLoader: false });
    //   } else {
    //     this.swalServices.Error(response.message);
    //   }
    //   this.setState({ isReinstatementLoader: false });
    // }); 

    this.setState({ isReinstatementLoader: true });
    this.swalServices.Confirm(
      "SLA Reinstatement",
      "Are you sure you want to reinstate?",
      CommonSuccessMessages.Yes_Text,
      CommonSuccessMessages.No_Text
    ).then((response) => {
      if (response === true) {
        this.corporateMemberServices.addEditReinstatementMembershipDetails(memberId).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success("Reinstatement successful.");
            this.setState({ isReinstatementLoader: false });
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isReinstatementLoader: false });
        });
      } else {
        this.setState({ isReinstatementLoader: false });
      }
    })
   


    // }
    // })
  };

  render() {
    return (
      <div className="main-body-section">
        {/* New  Start*/}
        {/* SLA Corporate Account Information Start*/}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="SIISACC"
              type="checkbox"
              name="corporate-d"
              value={"SIISAccountInformation"}
              checked={this.state.openSection === "SIISAccountInformation"}
              onClick={() => this.openSection("SIISAccountInformation")}
              onChange={() => { }}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="SIISACC"
            >
              <span className="small-title font-bold theme-color pl-5">
                SLA Corporate Account Information
              </span>
              <div className="flex items-left justify-between text-[18px] text-[#757575]">
                <p>SLA Individual ID: {this.state.siisAccountInformation.siisAccountId ? this.state.siisAccountInformation.siisAccountId : "SLA"}</p>
              </div>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
              <SIISCorporateAccountInformation
                status={this.state.status}
                reason={this.state.reason}
                _by={this.state._by}
                corporateInternalFlags={this.state.corporateInternalFlags}
                siisAccountInformation={this.state.siisAccountInformation}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditSIISCorporateAccountInformation.bind(this)}
                handleChange={this.handleChange.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                validState={this.state.validSiisAccountInfo}
                validate={this.siisAccountInfoValidate.bind(this)}
                handleChangeCheckBoxSIIS={this.handleChangeCheckBoxSIIS.bind(this)}
                isCorporateAccountLoading={this.state.isCorporateAccountLoading}
                isReinstatementLoader={this.state.isReinstatementLoader}
                redirectToWeb={this.redirectToWeb.bind(this)}
                handleClick={this.handleClick.bind(this)}
                addReinstatementMembershipDetails={this.addReinstatementMembershipDetails.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* SLA Corporate Account Information End*/}

        {/* Engagement Tracking and Notes Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full  border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="engtrack"
              type="checkbox"
              name="corporate-d"
              value={"EngagementTracking"}
              checked={this.state.openSection === "EngagementTracking"}
              onChange={() => { }}
              onClick={() => this.openSection("EngagementTracking")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="engtrack"
            >
              <span className="small-title font-bold theme-color pl-5">
                Engagement Tracking and Notes
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <EngagementTrackingNotes
                engagementType={this.state.engagementType}
                _By={this.state._by}
                engagementList={this.state.engagemmentTrackingList}
                engagementTrackingDetails={this.state.engagementTrackingDetails}
                isCorporate={this.state.memberId}
                validState={this.state.validState}
                onEdit={this.onEditEngagement.bind(this)}
                onClick={this.addEditEngagementTracking.bind(this)}
                handleChange={this.handleChange.bind(this)}
                setFilterParameters={this.setFilterParameters.bind(this)}
                validate={this.engagementValidate.bind(this)}
                isEngagementTrackingLoading={this.state.isEngagementTrackingLoading}
                recorded_by={this.state.recorded_by}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Engagement Tracking and Notes End */}
        {/* Prospect Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="ProspectInfo"
              type="checkbox"
              name="corporate-d"
              value={"ProspectInformation"}
              checked={this.state.openSection === "ProspectInformation"}
              onChange={() => { }}
              onClick={() => this.openSection("ProspectInformation")}
            />
            <label
              className="heading-part px-7 py-4 mb-0 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="ProspectInfo"
            >
              <span className="small-title font-bold theme-color pl-5">
                Prospect Information
              </span>
            </label>

            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <ProspectInformation
                prospectInformation={this.state.prospectInformation}
                status={this.state.status}
                _by={this.state._by}
                source1={this.state.source1}
                corporatePIC1={this.state.corporatePIC1}
                corporatePIC2={this.state.corporatePIC2}
                prospectSource2={this.state.prospectSource2}
                prospectStatus={this.state.prospectStatus}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditCorporateProspect.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validState={this.state.validProspectMem}
                validate={this.prospectMemValidate.bind(this)}
                interestedChapter={this.state.interestedChapter}
                getToKnowUsArr={this.state.getToKnowUsArr}
                handleChangeInDropdown={this.handleChangeInDropdownProspect.bind(this)}
                isProspectInformationLoading={this.state.isProspectInformationLoading}
              />
            </div>
          </div>
        </div>
        {/* Prospect Information End */}
        {/* Finance Card Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="Financecard"
              type="checkbox"
              name="corporate-d"
              value={"Financecard"}
              checked={this.state.openSection === "Financecard"}
              onChange={() => { }}
              onClick={() => this.openSection("Financecard")}
            />
            <label
              className="heading-part px-7 py-4 mb-0 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="Financecard"
            >
              <span className="small-title font-bold theme-color pl-5">
                Finance Card
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <FinanceCard
                corporateFinance={this.state.corporateFinance}
                handleChangeCheckBox={this.handleChangeCheckBoxFinanceCard.bind(this)}
                corporateCreditTerms={this.state.corporateCreditTerms}
                setFilterParameters={this.setFilterParametersFinanceCard.bind(this)}
                onSave={this.addEditFinanceCardDetails.bind(this)}
                validState={this.state.validStateFinanceCard}
                isFinanceCardLoading={this.state.isFinanceCardLoading}
              />
            </div>
          </div>
        </div>
        {/* Finance Card End */}
        {/* Corporate Membership Subscription Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="CorporateSubscrib"
              type="checkbox"
              name="corporate-d"
              value={"CorporateSubscrib"}
              checked={this.state.openSection === "CorporateSubscription"}
              onChange={() => { }}
              onClick={() => this.openSection("CorporateSubscription")}
            />
            <label
              className="heading-part px-7 py-4 mb-0 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="CorporateSubscrib"
            >
              <span className="small-title font-bold theme-color pl-5">
                Corporate Membership Subscription
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <CorporateMembershipSubscription
                _by={this.state._by}
                billingCategory={this.state.billingCategory}
                membershipTypes={this.state.membershipTypes}
                membershipSubscription={this.state.membershipSubscription}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditCorporateMembershipSubscription.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validState={this.state.validMemberSub}
                validate={this.memberSubValidate.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                renewCorporateMember={this.renewCorporateMember.bind(this)}
                isMembershipSubscriptionLoading={this.state.isMembershipSubscriptionLoading}
                isLoadingForRenewal={this.state.isLoadingForRenewal}
                tacNames={this.state.tacNames}
                handleChangeCheckBoxforcorporateSubscription={this.handleChangeCheckBoxforcorporateSubscription.bind(this)}

              />
            </div>
          </div>
        </div>
        {/* Corporate Membership Subscription End */}
        {/* Corporate Basic Information Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="OBI"
              type="checkbox"
              name="corporate-d"
              value={"OrganisationBasicInformation"}
              checked={
                this.state.openSection === "OrganisationBasicInformation"
              }
              onChange={() => { }}
              onClick={() => this.openSection("OrganisationBasicInformation")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="OBI"
            >
              <span className="small-title font-bold theme-color pl-5">
                Organisation Basic Information
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <OrganisationBasicInformations

                membershipTypes={this.state.membershipTypes} Organisation Profile
                organizationBasicInformation={this.state.organizationBasicInformation}
                disableUenNumber={this.state.disableUenNumber}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditOrganisationBasicInformation.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validState={this.state.validOrgBasicInfo}
                validate={this.orgBasicInfoValidate.bind(this)}
                mrMrs={this.state.mrMrs}
                designationList={this.state.designationList}
                contactRoles={this.state.contactRoles}
                membershipSubscription={this.state.membershipSubscription}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                isOrganizationBasicInfoLoading={this.state.isOrganizationBasicInfoLoading}
              />
            </div>
          </div>
        </div>
        {/* Corporate Basic Information End */}
        {/* Organisation Profile Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="OPRS"
              type="checkbox"
              name="corporate-d"
              value={"OrganisationProfile"}
              checked={this.state.openSection === "OrganisationProfile"}
              onChange={() => { }}
              onClick={() => this.openSection("OrganisationProfile")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="OPRS"
            >
              <span className="small-title font-bold theme-color pl-5">
                Organisation Profile
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <OrganisationProfile
                organizationProfile={this.state.organizationProfile}
                countriesWithPresence={this.state.countriesWithPresence}
                corporateGrossTurnovers={this.state.corporateGrossTurnovers}
                organizationRoleArray={this.state.organizationRoleArray}
                isOrganizationProfileLoading={this.state.isOrganizationProfileLoading}
                _By={this.state._by}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditOrganisationProfile.bind(this)}
                handleChange={this.handleChange.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                saveMainImage={this.saveMainImage.bind(this)}
                validState={this.state.validStateProfile}
                validate={this.validateOrganisationProfile.bind(this)}
                charLimit={this.state.charLimit}
                handleChangeCheckBox={this.handleChangeCheckBox.bind(this)}
                onFileChangePDF={this.onFileChangePDF.bind(this)}
                getInvoiceReceiptPdfByBookingId={this.getInvoiceReceiptPdfByBookingId.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Organisation Profile End */}
        {/* Organisation Business Details Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="OBDS"
              type="checkbox"
              name="corporate-d"
              value={"OrganisationBusinessDetail"}
              checked={this.state.openSection === "OrganisationBusinessDetail"}
              onChange={() => { }}
              onClick={() => this.openSection("OrganisationBusinessDetail")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="OBDS"
            >
              <span className="small-title font-bold theme-color pl-5">
                Other Information
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <OrganisationBasicInformation
                validState={this.state.validState}
                _by={this.state._by}
                organizationBusinessDetails={this.state.organizationBusinessDetails}
                isOtherInfoLoading={this.state.isOtherInfoLoading}
                setFilterParameters={this.setFilterParameters.bind(this)}
                onSave={this.addEditOrganisationBussinessDetails.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validate={this.validateCorporateBasic.bind(this)}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                serviceType={this.state.serviceType}
                industriesServed={this.state.industriesServed}
                countriesWithPresence={this.state.countriesWithPresence}
                getToKnow={this.state.getToKnow}
                associationBenefits={this.state.associationBenefits}
              />
            </div>
          </div>
        </div>
        {/* Organisation Business Details End */}

        {/* Organisation Employees Section Start  */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="emply"
              type="checkbox"
              name="corporate-d"
              value={"Employees"}
              checked={this.state.openSection === "Employees"}
              onChange={() => { }}
              onClick={() => this.openSection("Employees")}
            />
            <label
              className="heading-part px-7 py-2 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="emply"
            >
              <span className="small-title font-bold theme-color pl-5 lg:w-[40%]">
                Organisation Employees
              </span>
              <div className="flex items-center justify-end right-id-sec lg:w-[35%] px-5">

                <button
                  className="flex items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.resetEmployeeInfo();
                    this.setState({ addEditModelForEmployee: true, isCreateNewEmployee: true, isEditEmployee: false });
                    this.handleisAddEditAOrganisationEmployees(true);
                  }}
                >
                  <span className="text-xl text-[#c00000] font-semibold mr-4 min-w-[30px] min-h-[20px] border border-[#c00000]">
                    +
                  </span>
                  <span className="text-xl text-[#c00000] font-semibold tracking-normal">
                    Add Employee
                  </span>
                </button>
              </div>
              <div className="flex items-center justify-between right-id-sec xl:w-[25%]">
                <input
                  className={`w-full outline-none appearance-none form-input rounded-none border-[#757575]  text-[#757575]}`}
                  placeholder="Search"
                  type="text"
                  name=""
                  onChange={(e) => this.searchByName(e.target.value)}
                />
              </div>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-5">
              <EmployeesList
                employeeList={this.state.employeeList}
                emailDomain={this.state.emailDomain}
                employeeDetails={this.state.employeeDetails}
                validState={this.state.validStateEmp}
                domainArr={this.state.domainArr}
                mrMrs={this.state.mrMrs}
                isLoaderOn={this.state.isLoaderOn}
                onEditEmployee={this.onEditEmployee.bind(this)}
                saveEmployee={this.addEditEmployeeInformation.bind(this)}
                setFilterParameters={this.setFilterParametersEmployee.bind(this)}
                handleChange={this.handleChange.bind(this)}
                validate={this.validateEmployeeInfo.bind(this)}
                designationList={this.state.designationList}
                //addEditModelForEmployee={this.state.addEditModelForEmployee}
                handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                setLoader={this.setLoader.bind(this)}
                setAddEditModel={this.setAddEditModel.bind(this)}
                markIndivisualMemberAsNonMember={this.markIndivisualMemberAsNonMember.bind(this)}
                addEditModelForEmployee={this.state.addEditModelForEmployee}
                isCreateNewEmployee={this.state.isCreateNewEmployee}
                isEditEmployee={this.state.isEditEmployee}
                isEmployeeModalEditable={this.state.isEmployeeModalEditable}
                searchIndividualMemberByEmailForOrgRole={this.searchIndividualMemberByEmailForOrgRole.bind(this)}
                isCreateNewOrgRoleForEmployee={this.state.isCreateNewOrgRoleForEmployee}
                handleisAddEditAOrganisationEmployees={this.handleisAddEditAOrganisationEmployees.bind(this)}
                isAddEditProfileButtonLoader={this.state.isAddEditProfileButtonLoader}
                contactRoles={this.state.contactRoles}
                years={this.state.years}
                emailSubscription={this.state.emailSubscription}

              //searchIndividualMemberByEmailForEmployee={this.searchIndividualMemberByEmailForEmployee.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Organisation Employees Section End */}

        {/* Organisation Business Details Start */}
        <div className="bg-white border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="EDS"
              type="checkbox"
              name="corporate-d"
              value={"Endorsers"}
              checked={this.state.openSection === "Endorsers"}
              onChange={() => { }}
              onClick={() => this.openSection("Endorsers")}
            />
            <label
              className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="EDS"
            >
              <span className="small-title font-bold theme-color pl-5">
                Endorsers
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
              <Endorsers
                ordinaryEndorsers={this.state.ordinaryEndorsers}
                allEndorsers={this.state.allEndorsers}
                ordinaryRepresentative={this.state.ordinaryRepresentative}
                allRepresentative={this.state.allRepresentative}
                endorsersDetails={this.state.endorsersDetails}
              />
            </div>
          </div>
        </div>
        {/* Organisation Business Details End */}
        {/* Financials Record Start */}
        <div className="bg-[#f5faff] border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="finance"
              type="checkbox"
              name="corporate-d"
              value={"FinancialRecords"}
              checked={this.state.openSection === "FinancialRecords"}
              onChange={() => { }}
              onClick={() => this.openSection("FinancialRecords")}
            />
            <label
              className="bg-white heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="finance"
            >
              <span className="small-title font-bold theme-color pl-5">
                Financials
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal">
              <FinancialsRecord
                corporateFinanceInfo={this.state.corporateFinanceInfo}
                getCorporateFinanceReceiptInvoice={this.getCorporateFinanceReceiptInvoice.bind(this)}
                financeGetCreditNoteInvoice={this.financeGetCreditNoteInvoice.bind(this)}
                getCreditNoteInfoForCorporateMember={this.getCreditNoteInfoForCorporateMember.bind(this)}
                corporateCreditNotePaymentInfo={this.state.corporateCreditNotePaymentInfo}
                getCancellationReasonForCreditNote={this.getCancellationReasonForCreditNote.bind(this)}
                cancellationReason={this.state.cancellationReason}
                setFilterParameters={this.setFilterParameters.bind(this)}
                isOtherCancellationReason={this.state.isOtherCancellationReason}
                addEditCreditNote={this.state.addEditCreditNote}
                handleChange={this.handleChange.bind(this)}
                addFinanceCreditNote={this.addFinanceCreditNote.bind(this)}
                validate={this.validateFinanceCreditNote.bind(this)}
                validCreditNote={this.state.validCreditNote}
                validate2={this.validFinanceCreditNoteForOtherReason.bind(this)}
                validCreditNote2={this.state.validCreditNote2}
                isLoading={this.state.isLoading}
              />
            </div>
          </div>
        </div>

        {/* Past Applications Start */}
        <div className="bg-[#f5faff] border border-[#c9c9c9] mb-3">
          <div className="tab w-full border-t">
            <input
              className="absolute opacity-0 toggle-main"
              id="PastApplication"
              type="checkbox"
              name="corporate-d"
              value={"PastApplication"}
              checked={this.state.openSection === "PastApplication"}
              onChange={() => { }}
              onClick={() => this.openSection("PastApplication")}
            />
            <label
              className="bg-white heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
              htmlFor="PastApplication"
            >
              <span className="small-title font-bold theme-color pl-5">
                Past Application
              </span>
            </label>
            <div className="committee-info-overflow tab-content overflow-hidden leading-normal">
              <PastApplication
                memberId={this.state.memberId}
                getAllDetailsForGeneratePDF={this.getAllDetailsForGeneratePDF.bind(this)}
              />
            </div>
          </div>
        </div>
        {/* Past Applications End */}

        {/* Organisation Key Role & Chapter/Committee Roles Start */}
        <div className="w-full">
          <OrganisationChapterRole
            mrMrs={this.state.mrMrs}
            emailDomain={this.state.emailDomain}
            domainArr={this.state.domainArr}
            Organisationrole={this.state.Organisationrole}
            chaptercommitteerol={this.state.chaptercommitteerol}
            organisationDetails={this.state.organisationDetails}
            organizationRoleArray={this.state.organizationRoleArray}
            isAddEditAOrganisation={this.state.isAddEditAOrganisation}
            editOrganisationRole={this.editOrganisationRole.bind(this)}
            addEditOraganisation={this.addEditOraganisation.bind(this)}
            setFilterParameters={this.setFilterParametersOrgRoles.bind(this)}
            handleChange={this.handleChange.bind(this)}
            deleteOrganisationRole={this.deleteOrganisationRole.bind(this)}
            handleReset={this.handleReset.bind(this)}
            validate={this.validateOrganisationKeyRoles.bind(this)}
            validState={this.state.validStateRoles}
            addEditOrganisationModel={this.state.addEditOrganisationModel}
            setOrganisationModel={this.setOrganisationModel.bind(this)}
            handleisAddEditAOrganisation={this.handleisAddEditAOrganisation.bind(this)}
            designationList={this.state.designationList}
            isCreateNewOrgRole={this.state.isCreateNewOrgRole}
            isOrganisationModalEditable={this.state.isOrganisationModalEditable}
            handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
            searchIndividualMemberByEmailForOrgRole={this.searchIndividualMemberByEmailForOrgRole.bind(this)}
            contactRoles={this.state.contactRoles}
            years={this.state.years}
            emailSubscription={this.state.emailSubscription}
            individualOrganisationRole={this.state.individualOrganisationRole}
          />
        </div>
        {/* Organisation Key Role & Chapter/Committee Roles End */}
        {/* New  End*/}

        <div className="add-edite-super-popup">
          <SuperUserLoginModal
            // dashboardDetails={this.state.dashboardDetails}
            organizationBasicInformation={
              this.state.siisAccountInformation
            }
            setLoginToCorporateModal={this.state.setLoginToCorporateModal}
            passwordForCorporateModal={this.state.passwordForCorporateModal}
            setOpenModal={() =>
              this.setState({ setLoginToCorporateModal: false })
            }
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setLoginToCorporateModal: false });
            }}
            handleChangeForLoginToCorporateModal={this.handleChangeForLoginToCorporateModal.bind(
              this
            )}
            loginToIndividualMemberForCorporateMember={this.loginToIndividualMemberForCorporateMember.bind(
              this
            )}
          />
        </div>
      </div>

    );
  }
}