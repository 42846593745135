import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import { AddEditCommunitySpotlight, GetCommunitySpotlightById } from '../ApiEndPoints';

export default class CommunitySpotlightServices {

    async addEditCommunitySpotlight(request) {
        return axiosPostAuthorize(AddEditCommunitySpotlight, request)
    }

    async getCommunitySpotlightById(request) {
        return axiosGetAuthorize(GetCommunitySpotlightById, request)
    }
}