import moment from "moment";
import React, { useEffect, useState } from "react";
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import SwalServices from "../../services/swalServices/SwalServices";
import Selector from "../dropdown/commonDropdown/Selector";
import { DeleteButtonIcon, SettingsButtonIcon } from "../SVGConstants";
import TextInput from "../UIComponent/TextInput";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../utils/Messages";
import { convertToDecimal } from "../../utils/Utils";




const addOnObj = { eventAddOnPriceId: 0, eventId: 0, eventAddOnType: "", eventAddOnPrice: 0 };

// const earlyPackageObj = {
//   eventPriceId: 0,
//   eventPackageId: 0,
//   earlyBirdPrice: 0,
//   earlyBirdCredit: 0,
//   isSuperEarlyBirdPrice: false,
//   promotionStartDate: '',
//   promotionEndDate: '',
//   maxQuantity: 0
// };

// const superEarlyPackageObj = {
//   eventPriceId: 0,
//   eventPackageId: 0,
//   earlyBirdPrice: 0,
//   earlyBirdCredit: 0,
//   isSuperEarlyBirdPrice: true,
//   promotionStartDate: '',
//   promotionEndDate: '',
//   maxQuantity: 0
// };

// const packagePriceObj = {
//   eventPackageId: 0,
//   eventPackageName: 'Non Member',
//   eventPrice: 0,
//   eventCredit: 0,
//   entryPackagePrice: [
//     // earlyPackageObj,
//     // superEarlyPackageObj
//     {
//       eventPriceId: 0,
//       eventPackageId: 0,
//       earlyBirdPrice: 0,
//       earlyBirdCredit: 0,
//       isSuperEarlyBirdPrice: false,
//       promotionStartDate: "2000-12-12",
//       promotionEndDate: "2000-12-12",
//       maxQuantity: 0
//     },
//     {
//       eventPriceId: 0,
//       eventPackageId: 0,
//       earlyBirdPrice: 0,
//       earlyBirdCredit: 0,
//       isSuperEarlyBirdPrice: true,
//       promotionStartDate: "2000-12-12",
//       promotionEndDate: "2000-12-12",
//       maxQuantity: 0
//     }
//   ]
// };

const eventPricingObj = {
  //eventPackageId: 0,
  eventId: 0,
  eventTimeSlotId: 0,
  isAttendanceFree: false,
  eventSalesEndDate: 1,
  additionalGuestCorporate: 0,
  additionalGuestIndividual: 0,
  isEventAddOn: false,
  isQuantityAllow: false,
  entryPackage: [
    // packagePriceObj,
    {
      eventPackageId: 0,
      eventPackageName: 'Non Member',
      eventPrice: 0,
      eventCredit: 0,
      entryPackagePrice: [
        // earlyPackageObj,
        // superEarlyPackageObj
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: false,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Non Member',
        },
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: true,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Non Member',
        }
      ]
    },
    {
      eventPackageId: 0,
      eventPackageName: 'Ordinary Member',
      eventPrice: 0,
      eventCredit: 0,
      entryPackagePrice: [
        // earlyPackageObj,
        // superEarlyPackageObj
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: false,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Ordinary Member',

        },
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: true,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Ordinary Member',

        }
      ]
    },
    {
      eventPackageId: 0,
      eventPackageName: 'Associate Member',
      eventPrice: 0,
      eventCredit: 0,
      entryPackagePrice: [
        // earlyPackageObj,
        // superEarlyPackageObj
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: false,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Associate Member',

        },
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: true,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Associate Member',

        }
      ]
    },
    {
      eventPackageId: 0,
      eventPackageName: 'Startup Member',
      eventPrice: 0,
      eventCredit: 0,
      entryPackagePrice: [
        // earlyPackageObj,
        // superEarlyPackageObj
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: false,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Startup Member',

        },
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: true,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Startup Member',

        }
      ]
    },
    {
      eventPackageId: 0,
      eventPackageName: 'Affliate',
      eventPrice: 0,
      eventCredit: 0,
      entryPackagePrice: [
        // earlyPackageObj,
        // superEarlyPackageObj
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: false,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Affliate',

        },
        {
          eventPriceId: 0,
          eventPackageId: 0,
          earlyBirdPrice: 0,
          earlyBirdCredit: 0,
          isSuperEarlyBirdPrice: true,
          promotionStartDate: "",
          promotionEndDate: "",
          maxQuantity: 0,
          eventPackageName: 'Affliate',

        }
      ]
    },
  ],
  entryPackageAddOn: [
    addOnObj
  ],
};

const pricingAdditionalGuestAttendeeValidationRules = {
  // additionalGuestCorporate: [
  //   {
  //     type: "require",
  //     message: CommonValidationMessages.SelectRequired.replace("{0}", "max guest corporate"),
  //   }
  // ],
  additionalGuestIndividual: [
    {
      type: "require",
      message: CommonValidationMessages.SelectRequired.replace("{0}", "max guest individual"),
    }
  ],
  eventSalesEndDate: [
    {
      type: "require",
      message: CommonValidationMessages.SelectRequired.replace("{0}", " event sales end date"),
    }
  ]
};

const validPricing = {
  isValid: true,
  error: [],
};

const validStateObj = {
  isValid: true,
  error: [],
};

const validStateAdditionalAddOns = {
  isValid: true,
  error: [],
};
const entryServices = new EntryManagementServices();
const swalServices = new SwalServices();

function Pricing(props) {
  const [isEdit, setIsEdit] = useState(0);
  const [isAdd, setIsAdd] = useState(0);
  const [eventPricing, setEventPricing] = useState(eventPricingObj);
  const [packageList, setPackageList] = useState(eventPricingObj.entryPackage);
  const [entryPackageAddOn, setEntryPackageAddon] = useState(eventPricingObj.entryPackageAddOn);
  const [editPackageIndex, setEditPackageIndex] = useState(null);
  const [salesEndDayArr, setSalesEndDayArr] = useState([]);
  const [validPricingDetails, setValidPricingDetails] = useState(validPricing);
  const [validState, setValidState] = useState(validStateObj);
  const [validStateAddOns, setValidStateAddOns] = useState(validStateAdditionalAddOns);
  const [validationRule, setValidationRule] = useState(pricingAdditionalGuestAttendeeValidationRules);
  const [isQuantityAllow, setIsQuantityAllow] = useState(false);
  const [isCreditAllow, setIsCreditAllow] = useState(false);

  //const disablePricingDetail = false;
  //const [isDisablePricingDetail, setDisablePricingDetail] = useState(disablePricingDetail);

  useEffect(() => {
    let eventId = props.eventId;
    let eventTimeSlotId = props.eventTimeSlotId;
    setSalesEndDayArr(props.salesEndDateDays)
    // let eventId = 2;
    // let eventTimeSlotId = 1;
    if (eventId > 0) {
      getEntryPricingDetail(eventId, eventTimeSlotId);
    }
  }, []);


  const getCurrentDate = (separator = '-') => {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  }

  const isValidateGuestAttendee = () => {
    const newValidState = isValidForm(eventPricing, validationRule, validState);
    setValidState(newValidState);
    return newValidState.isValid;
  };
  const validateField = (key) => {
    const newValidState = validate(key, eventPricing, validationRule, validState);
    setValidState(newValidState);
  };

  const validateAdditionalAddOn = (addOn) => {
    let valError = [];
    let valState = { ...validStateAddOns };
    valState.isValid = true;
    let message = "";
    let field = "";
    addOn.forEach((value, key) => {
      if (value.eventAddOnType === "") {
        message = "Please enter add on name " + (key + 1);
        field = "eventAddOnType" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
      if (value.eventAddOnPrice === 0 || value.eventAddOnPrice === null) {
        message = "Please enter add on price " + (key + 1);
        field = "eventAddOnPrice" + (key + 1);
        valError[field] = message;
        valState.isValid = false;
      }
    });
    valState.error = Object.values(valError);
    setValidStateAddOns(valState);
    return valState.isValid;
  };


  const validatePrice = (priceList) => {
    let valError = [];

    let valState = { ...validPricingDetails };
    valState.isValid = true;
    let message = "";
    let field = "";
    priceList.forEach((pack, packindex) => {
      if (eventPricing.isAttendanceFree === false) {
        if (pack.eventPrice === "") {
          message = "Please enter package price for " + (pack.eventPackageName);
          field = "eventPrice" + (packindex + 1);
          valError[field] = message;
          valState.isValid = false;
        }
        if (pack.eventCredit === "") {
          message = "Please enter package credit for " + (pack.eventPackageName);
          field = "eventCredit" + (packindex + 1);
          valError[field] = message;
          valState.isValid = false;
        }
        if (pack.entryPackagePrice.length > 0) {

          pack.entryPackagePrice.forEach((price, priceIndex) => {

            if (price.earlyBirdPrice !== 0) {

              // if (price.isSuperEarlyBirdPrice === true) {
              //   message = "Please enter super early bird  price for " + (pack.eventPackageName);
              //   field = "superEarlyBirdPrice[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
              // }
              // else {
              //   message = "Please enter early bird price for " + (pack.eventPackageName);
              //   field = "earlyBirdPrice[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
              // }

              // valError[field] = message;
              // valState.isValid = false;

              if (price.promotionStartDate === "" || price.promotionStartDate === null) {
                message = "Please select promotion Start Date " + (priceIndex + 1) + " for " + (pack.eventPackageName);
                field = "promotionStartDate[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
                valError[field] = message;
                valState.isValid = false;
              }
              if (price.promotionEndDate === "" || price.promotionEndDate === null) {
                message = "Please select promotion End Date " + (priceIndex + 1) + " for " + (pack.eventPackageName);
                field = "promotionEndDate[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
                valError[field] = message;
                valState.isValid = false;
              }

              if ((price.promotionStartDate && price.promotionStartDate !== '') && (price.promotionEndDate && price.promotionEndDate !== '')) {
                let startDate = new Date(price.promotionStartDate);
                const startDateVal = Date.parse(startDate);

                let endDate = new Date(price.promotionEndDate);
                const endDateVal = Date.parse(endDate);
                if (endDateVal < startDateVal) {
                  message = `Please ensure that end date is not less then start date`;
                  field = "eventStartDate";
                  valError[field] = message;
                  valState.isValid = false;

                  // errorObj = {};
                  // errorObj['field'] = field;
                  // errorObj['message'] = message;
                  // errors.push(errorObj);
                }
                // let curdate = moment(new Date()).format("yyyy-MM-DD");
                // const currentDateVal = Date.parse(curdate);
                // if (endDateVal < currentDateVal || startDateVal < currentDateVal) {
                //   message = `Please ensure that date is not less then today's date`;
                //   field = "lessThanTodayDate";
                //   valError[field] = message;
                //   valState.isValid = false;
                // }
              }

              if (price.maxQuantity === "" || price.maxQuantity === 0) {
                message = "Please enter max Quantity " + (priceIndex + 1) + " for " + (pack.eventPackageName);
                field = "maxQuantity[" + (packindex + 1) + "][" + (priceIndex + 1) + "]";
                valError[field] = message;
                valState.isValid = false;
              }
            }

          });
        }
      }


    });
    valState.error = Object.values(valError);
    setValidPricingDetails(valState);
    return valState.isValid;
  };

  const handleRadioButtons = (e) => {
    //let value = e.target.checked;
    const id = e.target.id;
    let detail = { ...eventPricing };
    detail[id] = !detail[id];
    if (id === 'isEventAddOn' && detail.isEventAddOn === true) {
      // let data = [...entryPackageAddOn]
      // let end = (data.length - 1);
      // while (end >= 0) {
      //   data.splice(end, 1);
      //   end = end - 1;
      // }
      let addOns = [];
      addOns.push(addOnObj);
      setEntryPackageAddon(addOns);
    }
    if (id === 'isAttendanceFree' && detail.isAttendanceFree === true) {
      // let data = [...packageList]
      // let end = (data.length - 1);
      // while (end >= 0) {
      //   //if( if have pacake id then delete )
      //   data.splice(end, 1);
      //   end = end - 1;
      // }
      // setPackageList(data);
      // let arry1 = {
      //   eventPackageId: 0, eventPackageName: 'Non Member', eventPrice: 0,
      //   entryPackagePrice: [{ eventPriceId: 0, eventPackageId: 0, earlyBirdPrice: 0, isSuperEarlyBirdPrice: false, promotionStartDate: getCurrentDate("-"), promotionEndDate: getCurrentDate("-"), maxQuantity: 0 },
      //   { eventPriceId: 0, eventPackageId: 0, earlyBirdPrice: 0, isSuperEarlyBirdPrice: true, promotionStartDate: getCurrentDate("-"), promotionEndDate: getCurrentDate("-"), maxQuantity: 0 }
      //   ]
      // }
      let detail = [];
      const packageObj = {
        eventPackageId: 0,
        eventPackageName: "",
        eventPrice: 0,
        eventCredit: 0,
        eventTimeSlotId: props.eventTimeSlotId,
        entryPackagePrice: [
          {
            eventPriceId: 0,
            eventPackageId: 0,
            earlyBirdPrice: 0,
            isSuperEarlyBirdPrice: false,
            promotionStartDate: getCurrentDate("-"),
            promotionEndDate: getCurrentDate("-"),
            maxQuantity: 0
          },
          {
            eventPriceId: 0,
            eventPackageId: 0,
            earlyBirdPrice: 0,
            isSuperEarlyBirdPrice: true,
            promotionStartDate: getCurrentDate("-"),
            promotionEndDate: getCurrentDate("-"),
            maxQuantity: 0
          }
        ]
      }
      props.startupMember.forEach((pkgName, pkgIndex) => {
        let pkgToAdd = { ...packageObj };
        if (pkgName.label !== 'Others') {
          pkgToAdd.eventPackageName = pkgName.label;
          pkgToAdd.entryPackagePrice[0].eventPackageNameForBirdPrice = pkgName.label;
          pkgToAdd.entryPackagePrice[1].eventPackageNameForBirdPrice = pkgName.label;
          detail.push(pkgToAdd);
        }
      });

      // data.push(arry1);
      // setPackageList(data);
      setPackageList(detail)
      setIsAdd(-1);
      setIsEdit(0);
      setEditPackageIndex(null);
    }
    // else {
    //   let data = [...packageList]
    //   let end = (data.length - 1);
    //   while (end >= 0) {
    //     data.splice(end, 1);
    //     end = end - 1;
    //   }
    //   setPackageList(data);
    //   let arry = {
    //     eventPackageId: 0, eventPackageName: 'Member', eventPrice: 0,
    //     entryPackagePrice: [{ eventPriceId: 0, eventPackageId: 0, earlyBirdPrice: 0, isSuperEarlyBirdPrice: false, promotionStartDate: '', promotionEndDate: '', maxQuantity: 0 },
    //     { eventPriceId: 0, eventPackageId: 0, earlyBirdPrice: 0, isSuperEarlyBirdPrice: true, promotionStartDate: '', promotionEndDate: '', maxQuantity: 0 }
    //     ]
    //   }
    //   data.push(arry);
    //   setPackageList(data);
    // }
    setEventPricing(detail);

  };

  const handleDropdownChange = (e, name) => {
    //let name = e.target.name ;
    let value = e.value;
    let detail = { ...eventPricing };
    detail[name] = value;
    // if (name === "additionalGuestCorporate") {
    //   validateField("additionalGuestCorporate");
    // }
    // else 
    if (name === "additionalGuestIndividual") {
      validateField("additionalGuestIndividual");
    }
    else if (name === "eventSalesEndDate") {

      validateField("eventSalesEndDate");
    }
    setEventPricing(detail);
  };





  const handleChangeInDropdown = (e) => {
    let detail = [...packageList];
    if (e.value > 0) {
      let arry = {
        eventPackageId: 0,
        eventPackageName: e.label,
        eventPrice: 0,
        eventCredit: 0,
        entryPackagePrice: [
          {
            eventPriceId: 0,
            eventPackageId: 0,
            earlyBirdPrice: 0,
            earlyBirdCredit: 0,
            isSuperEarlyBirdPrice: false,
            promotionStartDate: '',
            promotionEndDate: '',
            maxQuantity: 0
          },
          {
            eventPriceId: 0,
            eventPackageId: 0,
            earlyBirdPrice: 0,
            earlyBirdCredit: 0,
            isSuperEarlyBirdPrice: true,
            promotionStartDate: '',
            promotionEndDate: '',
            maxQuantity: 0
          }
        ]
      }
      let packageObject = detail.find(x => x.eventPackageName === e.label);
      if (packageObject) {
        swalServices.Alert("Package already exists.");
      }
      else {
        detail.push(arry);
      }
      setPackageList(detail);
      setIsAdd(-1);
    }
  }

  const handleChange = (e, key, identity) => {
    const { name, value } = e.target;
    let data = [...packageList];
    let detail = { ...data[key] };

    if (name === "eventPrice" || name === "eventCredit") {
      const value = e.target.value;
      const convertedValue = convertToDecimal(value);
      detail[name] =
        convertedValue || convertedValue === 0 ? convertedValue : value;
    } else if (name === "eventPackageName") {
      if (detail.eventPackageName === "Others") {
        detail.eventPackageName = value === "Others" ? "" : value;
      } else {
        detail.eventPackageName = value;
      }
    }

    data[key] = detail;
    setPackageList(data);
  };

  const handlePriceChange = (e, key, packageName) => {
    let value = e.target.value;
    // const id = e.target.id;
    let name = e.target.name;
    const nameArr = name.split("_");
    const index = nameArr[0] === 'EarlyBirdPrice' ? 0 : 1;
    name = nameArr[1];

    let packageIndex = packageList.findIndex(x => x.eventPackageName === packageName);
    let mainPack = [...packageList];
    let singlePack = { ...mainPack[packageIndex] };
    let birdArr = [...singlePack.entryPackagePrice];



    if (packageIndex !== -1) {

      switch (nameArr[1]) {
        case 'earlyBirdPrice':
          if ((value = convertToDecimal(value)) || value === 0) {

            birdArr[index] = {
              ...birdArr[index],
              [name]: value,
            };
          }

          break;
        case 'earlyBirdCredit':
          if ((value = convertToDecimal(value)) || value === 0) {

            birdArr[index] = {
              ...birdArr[index],
              [name]: value,
            };
          }

          break;
        case 'maxQuantity':
          value = (!value || value === '') ? null : value;
          value = isNaN(value) ? 0 : Number(value);

          birdArr[index] = {
            ...birdArr[index],
            [name]: (value < 0) ? 0 : value,
          };
          break;
        default:
          birdArr[index] = {
            ...birdArr[index],
            [name]: (value < 0) ? 0 : value,
          };
          break;
      }
      mainPack[packageIndex] = { ...mainPack[packageIndex], entryPackagePrice: birdArr };
      setPackageList([...mainPack]);
    } else {

      setPackageList([...mainPack]);
    }
  }

  // const handleChangeDelete = (key,pacakeId) => {
  //   // let data = [...packageList];   
  //   // if(pacakeId === 0){
  //   //   deleteEntryPricingPackage(pacakeId);
  //   // }
  //   // data.splice(key, 1);
  //   // setPackageList(data);
  // }

  const deleteEntryPricingPackage = (key, packageId) => {
    let data = [...packageList];
    if (packageId > 0) {
      //let request = eventPricing;
      // request.eventId = props.eventId;
      // request.eventTimeSlotId = props.eventTimeSlotId;
      // request.entryPackage.eventPackageId = packageId;
      let request = {
        eventId: 1,
        eventTimeSlotId: 1,
        entryPackage: []
      };
      request.entryPackage.eventPackageId = 1;
      entryServices.deleteEntryPricingPackage(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorType === 'NOTFOUND') {
            swalServices.Error("Package data is NOT FOUND.");
          } else {
            swalServices.Success("Package data deleted successfully.");
            data.splice(key, 1);
            setPackageList(data);
          }
        }
        else {
          swalServices.Error(response.message);
        }
      });
    } else {
      data.splice(key, 1);
      setPackageList(data);
    }
  }

  const getEntryPricingDetail = (eventId, eventTimeSlotId) => {
    let request = {
      eventId: props.eventId,
      eventTimeSlotId: 0
    }
    entryServices.getEntryPricingDetail(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent !== null) {
          let eventPricingData = {}
          eventPricingData.eventId = response.responseItem.responseContent.eventId;
          eventPricingData.eventTimeSlotId = response.responseItem.responseContent.eventTimeSlotId;
          eventPricingData.isAttendanceFree = response.responseItem.responseContent.isAttendanceFree ? response.responseItem.responseContent.isAttendanceFree : false;
          eventPricingData.eventSalesEndDate = response.responseItem.responseContent.eventSalesEndDate ? response.responseItem.responseContent.eventSalesEndDate : 1;
          eventPricingData.additionalGuestCorporate = response.responseItem.responseContent.additionalGuestCorporate;
          eventPricingData.additionalGuestIndividual = response.responseItem.responseContent.additionalGuestIndividual + 1;
          eventPricingData.isEventAddOn = response.responseItem.responseContent.isEventAddOn ? response.responseItem.responseContent.isEventAddOn : false;
          //eventPricingData.isQuantityAllow = response.responseItem.responseContent.isQuantityAllow ? response.responseItem.responseContent.isQuantityAllow : false;
          setIsQuantityAllow(response.responseItem.responseContent.isQuantityAllow ? response.responseItem.responseContent.isQuantityAllow : false);

          // Akshay : To do : Uncomment bellow code while Credit Allow
          // setIsCreditAllow(response.responseItem.responseContent.isCreditAllow ? response.responseItem.responseContent.isCreditAllow : false);
          setIsCreditAllow(false);

          // let data = response.responseItem.responseContent;
          //let addEventPricing = {...eventPricing};
          setEventPricing(eventPricingData);
          if (eventPricingData.isAttendanceFree === false) {
            let addPackage = {};
            //addPackage.splice(0, 1);
            addPackage = response.responseItem.responseContent.entryPackage;
            if (response.responseItem.responseContent.entryPackage.length > 0) {
              setPackageList(addPackage);
            } else {
              let detail = [];
              const packageObj = {
                eventPackageId: 0,
                eventPackageName: "",
                eventPrice: 0,
                eventCredit: 0,
                eventTimeSlotId: props.eventTimeSlotId,
                entryPackagePrice: [
                  {
                    eventPriceId: 0,
                    eventPackageId: 0,
                    earlyBirdPrice: 0,
                    earlyBirdCredit: 0,
                    isSuperEarlyBirdPrice: false,
                    promotionStartDate: getCurrentDate("-"),
                    promotionEndDate: getCurrentDate("-"),
                    maxQuantity: 0
                  },
                  {
                    eventPriceId: 0,
                    eventPackageId: 0,
                    earlyBirdPrice: 0,
                    earlyBirdCredit: 0,
                    isSuperEarlyBirdPrice: true,
                    promotionStartDate: getCurrentDate("-"),
                    promotionEndDate: getCurrentDate("-"),
                    maxQuantity: 0
                  }
                ]
              }
              props.startupMember.forEach((pkgName, pkgIndex) => {
                let pkgToAdd = { ...packageObj };
                if (pkgName.label !== 'Others') {
                  pkgToAdd.eventPackageName = pkgName.label;
                  pkgToAdd.entryPackagePrice[0].eventPackageNameForBirdPrice = pkgName.label;
                  pkgToAdd.entryPackagePrice[1].eventPackageNameForBirdPrice = pkgName.label;
                  detail.push(pkgToAdd);
                }
              });
              setPackageList(detail);
            }
          } else {
            let detail = [];
            const packageObj = {
              eventPackageId: 0,
              eventPackageName: "",
              eventPrice: 0,
              eventTimeSlotId: props.eventTimeSlotId,
              entryPackagePrice: [
                {
                  eventPriceId: 0,
                  eventPackageId: 0,
                  earlyBirdPrice: 0,
                  earlyBirdCredit: 0,
                  isSuperEarlyBirdPrice: false,
                  promotionStartDate: getCurrentDate("-"),
                  promotionEndDate: getCurrentDate("-"),
                  maxQuantity: 0
                },
                {
                  eventPriceId: 0,
                  eventPackageId: 0,
                  earlyBirdPrice: 0,
                  earlyBirdCredit: 0,
                  isSuperEarlyBirdPrice: true,
                  promotionStartDate: getCurrentDate("-"),
                  promotionEndDate: getCurrentDate("-"),
                  maxQuantity: 0
                }
              ]
            }
            props.startupMember.forEach((pkgName, pkgIndex) => {
              let pkgToAdd = { ...packageObj };
              if (pkgName.label !== 'Others') {
                pkgToAdd.eventPackageName = pkgName.label;
                pkgToAdd.entryPackagePrice[0].eventPackageNameForBirdPrice = pkgName.label;
                pkgToAdd.entryPackagePrice[1].eventPackageNameForBirdPrice = pkgName.label;
                detail.push(pkgToAdd);
              }
            });
            setPackageList(detail);
          }
          if (eventPricingData.isEventAddOn === true) {
            let addEventAddOn = [...entryPackageAddOn];
            addEventAddOn.splice(0, 1);
            addEventAddOn = response.responseItem.responseContent.entryPackageAddOn;
            setEntryPackageAddon(addEventAddOn);
          }
        }
      }
      else {
        swalServices.Error(response.message);
      }
    });
  };

  const addEditEntryPricingDetail = (redirectToNext) => {

    setValidState(validStateObj);
    setValidPricingDetails(validStateObj);
    setValidStateAddOns(validStateObj);

    let isValidAddOn = true;
    if (eventPricing.isEventAddOn === true) {
      isValidAddOn = validateAdditionalAddOn([...entryPackageAddOn]);
    }
    // let isValidPricing = validatePrcingDetails([...packageList]);

    let isValidPricing = validatePrice([...packageList]);


    let isValidGuestAttendee = isValidateGuestAttendee();

    if (isValidAddOn && isValidGuestAttendee && isValidPricing) {

      let request = { ...eventPricing };
      request.eventId = props.eventId;
      request.eventTimeSlotId = eventPricing.eventTimeSlotId;
      request.isAttendanceFree = eventPricing.isAttendanceFree;
      request.eventSalesEndDate = eventPricing.eventSalesEndDate;
      request.additionalGuestCorporate = 0;
      request.additionalGuestIndividual = eventPricing.additionalGuestIndividual - 1;
      request.isEventAddOn = eventPricing.isEventAddOn;
      request.isQuantityAllow = isQuantityAllow;

      // Akshay : To do : Uncomment bellow code while Credit Allow
      // request.isCreditAllow = isCreditAllow;
      request.isCreditAllow = false // remove this for cerdit

      request.entryPackage = [...packageList];
      request.entryPackageAddOn = [...entryPackageAddOn];
      entryServices.addEditEntryPricingDetail(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {


          if (redirectToNext === 0) {
            swalServices.Success("Pricing data added successfully.");
          }
          else {
            props.nextToPricing();
            props.executeScroll();
          }
        } else {
          swalServices.Error(response.message);
        }
      });
    }
  };

  const handleInputChange = (e, index) => {
    let { name, value } = e.target;
    let list = [...entryPackageAddOn];
    if (name === 'eventAddOnPrice') {
      // value = (!value || value === '') ? null : value;
      // value = isNaN(value) ? 0 : Number(value);
      // list[index][name] = (value < 0) ? 0 : value;
      if ((value = convertToDecimal(value)) || value === 0) {
        list[index][name] = value;
      }
    }
    else {
      list[index][name] = value;
    }
    setEntryPackageAddon(list);
  };

  const handleRemoveClick = index => {
    let list = [...entryPackageAddOn];
    list.splice(index, 1);
    setEntryPackageAddon(list);
  };

  const handleAddClick = () => {
    let detail = [...entryPackageAddOn];
    let arry = {
      eventAddOnPriceId: 0,
      eventId: 0,
      eventAddOnType: "",
      eventAddOnPrice: 0
    }
    detail.push(arry);
    setEntryPackageAddon(detail);
  }
  // const handleAddClick = () => {
  //   setEntryPackageAddon([...entryPackageAddon, { eventAddOnPriceId : 0, eventId: 0, eventAddOnType: 0, eventAddOnPrice:0 }]);
  // };

  return (
    <div>
      {
        validPricingDetails.error.length > 0 ||
          validState.isValid === false ||
          validStateAddOns.error.length > 0 ?
          (
            <div className="bg-white" id="ErrorList">
              <div className="heading-part pl-7 py-4 border-b mb-5">
                <div className="flex items-center justify-between">
                  <h4 className="small-title font-bold theme-color">
                    The following field(s) are required:
                  </h4>
                </div>
                <ul>
                  {validPricingDetails.error
                    ? validPricingDetails.error.map((e, key) => {
                      return <li key={key} className=" text-[#c00000]">{e}</li>;
                    })
                    : null}
                  {/* <li className=" text-[#c00000]">{validState.error.additionalGuestCorporate}</li> */}
                  <li className=" text-[#c00000]">{validState.error.additionalGuestIndividual}</li>
                  <li className=" text-[#c00000]">{validState.error.eventSalesEndDate}</li>

                  {validStateAddOns.error
                    ? validStateAddOns.error.map((e, key) => {
                      return <li key={key} className=" text-[#c00000]">{e}</li>;
                    })
                    : null}


                </ul>
              </div>
            </div>
          ) : null
      }
      <div className="bg-white">
        <div className="heading-part pl-7 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">Pricing</h4>
          </div>
        </div>
        <div className="px-9 py-7">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className=" col-span-7 w-full">
                      <div className="flex flex-col justify-start">
                        <div className="my-3">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="radio-buttons-isAttendanceFree"
                              id="isAttendanceFree"
                              className="form-radio"
                              onChange={(event) => handleRadioButtons(event)}
                              value={eventPricing.isAttendanceFree}
                              checked={eventPricing.isAttendanceFree === true}
                            />
                            <span className="text-xl  font-medium theme-color ml-2">
                              Attendance is free
                            </span>
                          </label>
                        </div>

                        <div className="my-3">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="radio-buttons-isAttendanceFree"
                              id="isAttendanceFree"
                              className="form-radio"
                              onChange={(event) => handleRadioButtons(event)}
                              value={eventPricing.isAttendanceFree}
                              checked={eventPricing.isAttendanceFree === false}
                            />
                            <span className="text-xl  font-medium theme-color ml-2">
                              There is a cost to attend
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pricing End */}
      {/* Pricing Detail Start */}
      <div className="bg-white mt-12 pb-1">
        <div className="heading-part pl-7 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Pricing Details
            </h4>
            {/* Akshay : To do : Uncomment bellow code while Credit Allow */}
            {/* <div className="2xl:col-span-4 lg:col-span-4 col-span-12 flex justify-end pr-10">
              <div className="check-box-sec flex items-center">
                <input
                  id="isCreditAllow"
                  name="isCreditAllow"
                  type="checkbox"
                  className="form-checkbox"
                  checked={isCreditAllow}
                  value={isCreditAllow}
                  onChange={(e) => setIsCreditAllow(e.target.checked)}
                />
                <span className="filter-lable font-bold text-[#757575] pl-4">
                  Credit Allow
                </span>
              </div>
            </div> */}
          </div>
        </div>

        <div className="m-8 mb-7 border border-[#757575]">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="px-7 py-4 pricing-details-title-part border-b border-[#757575]">
                <div className="grid grid-cols-12 gap-6">
                  <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                    <span className="text-xl font-medium theme-color">
                      Package
                      <span className="text-[#C00000]">*</span>
                    </span>
                  </div>
                  <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                    <span className="text-xl font-medium theme-color">
                      Price
                      <span className="text-[#C00000]">*</span>
                    </span>
                  </div>
                  <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                    <span className="text-xl font-medium theme-color">
                      Credit
                      <span className="text-[#C00000]">*</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="pricing-details">
                <div className="p-7 pb-5">
                  {packageList.map((pack, key) => (
                    <>
                      <div className="grid grid-cols-12 gap-6 mb-6 relative z-10" key={`packageMainSection${key}`}>
                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                          {pack.eventPackageName === "Non Member" ||
                            pack.eventPackageName === "Ordinary Member" ||
                            pack.eventPackageName === "Qualifying  Member" ||
                            pack.eventPackageName === "Associate  Member" ||
                            pack.eventPackageName === "Others"
                            ?
                            <input
                            className={pack.eventPackageName === "Others" ? "bg-[#fff] w-full" : "bg-[#e3e3e3] w-full" }
                            type="text"
                            id={`eventPackageName_${key}`}
                            name="eventPackageName"
                            value={pack.eventPackageName}
                            onChange={(e) => handleChange(e, key)}
                            disabled={pack.eventPackageName !== "Others"}
                          />
                            :
                            <input
                              className={"bg-[#fff] w-full color-[#000]"}
                              type="text"
                              id={`eventPackageName_${key}`}
                              name="eventPackageName"
                              value={
                                pack.eventPackageName === "Others"
                                  ? ""
                                  : pack.eventPackageName
                              }
                              onChange={(e) => handleChange(e, key)}
                              disabled={false}
                            />
                          }

                        </div>
                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12 settings-section">
                          <div className="sign-icon-input">
                            <div className="relative dollar-input">
                              <div className="sign-sec">
                                <input
                                  className={`w-full py-3 text-lg ${eventPricing.isAttendanceFree ? "bg-[#e3e3e3]" : null}`}
                                  id={`eventBasePrice_${key}`}
                                  type="text"
                                  name="eventPrice"
                                  value={pack.eventPrice}
                                  onChange={(e) => handleChange(e, key)}
                                  disabled={eventPricing.isAttendanceFree}
                                //onBlur={(e) => props.onBlur(props.identity)}                                
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                          <div className="grid grid-cols-12 gap-6 items-center">
                            <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                              <input
                                className={`w-full py-3 text-lg ${eventPricing.isAttendanceFree || (isCreditAllow === true ? null : "bg-[#e3e3e3]") ? "bg-[#e3e3e3]" : null}`}
                                id={`eventBasePrice_${key}`}
                                type="text"
                                name="eventCredit"
                                value={pack.eventCredit}
                                onChange={(e) => handleChange(e, key)}
                                disabled={!(eventPricing.isAttendanceFree || isCreditAllow === true)}
                              //onBlur={(e) => props.onBlur(props.identity)}                                
                              />
                            </div>

                            <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                              <div className="setting-delete-button">
                                {eventPricing.isAttendanceFree ?
                                  null
                                  :
                                  <div className="flex items-center justify-around relative">
                                    <button
                                      className={`action-btn right-icon btn-settings ${isEdit === key + 1 ? "active-btn" : ""
                                        }`}
                                      // className="action-btn right-icon active-btn"
                                      onClick={() => { setIsEdit(key + 1); setEditPackageIndex(key) }}
                                    >
                                      <SettingsButtonIcon />
                                    </button>
                                    <button
                                      className={`action-btn right-icon btn-settings ${isEdit === key + 1 ? "ml-[103px]" : ""
                                        }`}
                                      // onClick={() =>
                                      //   props.deleteAgencyOfficer(
                                      //     officer.agencyOfficerId
                                      //   )
                                      // }
                                      onClick={() => deleteEntryPricingPackage(key, pack.eventPackageId)}
                                    >
                                      <DeleteButtonIcon />
                                    </button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isEdit === key + 1 ? (
                        <div className="grid grid-cols-12 gap-6 mb-6 relative" key={`DiscountSection${key}`}>
                          <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                            <div className="bg-[#f0f0f1] p-8 w-full settings-section">
                              <div className="grid grid-cols-12 gap-6 pb-10" key={`EarlyBirdSection${key}`}>
                                <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                  {/* {packageList.entryPackagePrice.isSuperEarlyBirdPrice === false} */}
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`earlyBirdPrice${key}`}
                                  >
                                    Early Bird:
                                  </label>
                                  <div className="sign-icon-input">
                                    <div className="relative dollar-input">
                                      <div className="sign-sec">
                                        <input
                                          className="w-full py-3 text-lg"
                                          name="EarlyBirdPrice_earlyBirdPrice"
                                          type="text"
                                          id={`earlyBirdPrice${key}`}
                                          value={pack.entryPackagePrice[0].earlyBirdPrice}
                                          onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`earlyBirdCredit${key}`}
                                  >
                                    Credit:
                                  </label>
                                  <input
                                    className={`w-full py-3 text-lg ${eventPricing.isAttendanceFree || (isCreditAllow === true ? null : "bg-[#e3e3e3]") ? "bg-[#e3e3e3]" : null}`}
                                    name="EarlyBirdPrice_earlyBirdCredit"
                                    type="text"
                                    id={`earlyBirdCredit${key}`}
                                    value={pack.entryPackagePrice[0].earlyBirdCredit}
                                    onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                    disabled={!(eventPricing.isAttendanceFree || isCreditAllow === true)}
                                  />
                                </div>
                                <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`promotionStartDate${key}`}
                                  >
                                    Promotion Start Date:
                                    {/* <span className="text-[#C00000]">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    name="EarlyBirdPrice_promotionStartDate"
                                    id={`promotionStartDate${key}`}
                                    value={moment(pack.entryPackagePrice[0].promotionStartDate).format("yyyy-MM-DD")}
                                    onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                  />
                                </div>
                                <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`promotionEndDate${key}`}
                                  >
                                    Promotion End Date:
                                    {/* <span className="text-[#C00000]">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    name="EarlyBirdPrice_promotionEndDate"
                                    id={`promotionEndDate${key}`}
                                    value={moment(pack.entryPackagePrice[0].promotionEndDate).format("yyyy-MM-DD")}
                                    onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                  />
                                </div>
                                <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`maxQuantity${key}`}
                                  >
                                    Max Quantity:
                                    {/* <span className="text-[#C00000]">*</span> */}
                                  </label>
                                  <input
                                    type="text"
                                    className="w-full py-3 text-lg"
                                    id={`maxQuantity${key}`}
                                    name="EarlyBirdPrice_maxQuantity"
                                    onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                    value={pack.entryPackagePrice[0].maxQuantity}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-12 gap-6 pt-7 border-t border-[#757575]" key={`SuperEarlyBirdSection${key}`}>
                                <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`superEarlyBirdPrice${key}`}
                                  >
                                    Super Early Bird:
                                    {/* <span className="text-[#C00000]">*</span> */}
                                  </label>
                                  <div className="sign-icon-input">
                                    <div className="relative dollar-input">
                                      <div className="sign-sec">
                                        <input
                                          className="w-full py-3 text-lg"
                                          type="text"
                                          id={`superEarlyBirdPrice${key}`}
                                          name="SuperEarlyBird_earlyBirdPrice"
                                          //value={eventPricing.entryPackage[key].entryPackagePrice[1].earlyBirdPrice}
                                          value={pack.entryPackagePrice[1].earlyBirdPrice}
                                          onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                        //onBlur={(e) => props.onBlur(props.identity)}                                
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`superMaxQuantity${key}`}
                                  >
                                    Credit:
                                  </label>
                                  <input
                                    type="text"
                                    className={`w-full py-3 text-lg ${eventPricing.isAttendanceFree || (isCreditAllow === true ? null : "bg-[#e3e3e3]") ? "bg-[#e3e3e3]" : null}`}
                                    id={`superMaxQuantity${key}`}
                                    name="SuperEarlyBird_earlyBirdCredit"
                                    value={pack.entryPackagePrice[1].earlyBirdCredit}
                                    onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                    disabled={!(eventPricing.isAttendanceFree || isCreditAllow === true)}
                                  />
                                </div>
                                <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`superPromotionStartDate${key}`}
                                  >
                                    Promotion Start Date:
                                    {/* <span className="text-[#C00000]">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    id={`superPromotionStartDate${key}`}
                                    name="SuperEarlyBird_promotionStartDate"
                                    value={moment(pack.entryPackagePrice[1].promotionStartDate).format("yyyy-MM-DD")}
                                    onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                  />
                                </div>
                                <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`superPromotionEndDate${key}`}
                                  >
                                    Promotion End Date:
                                    {/* <span className="text-[#C00000]">*</span> */}
                                  </label>
                                  <input
                                    type="date"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    id={`superPromotionEndDate${key}`}
                                    name="SuperEarlyBird_promotionEndDate"
                                    value={moment(pack.entryPackagePrice[1].promotionEndDate).format("yyyy-MM-DD")}
                                    onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                  />
                                </div>
                                <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                  <label
                                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                    htmlFor={`superMaxQuantity${key}`}
                                  >
                                    Max Quantity:
                                    {/* <span className="text-[#C00000]">*</span> */}
                                  </label>
                                  <input
                                    type="text"
                                    className="w-full py-3 text-lg"
                                    id={`superMaxQuantity${key}`}
                                    name="SuperEarlyBird_maxQuantity"
                                    value={pack.entryPackagePrice[1].maxQuantity}
                                    onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-12 gap-6 pt-10">
                                <div className="2xl:col-span-12 lg:col-span-12 col-span-12 text-center">
                                  <button
                                    className="text-xl text-[#81a3b8]"
                                    onClick={() => setIsEdit(0)}
                                  >
                                    Minimise settings
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))
                  }
                  {isAdd === +1 ? (
                    <div className="grid grid-cols-12 gap-6 mt-6 mb-6">
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                        <Selector
                          options={props.startupMember}
                          // isMulti
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          // components={{
                          //   Option,
                          // }}
                          onChange={(e) => handleChangeInDropdown(e)}
                          allowSelectAll={false}
                          //value={packageList.value}
                          drpIdentity="startupMember"
                        />
                        {/* <SingleSelectWithAddNewData
                          drpIdentity={"startupMember"}
                          optionArray={props.startupMember}
                          setFilterParameters={setFilterParametersTest()}
                          value={newPackageNameList}
                          handleChangeTest={(e) => handleChangeTest(e)}
                          mainModulesTestSelectedName={newPackageName}
                        /> */}
                        {/* <TextInput
                          className={"bg-[#e3e3e3]"}
                          type="Text"
                          id={`eventPackageName_`}
                          name="eventPackageName"
                          value={newPackageName}
                          onChange={(e) => handleChange(e, key)}
                          disabled={true}
                        /> */}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="add-buttons">
                <div className="grid grid-cols-12 gap-6 border-t border-[#757575]">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="flex items-center justify-end p-8">
                      <button
                        className={` px-10 py-3 text-xl border text-white ${eventPricing.isAttendanceFree ? "bg-[#e3e3e3]" : "btn btn-red"}`}
                        // onClick={() => props.addNewDec()}
                        onClick={() => setIsAdd(+1)}
                        disabled={eventPricing.isAttendanceFree}
                      >
                        <div className="flex items-center font-semibold">
                          <span className="mr-3">
                            <img src="../plus-round-icon.svg" alt=""></img>
                          </span>
                          <span className="">Add Package</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="m-8 mb-7 mt-20">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
              <label
                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                htmlFor="default"
              >
                Sales End Date
                {/* <span className="text-[#C00000]">*</span> */}
              </label>
              <div className="grid grid-cols-12 gap-6 items-center">
                <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                  <Selector
                    options={salesEndDayArr}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    // }}
                    onChange={(e) => handleDropdownChange(e, 'eventSalesEndDate')}
                    allowSelectAll={false}
                    value={salesEndDayArr.filter(x => x.value === eventPricing.eventSalesEndDate)}
                    drpIdentity="eventSalesEndDate"
                    isNumberSelect={true}
                  />
                </div>
                <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                  <label
                    className="filter-lable block font-medium theme-color min-h-30"
                    htmlFor="default"
                  >
                    day(s)
                    {/* <span className="text-[#C00000]">*</span> */}
                  </label>
                </div>
              </div>
            </div>
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 my-4">
              <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                <p
                  className="filter-lable block font-medium theme-color min-h-30"
                  htmlFor="default"
                >
                  Booking period for this entry ends X days before start of
                  course/event as indicated above.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pricing Detail End */}

      {/* Additional Guest Start */}
      <div className="bg-white mt-12 pb-1">
        <div className="heading-part pl-7 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Additional Guest/Attendee
            </h4>
          </div>
        </div>
        <div className="m-8 mt-10 mb-12">
          <div className="grid grid-cols-12 gap-6 mt-10">
            <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
              <label
                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                htmlFor="default"
              >
                For Individual
                {/* <span className="text-[#C00000]">*</span> */}
              </label>
              <div className="grid grid-cols-12 gap-6 items-center pl-6">
                <div className="2xl:col-span-5 lg:col-span-5 col-span-12">
                  <label
                    className="filter-lable block font-medium theme-color min-h-30"
                    htmlFor="default"
                  >
                    Max guest
                    {/* <span className="text-[#C00000]">*</span> */}
                  </label>
                </div>
                <div className="2xl:col-span-7 lg:col-span-7 col-span-12">
                  <Selector
                    options={props.maxGuest}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    // }}
                    onChange={(e) => handleDropdownChange(e, 'additionalGuestIndividual')}
                    allowSelectAll={false}
                    //value={eventPricing.additionalGuestIndividual}
                    //value={props.maxGuest.filter(x => x.value === eventPricing.additionalGuestIndividual)}
                    value={props.maxGuest.find((item) => item.value === eventPricing.additionalGuestIndividual)}
                    drpIdentity="additionalGuestIndividual"
                    isNumberSelect={true}
                  />
                </div>
              </div>
            </div>
            <div className="2xl:col-span-4 lg:col-span-4 col-span-12 flex justify-end">
              <div className="check-box-sec flex items-center">
                <input
                  id="isQuantityAllow"
                  name="isQuantityAllow"
                  type="checkbox"
                  className="form-checkbox"
                  checked={isQuantityAllow}
                  value={isQuantityAllow}
                  onChange={(e) => setIsQuantityAllow(e.target.checked)}
                />
                <span className="filter-lable font-bold text-[#757575] pl-4">
                  Quantity Allow
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Additional Guest End */}
      {/* Additional Add-Ons Start */}
      <div className="bg-white mt-12">
        <div className="heading-part pl-7 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Additional Add-Ons
            </h4>
          </div>
        </div>
        <div className="px-9 py-0">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className=" col-span-7 w-full">
                      <div className="flex flex-col justify-start">
                        <div className="my-3">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="rdo-btn"
                              id="isEventAddOn"
                              className="form-radio"
                              onChange={(event) => handleRadioButtons(event)}
                              value={eventPricing.isEventAddOn}
                              checked={eventPricing.isEventAddOn === false}
                            />

                            <span className="text-xl  font-medium theme-color ml-2">
                              No additional fee
                            </span>
                          </label>
                        </div>

                        <div className="my-3">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="rdo-btn"
                              id="isEventAddOn"
                              className="form-radio"
                              onChange={(event) => handleRadioButtons(event)}
                              value={eventPricing.isEventAddOn}
                              checked={eventPricing.isEventAddOn === true}
                            />
                            <span className="text-xl  font-medium theme-color ml-2">
                              There is additional fee
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <span className="text-xl  font-medium theme-color ml-2">
                    These options will be displayed when they make the booking:
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {
          eventPricing.isEventAddOn === true ?
            //eventPricingObj.isEventAddOn && eventPricingObj.isEventAddOn === true ?
            (
              <div className="px-10 pb-5">

                {entryPackageAddOn.map((x, i) => {
                  return (
                    <>
                      <div className="grid grid-cols-4 gap-4 mt-0 py-2" key={`AddOnSection_${i}`}>
                        <div className=''>
                          <div className="personal-info 2xl:col-span-4 lg:col-span-6 md:col-span-12 col-span-12">
                            <div className="w-full custom-inputs">

                              <TextInput
                                handleChange={e => handleInputChange(e, i)}
                                name="eventAddOnType"
                                value={x.eventAddOnType}
                              // validateField={props.validateField}
                              // validState={props.validState}
                              />

                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="personal-info 2xl:col-span46 lg:col-span-6 md:col-span-12 col-span-12">
                            <div className="w-full custom-inputs">
                              <div className="sign-icon-input">
                                <div className="relative dollar-input">
                                  <div className="sign-sec">
                                    <TextInput
                                      handleChange={e => handleInputChange(e, i)}
                                      name="eventAddOnPrice"
                                      value={x.eventAddOnPrice}
                                      type="text"
                                    // validateField={props.validateField}
                                    // validState={props.validState}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {i > 0 ?
                          <div className="flex items-center justify-between">
                            {entryPackageAddOn.length !== 1 && <button
                              className="btn btn-red text-white text-[16px] p-2"
                              onClick={() => handleRemoveClick(i)}>- Delete Add-On</button>}
                            {/* {addAdditionalAddOn.length - 1 === i && <button onClick={handleAddClick}>+ Add Add-On </button>} */}
                          </div>
                          : null}

                      </div>
                    </>
                  );
                })}
                <div className="flex justify-start my-2">
                  <button
                    className="flex items-center justify-center p-2 text-white btn btn-red"
                    onClick={handleAddClick}
                  >
                    <p className="text-[18px]">+ Add Add-On</p>
                  </button>
                </div>
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <span className="text-xl  font-medium theme-color ml-2">
                    These options will be displayed when they make the booking:
                  </span>
                </div>
              </div>
            )
            :
            null
        }
      </div>
      {/* Additional Add-Ons End */}
      {/* footer Button Start*/}
      <div className="grid grid-cols-12 gap-6">
        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">

          <div className="flex flex-wrap justify-between space-x-10 mt-12">
            <div>
              <button className="w-32 px-7 py-2 btn-sm btn btn-gray text-lg text-white" onClick={() => { props.backFromPricing(); props.executeScroll(); }}>Back</button>
            </div>
            <div>
              {/* {props.resource.isPage ? ( */}
              <button
                className="w-32 px-7 py-2 mr-12 btn-sm btn btn-red text-lg text-white"
                onClick={() => { addEditEntryPricingDetail(0); props.executeScroll(); }}
              // onClick={() => {=
              //   props.isValidateAllBasicInformation();
              //   goToViolation("ErrorList");
              // }}
              // disabled={props.isLoaderEnabled}
              >
                Save
              </button>
              {/* ) : null} */}

              <button
                className="w-32 px-7  py-2 btn-sm btn btn-lighblue text-lg text-white"
                // onClick={() => addEditEntryPricingDetail()}
                onClick={() => {
                  addEditEntryPricingDetail(1); props.executeScroll();
                }
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* footer Button End*/}
    </div >
  );
}
export default Pricing;