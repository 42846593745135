import React, { Component } from 'react'
import SwalServices from '../../../services/swalServices/SwalServices';
import LeadershipPositionsServices from '../../../services/axiosServices/apiServices/LeadershipPositionsServices';
import { Navigate } from 'react-router-dom';
import ValidationText from '../../../utils/validation/ValidationText';
import { isValidForm, validate } from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from '../../../utils/Messages';
import ImageCropperWithUpload from '../../../components/ImageUpload/UploadImage';
import CommonServices from '../../../services/axiosServices/apiServices/CommonServices';
import WebsiteCmsServices from '../../../services/axiosServices/apiServices/WebsiteCmsServices';

export default class AddFeaturedNewMember extends Component {
    constructor(props) {
        super(props)
        this.LeadershipPositionsServices = new LeadershipPositionsServices();
        this.websiteCmsServices = new WebsiteCmsServices();
        this.commonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.state = {
            redirect: null,
            featuredNewMemberDetails: {
                memberName: '',
                logoImagePath: ''
            },
            validationRule: {
                memberName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'Name'),
                    },
                ],
                logoImagePath: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.UploadPdfRequired.replace('{0}', 'Logo Image')
                    }
                ],
            },
            validState: {
                isValid: true,
                error: {}
            },
        }
    }

    back = () => {
        this.setState({ redirect: "/ManageFeaturedNewMembers" });
    }

    addFeaturedNewMemberLogoToWeb = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields === true) {
            this.websiteCmsServices.addFeaturedNewMemberLogoToWeb(this.state.featuredNewMemberDetails).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Logo inserted successfully.");
                    this.setState({ redirect: "/ManageFeaturedNewMembers" });
                } else {
                    this.swalServices.Error(response.message);
                }
            })
        }
    }

    validateField = (key) => {
        const newValidState = validate(key, this.state.featuredNewMemberDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.featuredNewMemberDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    saveMainImage = (file, keyId, imageIdentity) => {
        file.storagePath = "FeaturedNewMembersLogo";
        this.commonServices.storeBase64Image(file).then((response) => {
            if (response != null) {
                this.swalServices.Success("Logo Uploaded");
                if (file.storagePath === "FeaturedNewMembersLogo") {
                    let detail = this.state.featuredNewMemberDetails;
                    detail["logoImagePath"] = response.fileName;
                    this.setState({ featuredNewMemberDetails: { ...detail } });
                }
            }
            else {
                this.swalServices.Error(response.message);
            }
        })
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.featuredNewMemberDetails;
        detail[name] = value;
        this.setState({ featuredNewMemberDetails: { ...detail } });
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="main-body-section">
                <div className="bg-white">
                    <div className="heading-part px-7 py-4 border-b">
                        <h4 className="small-title font-bold theme-color">Add Featured New Member</h4>
                    </div>

                    <div className="px-14 py-4">
                        <div className="my-10">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="xl:col-span-6">
                                    <label
                                        className="filter-lable block font-medium mb-3 theme-color min-h-30"
                                        htmlFor="leadershipName"
                                    >
                                        Name <span className="text-[#C00000]">*</span>
                                    </label>
                                    <input
                                        id="default"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="text"
                                        name="memberName"
                                        onChange={(event) => this.handleChange(event)}
                                        value={this.state.featuredNewMemberDetails.memberName}
                                        onBlur={() => this.validateField("memberName")}
                                    />
                                    <ValidationText error={this.state.validState.error.memberName} />
                                </div>
                            </div>
                            <div className="grid grid-cols-12 mt-8">
                                <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                    <div className="adminaccount-info-croper company-logo-cropper mt-8">
                                        <ImageCropperWithUpload
                                            key="Main"
                                            height={250}
                                            width={350}
                                            isCheckValidation={true}
                                            MaxFileSize={2097152}
                                            MaxFileSizeMessage={"2MB"}
                                            onSaveImage={this.saveMainImage}
                                            imageIdentity="FeaturedNewMembersLogo"
                                            intitImage={this.state.featuredNewMemberDetails.logoImagePath}
                                            aspactRatio={350 / 250}
                                            uploadBtn={"Upload Logo"}
                                            dimensionText={
                                                "(Dimension: 200px by 200px Maximum Size: 2MB)"
                                            }
                                        />
                                        <ValidationText error={this.state.validState.error.logoImagePath} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Buttons */}
                <div className="flex flex-wrap justify-between space-x-10 mt-12">
                    <button
                        className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white"
                        onClick={() => this.back()}
                    >
                        Back
                    </button>
                    <button
                        className="w-32 px-7 mr-16 py-2 btn-sm btn-red text-lg text-white"
                        onClick={() => this.addFeaturedNewMemberLogoToWeb()}
                    >
                        Add
                    </button>
                </div>
            </div>
        );

    }
}
