import React, { Component } from "react";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionEditButton from "../../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionDeleteButton from "../../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { Navigate } from "react-router-dom";
import AuthenticationServices from "../../../services/axiosServices/apiServices/AuthenticationServices";
import SwalServices from '../../../services/swalServices/SwalServices';
import { APIURL, GetImage } from "../../../services/axiosServices/ApiEndPoints";
import { CommonSuccessMessages } from "../../../utils/Messages";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import PageLoader from "../../../components/common/pageLoader/PageLoader";
import { encryptAES } from "../../../utils/Encryption";
import CreateButton from '../../../components/UIComponent/Buttons/CreateButton';
import WebsiteCmsServices from "../../../services/axiosServices/apiServices/WebsiteCmsServices";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";

export default class ManageFeaturedNewMembers extends Component {
    constructor(props) {
        super(props);
        this.websiteCmsServices = new WebsiteCmsServices();
        this.swalServices = new SwalServices();
        this.state = {
            route: "",
            isLoading: false,
            isUpdateLoading: false,
            pagination: {
                pageNo: 1,
                pageSize: 50,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
            },
            totalResultes: 0,
            featuredMemberDetailsList: [],
            deletedBy: 1,
            deletedAppId: 1,
            featuredWelcomeTitle: '',
        };
    }

    componentDidMount() {
        this.getFeaturedNewMemberWelcomeTitle();
        this.getFeaturedNewMembersList();
    }

    getFeaturedNewMemberWelcomeTitle = () => {
        this.websiteCmsServices.getFeaturedNewMemberWelcomeTitle().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ featuredWelcomeTitle: response.responseItem.responseContent });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    updateFeaturedNewMemberWelcomeTitle = (featuredWelcomeTitle) => {
        this.setState({ isUpdateLoading: true });
        this.websiteCmsServices.updateFeaturedNewMemberWelcomeTitle(featuredWelcomeTitle).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("Featured Welcome Title Updated Successfully!");
                this.setState({ isUpdateLoading: false });
                this.getFeaturedNewMemberWelcomeTitle()
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isUpdateLoading: false });
        })
    }

    getFeaturedNewMembersList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.websiteCmsServices.getFeaturedNewMembersList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ featuredMemberDetailsList: response.responseItem.responseContent.itemList });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    onDelete = (featuredNewMembersId) => {
        if (featuredNewMembersId !== null) {
            this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Featured New Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "member details"),
                CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
                    if (response) {
                        let request = {};
                        request.id = featuredNewMembersId;
                        request.deletedBy = this.state.deletedBy;
                        this.websiteCmsServices.deleteFeaturedNewMemberLogoById(request).then((response) => {
                            if (response.statusCode === 200 && response.responseItem != null) {
                                this.swalServices.Success("Featured New Member details deleted successfully.");
                                this.getFeaturedNewMembersList();
                            }
                            else {
                                this.swalServices.Error(response.message);
                            }
                            this.setState({ isLoading: false });
                        })
                    }
                })
        }
        else {
            this.swalServices.Error("Sorry! You can't delete this Member.");
        }
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({ featuredWelcomeTitle: value });
    }

    searchByName = (value) => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        detail.pageSize = 10;
        detail.searchText = value;
        this.setState({ pagination: { ...detail } });
        this.getFeaturedNewMembersList();
    }

    render() {
        if (this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div className="main-body-section">
                <div className="flex">
                    <div className="relative flex flex-col flex-1">
                        <main className="pb-10">
                            {/* Title and Update Section */}
                            <div className="custom-card shadow-lg mb-10">
                                <div className="flex items-center gap-4 mb-3">
                                    <div className="flex-1">
                                        <input
                                            id="title"
                                            className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                            type="text"
                                            name="featuredWelcomeTitle"
                                            onChange={(event) => this.handleChange(event)}
                                            value={this.state.featuredWelcomeTitle}
                                            placeholder="Enter title"
                                        />
                                    </div>
                                    {this.state.isUpdateLoading ?
                                        <ButtonLoader className="btn btn-red text-white py-3 px-6 text-lg font-semibold" />
                                        :
                                        <button
                                            className="btn btn-red text-white py-3 px-6 text-lg font-semibold"
                                            onClick={() => this.updateFeaturedNewMemberWelcomeTitle(this.state.featuredWelcomeTitle)}
                                        >
                                            Update
                                        </button>
                                    }
                                </div>
                            </div>

                            {/* Table Section */}
                            <div className="w-full mx-auto">
                                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="table-title-txt theme-color font-bold">
                                            {this.state.totalResultes} Featured Members
                                        </h1>
                                    </div>
                                    <CreateButton
                                        redirect="/AddFeaturedNewMember"
                                        text="Create New"
                                    />
                                </div>
                                <div className="relative">
                                    {/* Table */}
                                    <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
                                        <thead className="table-bg text-xs font-semibold capitalize text-white border-t border-b border-slate-200">
                                            <tr>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Member Name
                                                    </div>
                                                </th>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Logo
                                                    </div>
                                                </th>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Action
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="scrollit text-sm custom-tbody">
                                            {this.state.featuredMemberDetailsList.map((e, key) => (
                                                <tr key={key}>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                                        {e.memberName}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[30%]">
                                                        <img
                                                            className="w-32 border border-[#dfdfdf]"
                                                            alt=""
                                                            src={
                                                                APIURL +
                                                                GetImage +
                                                                "?type=featuredMemberLogo&&fileName=" +
                                                                e.logoImagePath
                                                            }
                                                        />
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%]">
                                                        <ActionContainer>
                                                            <ActionDeleteButton
                                                                value={e.featuredNewMembersId}
                                                                id={key}
                                                                index={key}
                                                                onActionClick={this.onDelete.bind(this)}
                                                                text="Delete"
                                                            />
                                                        </ActionContainer>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );

    }
}
